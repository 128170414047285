import { isAxiosError } from 'axios';
import {
  useNotification,
  ShowNotificationProps,
} from './shared/useNotification';

export const useApiErrorNotification = () => {
  const { showErrorNotification } = useNotification();

  const showNotification = (
    error: unknown,
    defaultNotificationOptions?: Partial<Omit<ShowNotificationProps, 'type'>>
  ) => {
    if (isAxiosError<{ message: string[] | string }>(error)) {
      const data = error.response?.data;

      if (!data) {
        showErrorNotification(defaultNotificationOptions);

        return;
      }

      if (typeof data.message === 'string') {
        showErrorNotification({
          ...defaultNotificationOptions,
          content: (
            <ul>
              <li className="list-inside list-disc">{data.message}</li>
            </ul>
          ),
        });

        return;
      }

      if (Array.isArray(data.message)) {
        showErrorNotification({
          ...defaultNotificationOptions,
          content: (
            <ul>
              {data.message.map((message, index) => (
                <li key={index} className="list-inside list-disc">
                  {message}
                </li>
              ))}
            </ul>
          ),
        });

        return;
      }

      showErrorNotification(defaultNotificationOptions);
    }
  };

  return {
    showErrorNotification: showNotification,
  };
};
