import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgReply = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m4.75 11 6.5-6.25v4.5c8 0 8 5.75 8 10-2.25-6.25-8-6.5-8-6.5v4.5L4.75 11Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgReply);
export default ForwardRef;
