export const dashboard = {
  title: 'Dashboard',
  description: 'Monitor your WhatsApp marketing metrics in real-time',

  overviewTitle: 'Overview',
  totalAttributeRevenue: 'Total attributed revenue',
  totalAttributeRevenueTooltip:
    'Total revenue attributed to WhatsApp Marketing',
  returnOnInvestment: 'ROI (Return on Investment)',
  returnOnInvestmentTooltip: 'Total Revenue divided by WhatsApp Costs',
  whatsAppCosts: 'WhatsApp Costs',
  whatsAppCostsTooltip: 'Total WhatsApp API Conversation Fees',
  revenuePerConversation: 'Revenue per conversation',
  revenuePerConversationTooltip:
    'Total Revenue divided by number of Conversation ',

  audienceTitle: 'Audience',
  totalOptInContacts: 'Total Opt-in Contacts',
  totalOptInContactsTooltip:
    'Total Contacts Opted-In to receive WhatsApp Marketing messages',
  newOptInContacts: 'New Opt-in Contacts',
  unsubscribeRate: 'Unsubscribe Rate',
  unsubscribeRateTooltip:
    'Average of Daily Opt-Out rate (Opt-Outs divided by Total Opted-In Contacts)',
  revenuePerSubscriber: 'Revenue per Subscriber',
  revenuePerSubscriberTooltip:
    'Total Revenue divided by number of Opted-In Contacts',

  conversationTitle: 'Conversations',
  conversations: 'Total conversations',
  conversationsTooltip: 'Number of WhatsApp Conversations (24h window)',
  businessConversations: 'Business Conversations',
  businessConversationsTooltip:
    'Number of WhatsApp Conversations (24h window) initiated by your business',
  customerConversations: 'Customer Conversations',
  customerConversationsTooltip:
    'Number of WhatsApp Conversations (24h window) initiated by your customers',
  messages: 'Total Messages',
  messagesTooltip: 'Total number of WhatsApp Messages sent',

  automationsTitle: 'Automations',
  automationsCta: 'Go to automations',

  campaignsTitle: 'Campaigns',
  campaignsCta: 'Go to campaigns',

  filters: {
    today: 'Today',
    pastWeek: 'Past 7 days',
    pastMonth: 'Past 30 days',
    pastQuarter: 'Past 90 days',
    pastYear: 'Past year',
    allTime: 'All time',
    custom: 'Custom',
  },
};
