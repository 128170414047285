import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgGhost = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.25 11v7.667c0 .507.604.77.975.424l.706-.657a2 2 0 0 1 2.343-.28l1.736.977a1 1 0 0 0 .98 0l1.736-.977a1.999 1.999 0 0 1 2.343.28l.706.657a.58.58 0 0 0 .975-.424V11a6.25 6.25 0 1 0-12.5 0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM15 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgGhost);
export default ForwardRef;
