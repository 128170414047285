import {
  IWaTemplateStatus,
  IWaTemplateLanguage,
  IWaTemplateCategory,
  IWaTemplateQualityScore,
  IMessageButtonType,
  IVariableValueReferenceType,
  IVariableValueReferenceKioskProperty,
  IScenarioNodeTriggerType,
  IVariableValueReferenceShopifyNodeTriggerOrderProperty,
  IVariableValueReferenceShopifyNodeTriggerCheckoutProperty,
} from 'models';

export const waTemplates = {
  pageTitle: 'Template messages',

  status: {
    [IWaTemplateStatus.APPROVED]: 'Approved',
    [IWaTemplateStatus.IN_APPEAL]: 'In appeal',
    [IWaTemplateStatus.PENDING]: 'Pending',
    [IWaTemplateStatus.REJECTED]: 'Rejected',
    [IWaTemplateStatus.PENDING_DELETION]: 'Pending deletion',
    [IWaTemplateStatus.DELETED]: 'Deleted',
    [IWaTemplateStatus.DISABLED]: 'Disabled',
    [IWaTemplateStatus.PAUSED]: 'Paused',
    [IWaTemplateStatus.LIMIT_EXCEEDED]: 'Limit exceeded',
    [IWaTemplateStatus.UNKNOWN]: 'Unknown',
  },

  language: {
    [IWaTemplateLanguage.AFRIKAANS]: 'Afrikaans',
    [IWaTemplateLanguage.ALBANIAN]: 'Albanian',
    [IWaTemplateLanguage.ARABIC]: 'Arabic',
    [IWaTemplateLanguage.AZERBAIJANI]: 'Azerbaijani',
    [IWaTemplateLanguage.BENGALI]: 'Bengali',
    [IWaTemplateLanguage.BULGARIAN]: 'Bulgarian',
    [IWaTemplateLanguage.CATALAN]: 'Catalan',
    [IWaTemplateLanguage.CHINESE_CHN]: 'Chinese (China)',
    [IWaTemplateLanguage.CHINESE_HKG]: 'Chinese (Hong Kong)',
    [IWaTemplateLanguage.CHINESE_TAI]: 'Chinese (Taiwan)',
    [IWaTemplateLanguage.CROATIAN]: 'Croatian',
    [IWaTemplateLanguage.CZECH]: 'Czech',
    [IWaTemplateLanguage.DANISH]: 'Danish',
    [IWaTemplateLanguage.DUTCH]: 'Dutch',
    [IWaTemplateLanguage.ENGLISH]: 'English',
    [IWaTemplateLanguage.ENGLISH_UK]: 'English (UK)',
    [IWaTemplateLanguage.ENGLISH_US]: 'English (US)',
    [IWaTemplateLanguage.ESTONIAN]: 'Estonian',
    [IWaTemplateLanguage.FILIPINO]: 'Filipino',
    [IWaTemplateLanguage.FINNISH]: 'Finnish',
    [IWaTemplateLanguage.FRENCH]: 'French',
    [IWaTemplateLanguage.GEORGIAN]: 'Georgian',
    [IWaTemplateLanguage.GERMAN]: 'German',
    [IWaTemplateLanguage.GREEK]: 'Greek',
    [IWaTemplateLanguage.GUJARATI]: 'Gujarati',
    [IWaTemplateLanguage.HAUSA]: 'Hausa',
    [IWaTemplateLanguage.HEBREW]: 'Hebrew',
    [IWaTemplateLanguage.HINDI]: 'Hindi',
    [IWaTemplateLanguage.HUNGARIAN]: 'Hungarian',
    [IWaTemplateLanguage.INDONESIAN]: 'Indonesian',
    [IWaTemplateLanguage.IRISH]: 'Irish',
    [IWaTemplateLanguage.ITALIAN]: 'Italian',
    [IWaTemplateLanguage.JAPANESE]: 'Japanese',
    [IWaTemplateLanguage.KANNADA]: 'kn',
    [IWaTemplateLanguage.KAZAKH]: 'kk',
    [IWaTemplateLanguage.KINYARWANDA]: 'rw_RW',
    [IWaTemplateLanguage.KOREAN]: 'Korean',
    [IWaTemplateLanguage.KYRGYZ_KYRGYZSTAN]: 'Kyrgyz (Kyrgyzstan)',
    [IWaTemplateLanguage.LAO]: 'Lao',
    [IWaTemplateLanguage.LATVIAN]: 'Latvian',
    [IWaTemplateLanguage.LITHUANIAN]: 'Lithuanian',
    [IWaTemplateLanguage.MACEDONIAN]: 'Macedonian',
    [IWaTemplateLanguage.MALAY]: 'Malay',
    [IWaTemplateLanguage.MALAYALAM]: 'Malayalam',
    [IWaTemplateLanguage.MARATHI]: 'Marathi',
    [IWaTemplateLanguage.NORWEGIAN]: 'Norwegian',
    [IWaTemplateLanguage.PERSIAN]: 'Persian',
    [IWaTemplateLanguage.POLISH]: 'Polish',
    [IWaTemplateLanguage.PORTUGUESE_BR]: 'Portuguese (Brazil)',
    [IWaTemplateLanguage.PORTUGUESE_POR]: 'Portuguese (Portugal)',
    [IWaTemplateLanguage.PUNJABI]: 'Punjabi',
    [IWaTemplateLanguage.ROMANIAN]: 'Romanian',
    [IWaTemplateLanguage.RUSSIAN]: 'Russian',
    [IWaTemplateLanguage.SERBIAN]: 'Serbian',
    [IWaTemplateLanguage.SLOVAK]: 'Slavak',
    [IWaTemplateLanguage.SLOVENIAN]: 'Slavonian',
    [IWaTemplateLanguage.SPANISH]: 'Spanish',
    [IWaTemplateLanguage.SPANISH_ARG]: 'Spannish (Argentina)',
    [IWaTemplateLanguage.SPANISH_SPA]: 'Spannish (Spain)',
    [IWaTemplateLanguage.SPANISH_MEX]: 'Spannish (Mexico)',
    [IWaTemplateLanguage.SWAHILI]: 'Swahili',
    [IWaTemplateLanguage.SWEDISH]: 'Swedish',
    [IWaTemplateLanguage.TAMIL]: 'Tamil',
    [IWaTemplateLanguage.TELUGU]: 'Telugu',
    [IWaTemplateLanguage.THAI]: 'Thai',
    [IWaTemplateLanguage.TURKISH]: 'Turkish',
    [IWaTemplateLanguage.UKRAINIAN]: 'Ukrainian',
    [IWaTemplateLanguage.URDU]: 'Urdu',
    [IWaTemplateLanguage.UZBEK]: 'Uzbek',
    [IWaTemplateLanguage.VIETNAMESE]: 'Vietnamese',
    [IWaTemplateLanguage.ZULU]: 'Zulu',
  },

  category: {
    [IWaTemplateCategory.MARKETING]: 'Marketing',
    [IWaTemplateCategory.UTILITY]: 'Service',
    [IWaTemplateCategory.UNKNOWN]: 'Unknown',
  },

  qualityScore: {
    [IWaTemplateQualityScore.RED]: 'High quality',
    [IWaTemplateQualityScore.YELLOW]: 'Medium quality',
    [IWaTemplateQualityScore.GREEN]: 'Low quality',
    [IWaTemplateQualityScore.UNKNOWN]: 'Quality pending',
  },

  index: {
    newWaTemplate: 'New template',
    emptyState: {
      title: 'No templates found',
      text: 'Try changing the filters or create a new template.',
    },

    waTemplatesCount: '{{waTemplatesCount}} templates',
    maxWaTemplates: '{{maxWaTemplates}} max',
    description:
      'Create and improve your library of messages validated by Meta.',
    descriptionTooltip:
      'Template messages allow you to send messages to customers who have not sent a message in the last 24 hours.',

    headers: {
      name: 'Name',
      language: 'Language',
      status: 'Status',
      numberOfMessages: 'Messages',
      updatedAt: 'Last update',
      content: 'Content',
    },

    filters: {
      all: 'All',
      [IWaTemplateStatus.APPROVED]: 'Approved',
      [IWaTemplateStatus.PENDING]: 'Pending',
      [IWaTemplateStatus.REJECTED]: 'Rejected',
    },

    modifyError: {
      title: 'Template is not editable',
      content:
        'Template can only be edited when status is approved, paused or rejected',
    },
  },

  variableValueReferences: {
    groups: {
      kiosk: 'Kiosk variables',
      scenario: 'Automation variables',
      scenario_input: 'Automation inputs variables',
      klaviyo: 'Klaviyo profile variables',
      shopify: 'Shopify customer variables',
      triggerEvent: {
        [IScenarioNodeTriggerType.SHOPIFY]: 'Shopify event variables',
      },
    },
    [IVariableValueReferenceType.STATIC]: 'Free text',
    [IVariableValueReferenceType.KIOSK]: {
      [IVariableValueReferenceKioskProperty.WA_NAME]: {
        label: 'WhatsApp name',
        support: 'Mike',
      },
      [IVariableValueReferenceKioskProperty.HAS_ORGANIZATION_MESSAGES_IN_PAST_24_HOURS]:
        {
          label: 'Has message from us in past 24H',
          support: '',
        },
    },
    [IVariableValueReferenceType.SCENARIO_NODE_TRIGGER_EVENT]: {
      [IScenarioNodeTriggerType.SHOPIFY]: {
        ORDER_EVENT: {
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_AMOUNT]:
            {
              label: 'Order amount',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_PRODUCT_IDS]:
            {
              label: 'Product ids',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_PRODUCT_TITLES]:
            {
              label: 'Product titles',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_PRODUCT_VENDORS]:
            {
              label: 'Product vendors',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_EMAIL]:
            {
              label: 'Email',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_PHONE_NUMBER]:
            {
              label: 'Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_FIRST_NAME]:
            {
              label: 'Shipping address - First name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_LAST_NAME]:
            {
              label: 'Shipping address - Last name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_PHONE_NUMBER]:
            {
              label: 'Shipping address - Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_ADDRESS_1]:
            {
              label: 'Shipping address - Address 1',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_ADDRESS_2]:
            {
              label: 'Shipping address - Address 2',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_COMPANY]:
            {
              label: 'Shipping address - Company',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_CITY]:
            {
              label: 'Shipping address - City',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_ZIP]:
            {
              label: 'Shipping address - Zip',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_PROVINCE]:
            {
              label: 'Shipping address - Province',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_SHIPPING_ADDRESS_COUNTRY_CODE]:
            {
              label: 'Shipping address - Country',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_BILLING_ADDRESS_SAME_AS_SHIPPING]:
            {
              label: 'Billing address same as shipping',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_BILLING_ADDRESS_PHONE_NUMBER]:
            {
              label: 'Billing address - Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_BILLING_ADDRESS_FIRST_NAME]:
            {
              label: 'Billing address - First name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerOrderProperty.ORDER_BILLING_ADDRESS_LAST_NAME]:
            {
              label: 'Billing address - Last name',
              support: '',
            },
        },
        CHECKOUT_EVENT: {
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_COMPLETED]:
            {
              label: 'Checkout completed',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_AMOUNT]:
            {
              label: 'Checkout amount',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_URL]:
            {
              label: 'Checkout url',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_EMAIL]:
            {
              label: 'Email',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_PHONE_NUMBER]:
            {
              label: 'Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_PRODUCT_IDS]:
            {
              label: 'Product ids',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_PRODUCT_TITLES]:
            {
              label: 'Product titles',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_PRODUCT_VENDORS]:
            {
              label: 'Product vendors',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_FIRST_NAME]:
            {
              label: 'Shipping address - First name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_LAST_NAME]:
            {
              label: 'Shipping address - Last name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_PHONE_NUMBER]:
            {
              label: 'Shipping address - Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_ADDRESS_1]:
            {
              label: 'Shipping address - Address 1',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_ADDRESS_2]:
            {
              label: 'Shipping address - Address 2',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_COMPANY]:
            {
              label: 'Shipping address - Company',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_CITY]:
            {
              label: 'Shipping address - City',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_ZIP]:
            {
              label: 'Shipping address - Zip',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_PROVINCE]:
            {
              label: 'Shipping address - Province',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_SHIPPING_ADDRESS_COUNTRY_CODE]:
            {
              label: 'Shipping address - Country',
              support: '',
            },

          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_BILLING_ADDRESS_SAME_AS_SHIPPING]:
            {
              label: 'Billing address same as shipping',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_BILLING_ADDRESS_PHONE_NUMBER]:
            {
              label: 'Billing address - Phone number',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_BILLING_ADDRESS_FIRST_NAME]:
            {
              label: 'Billing address - First name',
              support: '',
            },
          [IVariableValueReferenceShopifyNodeTriggerCheckoutProperty.CHECKOUT_BILLING_ADDRESS_LAST_NAME]:
            {
              label: 'Billing address - Last name',
              support: '',
            },
        },
      },
    },
  },

  form: {
    submitAction: 'Send for verification',
    composeTitle: 'Compose your message',

    submitError: {
      titleCreated: 'Error creating template',
      titleUpdated: 'Error updating template',
      content: 'Please try again later',
    },

    submitSuccess: {
      titleCreated: 'Template created',
      titleUpdated: 'Template updated',
      content: 'You now have to wait for your template to be validated',
    },

    templateNamePlaceholder: 'Ex: Away message',
    bodyAddVariable: 'Add variable',
    buttonsAdd: 'Add button',

    buttonType: {
      [IMessageButtonType.LITERAL_URL]: {
        title: 'External website (literal url)',
        description: '2 max',
        placeholder: 'Check our website',
      },
      [IMessageButtonType.URL]: {
        title: 'External website',
        description: '2 max',
        placeholder: 'Check our website',
      },
      [IMessageButtonType.QUICK_REPLY]: {
        title: 'Quick reply',
        description: 'Unlimited use',
        placeholder: 'Yes',
      },
    },
  },

  validations: {
    noVariableStart: 'Text cannot start with a variable',
    invalidUrl: 'Invalid URL format. Please use http://, https:// or tel:',
    noVariableEnd: 'Text cannot end with a variable',
    variablesToTextRatio:
      'There are too many variables compared to the text content. Please remove some variables or add more text.',
  },
};
