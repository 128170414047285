import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPaperclip = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.5 11.951-6.117 5.91c-1.917 1.852-5.027 1.852-6.945 0a4.632 4.632 0 0 1 .018-6.729L12.1 5.702c1.313-1.268 3.44-1.268 4.752 0a3.17 3.17 0 0 1-.012 4.603l-5.707 5.482a1.86 1.86 0 0 1-2.566 0 1.711 1.711 0 0 1 0-2.479l4.727-4.566"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPaperclip);
export default ForwardRef;
