import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    ref={ref}
    {...props}
  >
    <path
      d="M8.11442 3.76074H5.34359C4.46914 3.76074 3.76025 4.46962 3.76025 5.34408V15.2399H12.0728V10.0941"
      stroke="currentColor"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.76025 15.2393H15.2394"
      stroke="currentColor"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2603 7.32324L15.2394 5.54199M15.2394 5.54199L13.2603 3.76074M15.2394 5.54199H10.8853"
      stroke="currentColor"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.89567 9.50033C9.89567 9.71894 9.71845 9.89616 9.49984 9.89616C9.28122 9.89616 9.104 9.71894 9.104 9.50033C9.104 9.28171 9.28122 9.10449 9.49984 9.10449C9.71845 9.10449 9.89567 9.28171 9.89567 9.50033Z"
      stroke="currentColor"
      strokeWidth="0.791667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
