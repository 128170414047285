import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgArchive = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.25 8.75H5.75l.828 8.69a2 2 0 0 0 1.99 1.81h6.863a2 2 0 0 0 1.991-1.81l.828-8.69Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.25 5.75a1 1 0 0 0-1-1H5.75a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h12.5a1 1 0 0 0 1-1v-2ZM9.75 13.25h4.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArchive);
export default ForwardRef;
