import { MediaType } from '@/types/media.ts';

export const inputImageMimeTypes = ['image/jpeg', 'image/png'];
export const outputImageMimeTypes = [
  ...inputImageMimeTypes,
  'image/gif',
  'image/webp',
];

export const inputAudioMimeTypes = ['audio/aac', 'audio/mp3'];
export const outputAudioMimeTypes = [
  ...inputAudioMimeTypes,
  'audio/mp4',
  'audio/mpeg',
  'audio/amr',
  'audio/ogg',
];

export const inputVideoMimeTypes = ['video/mp4', 'video/3gp'];
export const outputVideoMimeTypes = [...inputVideoMimeTypes];

export const inputDocumentMimeTypes = ['application/pdf'];
export const outputDocumentMimeTypes = [...inputDocumentMimeTypes];

export function mimeTypeToMediaType(mimeType: string): MediaType {
  switch (true) {
    case outputImageMimeTypes.includes(mimeType):
      return MediaType.IMAGE;
    case outputAudioMimeTypes.includes(mimeType):
      return MediaType.AUDIO;
    case outputVideoMimeTypes.includes(mimeType):
      return MediaType.VIDEO;
    default:
      return MediaType.FILE;
  }
}

export const mediaTypesToInputAccept = (
  mediaTypes: MediaType | MediaType[]
): string => {
  const types = Array.isArray(mediaTypes) ? mediaTypes : [mediaTypes];

  return types
    .map((type) => {
      switch (type) {
        case MediaType.IMAGE:
          return inputImageMimeTypes;
        case MediaType.AUDIO:
          return inputAudioMimeTypes;
        case MediaType.VIDEO:
          return inputVideoMimeTypes;
        case MediaType.FILE:
          return inputDocumentMimeTypes;
        default:
          return [];
      }
    })
    .flat()
    .join(',');
};
