import { useRouteError, isRouteErrorResponse, Link } from 'react-router-dom';
import { Button } from '@ui/button';
import { ArrowRightGradientIcon, FaceIdErrorIcon } from '@ui/icons';
import { getWhatsAppLink } from '@/utils/whatsApp';
import { t } from 'i18next';

const ErrorComponent = ({
  status,
  text,
}: {
  status: string | number;
  text: string;
}) => (
  <div className="flex h-screen flex-col items-center justify-center gap-8 text-neutral-700">
    <div className="relative rounded-[30px] bg-white p-5 shadow-lg ring-1 ring-black/5 before:absolute before:inset-0 before:-z-10 before:block before:h-24 before:w-24 before:scale-[2] before:rounded-inherit before:blur-xl before:bg-gradient before:gradient-opacity-medium">
      <FaceIdErrorIcon className="h-12 w-12" />
    </div>

    <div className="text-center ">
      <h1 className="heading-xl">{status}</h1>
      <p className="mt-2 font-light text-neutral-500">{text}</p>
    </div>
    <div className="flex flex-col items-center gap-2">
      <Button as="a" href={getWhatsAppLink()} target="_blank">
        {t('shared:layout.errorDisplay.cta')}
        <ArrowRightGradientIcon className="h-5 w-5" />
      </Button>
      <Button as={Link} to="/" variant="subtle-indigo">
        {t('shared:layout.errorDisplay.back')}
      </Button>
    </div>
  </div>
);

export const ConfigError = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error))
    return <ErrorComponent status={error.status} text={error.statusText} />;

  if (error instanceof Error) {
    return <ErrorComponent status={error.name} text={error.message} />;
  }

  return <ErrorComponent status="Error" text="Something went wrong..." />;
};
