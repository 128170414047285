import { RouteObject } from 'react-router-dom';

export const router = {
  path: 'scenarios',
  children: [
    {
      index: true,
      lazy: () => import('./Scenarios.page'),
    },
    {
      path: 'create-json',
      lazy: () => import('./pages/create/ScenariosCreateJson.page'),
    },
    {
      path: ':scenarioId',
      lazy: () => import('./pages/show/ScenarioShow.layout'),
      children: [
        {
          index: true,
          lazy: () => import('./pages/show/ScenarioShow.page'),
        },
        {
          path: 'performance',
          lazy: () => import('./pages/show/ScenarioShowPerformance.page'),
        },
        {
          path: 'responses',
          lazy: () => import('./pages/show/ScenarioShowResponses.page.tsx'),
        },
      ],
    },
    {
      path: ':scenarioId/update',
      lazy: () => import('./pages/update/ScenariosUpdate.page'),
    },
    {
      path: ':scenarioId/update-json',
      lazy: () => import('./pages/update/ScenariosUpdateJson.page'),
    },
  ],
} satisfies RouteObject;
