import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgHyperlink = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.hyperlink_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="m9.364 18.5-.932.932a4.5 4.5 0 0 1-6.364-6.364l4.773-4.774a4.5 4.5 0 0 1 6.825 5.825"
      className="hyperlink_svg__a"
    />
    <path
      d="m14.818 5.567.75-.75a4.5 4.5 0 0 1 6.364 6.364l-4.773 4.773a4.5 4.5 0 0 1-6.824-5.826"
      className="hyperlink_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHyperlink);
export default ForwardRef;
