import { BrandLogo } from '@/features/display/brandLogo';
import { Outlet } from 'react-router-dom';
import topLeftIllustration from './assets/PreSaleIllustration.png';
import bottomLeftIllustration from './assets/AbandonCartIllustration.png';
import topRightIllustration from './assets/RaffleIllustration.png';
import bottomRightIllustration from './assets/ClientMessageIllustration.png';

export const AuthLayout = () => (
  <main className="h-screen bg-gradient-to-tr from-indigo-500 to-indigo-600 p-3">
    <div className="relative h-full overflow-x-clip rounded-xl bg-white shadow-md ring ring-black/20 ring-offset-0">
      <div className="h-full overflow-y-auto pb-12">
        <div className="sticky top-0 flex items-center gap-4 p-8">
          <div className="size-11 rounded-xl bg-gradient-to-tr from-indigo-900 to-indigo-500 p-1">
            <BrandLogo brand="kiosk" className="size-full rounded-lg" />
          </div>
          <BrandLogo brand="kioskLetters" className="h-6" />
        </div>

        <div className="flex-1 overflow-y-auto px-8">
          <div className="mx-auto max-w-2xl">
            <Outlet />
          </div>
        </div>
      </div>

      <img
        src={bottomLeftIllustration}
        alt=""
        className="absolute -left-20 bottom-16 hidden w-52 -rotate-6 md:block lg:left-8 lg:rotate-6"
        loading="lazy"
      />
      <img
        src={topLeftIllustration}
        alt=""
        className="absolute -left-24 top-32 hidden w-52 rotate-6 md:block lg:bottom-64 lg:top-auto lg:-rotate-6"
        loading="lazy"
      />
      <img
        src={bottomRightIllustration}
        alt=""
        className="absolute -right-20 bottom-12 hidden w-52 rotate-6 md:block lg:right-8 lg:-rotate-6"
        loading="lazy"
      />
      <img
        src={topRightIllustration}
        alt=""
        className="absolute -right-20 top-12 hidden w-52 -rotate-6 md:block lg:-right-4 lg:bottom-56 lg:top-auto lg:rotate-6 xl:-right-8"
        loading="lazy"
      />
    </div>
  </main>
);
