import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgClockCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 20"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.917 10a6.042 6.042 0 1 1-12.084 0 6.042 6.042 0 0 1 12.084 0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M10.875 6.667V10l1.667 1.667"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgClockCircle);
export default ForwardRef;
