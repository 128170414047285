import { IIntegrationType } from 'models';

export const integrations = {
  integrationType: {
    [IIntegrationType.HUBSPOT]: 'HubSpot',
    [IIntegrationType.KLAVIYO]: 'Klaviyo',
    [IIntegrationType.SHOPIFY]: 'Shopify',
    [IIntegrationType.GORGIAS]: 'Gorgias',
  },

  shopify: {
    invalidShopDomainHttp:
      'Shop store domain should not contain "http://" or "https://"',
    invalidShopDomainEndsWith:
      'Shop store domain must end with "myshopify.com"',
    title: 'Connect your Shopify store',
    label: 'Shopify store domain',
    description: 'You can find your Shopify store information ',
    shopifyStoreInfoLink: 'https://admin.shopify.com/?redirect=%2Fsettings',
    descriptionHere: 'here',
  },
  gorgias: {
    title: 'Connect your Gorgias account',
    label: 'Gorgias account domain',
  },
};
