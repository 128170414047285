import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M18.75 14C18.75 18 16.0001 19.25 12.5 19.25C8.50006 19.25 6.25006 16.4 6.25006 14C6.25006 11.6 7.50006 9.41667 8.50006 8.75C8.50006 11.55 11.1667 13.3333 12.5 13.25C10.1 9.65 12.1667 5.66667 13.5 4.75C13.5 9.25 18.75 10 18.75 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
