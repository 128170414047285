import { useActiveIntegrationList } from '@/features/integration/queries';
import { useMemo } from 'react';
import { IIntegrationType } from 'models';
import { useOrganization } from '@/features/organization/queries';

export const useGettingStartedInformations = () => {
  // SETUP
  const { organization, organizationLoading } = useOrganization();
  const whatsAppSetupCheck = !!organization?.waAccount?.id;
  const whatsAppPaymentCheck = !organization?.waData?.hasPaymentIssues;

  // SHOPIFY
  const { activeIntegrations, activeIntegrationsLoading: shopifyLoading } =
    useActiveIntegrationList();

  const shopifyCheck = useMemo(
    () =>
      activeIntegrations?.some(
        (integration) => integration.type === IIntegrationType.SHOPIFY
      ),
    [activeIntegrations]
  );

  // ENTRY POINTS
  const entryPointsCheck = !!organization?.usageData.enabledEntryPointsCount;

  // CAMPAIGNS
  const campaignsCheck = !!organization?.usageData.sentCampaignsCount;

  // SCENARIOS
  const scenariosCheck = !!organization?.usageData.enabledAutomationsCount;

  const percentage = useMemo(() => {
    const elements = [
      shopifyCheck,
      entryPointsCheck,
      campaignsCheck,
      scenariosCheck,
      whatsAppSetupCheck,
      whatsAppPaymentCheck,
    ];

    return Math.round(
      (elements.filter(Boolean).length / elements.length) * 100
    );
  }, [
    shopifyCheck,
    entryPointsCheck,
    campaignsCheck,
    scenariosCheck,
    whatsAppSetupCheck,
    whatsAppPaymentCheck,
  ]);

  const isLoading = useMemo(
    () => shopifyLoading || organizationLoading,
    [shopifyLoading, organizationLoading]
  );

  return {
    percentage,
    isLoading,

    shopifyCheck,
    entryPointsCheck,
    campaignsCheck,
    scenariosCheck,
    whatsAppSetupCheck,
    whatsAppPaymentCheck,
  };
};
