import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgAvatar = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="url(#paint0_linear_3_1241)" />
    <circle cx="16" cy="11" r="6" fill="white" />
    <path
      d="M4.45532 23.922C7.36709 20.8884 11.463 19 16 19C20.5369 19 24.6328 20.8884 27.5446 23.922C25.0208 27.5929 20.7915 30 16 30C11.2084 30 6.97915 27.5929 4.45532 23.922Z"
      fill="white"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="15.5"
      stroke="black"
      strokeOpacity="0.04"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3_1241"
        x1="2"
        y1="32"
        x2="32"
        y2="1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="#D4D4D4" />
      </linearGradient>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgAvatar);
export default ForwardRef;
