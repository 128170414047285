import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgLightning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.75 13.25h-4l6.5-8.5v6h4l-6.5 8.5v-6Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLightning);
export default ForwardRef;
