import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    fill="currentColor"
    {...props}
  >
    <path d="M3.76733 12.0326V11.1407C4.43727 11.1407 4.90504 11.0039 5.17064 10.7304C5.43624 10.4529 5.56904 9.99506 5.56904 9.35683V7.65027C5.56904 7.04375 5.63643 6.53436 5.77121 6.12209C5.90995 5.70586 6.12005 5.37287 6.40151 5.12313C6.68296 4.87339 7.03973 4.69302 7.47183 4.58202C7.90392 4.47103 8.41331 4.41553 9 4.41553V5.82478C8.54016 5.82478 8.18141 5.89217 7.92374 6.02695C7.66607 6.16173 7.4857 6.37183 7.38263 6.65725C7.28353 6.93871 7.23398 7.29944 7.23398 7.73946V9.90389C7.23398 10.2091 7.18839 10.4926 7.09722 10.7542C7.00604 11.0119 6.83756 11.2358 6.59179 11.4261C6.34601 11.6164 5.99518 11.7651 5.53931 11.8721C5.08343 11.9791 4.49277 12.0326 3.76733 12.0326ZM9 19.5843C8.41331 19.5843 7.90392 19.5288 7.47183 19.4178C7.03973 19.3068 6.68296 19.1265 6.40151 18.8767C6.12005 18.627 5.90995 18.294 5.77121 17.8778C5.63643 17.4655 5.56904 16.9561 5.56904 16.3496V14.6371C5.56904 14.0028 5.43624 13.5469 5.17064 13.2694C4.90504 12.992 4.43727 12.8532 3.76733 12.8532V11.9672C4.49277 11.9672 5.08343 12.0207 5.53931 12.1278C5.99518 12.2308 6.34601 12.3795 6.59179 12.5737C6.83756 12.764 7.00604 12.99 7.09722 13.2516C7.18839 13.5093 7.23398 13.7907 7.23398 14.096V16.2604C7.23398 16.6964 7.28353 17.0552 7.38263 17.3367C7.4857 17.6221 7.66607 17.8322 7.92374 17.967C8.18141 18.1057 8.54016 18.1751 9 18.1751V19.5843ZM3.76733 12.8532V11.1407H5.41444V12.8532H3.76733Z" />
    <path d="M10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12Z" />
    <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" />
    <path d="M16 12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12Z" />
    <path d="M20.2327 11.9672V12.8532C19.5627 12.8532 19.095 12.992 18.8294 13.2694C18.5638 13.5469 18.431 14.0028 18.431 14.6371V16.3496C18.431 16.9561 18.3616 17.4655 18.2229 17.8778C18.0881 18.294 17.8799 18.627 17.5985 18.8767C17.317 19.1265 16.9603 19.3068 16.5282 19.4178C16.0961 19.5288 15.5867 19.5843 15 19.5843V18.1751C15.4598 18.1751 15.8186 18.1057 16.0763 17.967C16.3339 17.8322 16.5123 17.6221 16.6114 17.3367C16.7145 17.0552 16.766 16.6964 16.766 16.2604V14.096C16.766 13.7907 16.8116 13.5093 16.9028 13.2516C16.994 12.99 17.1624 12.764 17.4082 12.5737C17.654 12.3795 18.0048 12.2308 18.4607 12.1278C18.9166 12.0207 19.5072 11.9672 20.2327 11.9672ZM15 4.41553C15.5867 4.41553 16.0961 4.47103 16.5282 4.58202C16.9603 4.69302 17.317 4.87339 17.5985 5.12313C17.8799 5.37287 18.0881 5.70586 18.2229 6.12209C18.3616 6.53436 18.431 7.04375 18.431 7.65027V9.35683C18.431 9.99506 18.5638 10.4529 18.8294 10.7304C19.095 11.0039 19.5627 11.1407 20.2327 11.1407V12.0326C19.5072 12.0326 18.9166 11.9791 18.4607 11.8721C18.0048 11.7651 17.654 11.6164 17.4082 11.4261C17.1624 11.2358 16.994 11.0119 16.9028 10.7542C16.8116 10.4926 16.766 10.2091 16.766 9.90389V7.73946C16.766 7.29944 16.7145 6.93871 16.6114 6.65725C16.5123 6.37183 16.3339 6.16173 16.0763 6.02695C15.8186 5.89217 15.4598 5.82478 15 5.82478V4.41553ZM20.2327 11.1407V12.8532H18.5856V11.1407H20.2327Z" />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
