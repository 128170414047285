import Session from 'supertokens-web-js/recipe/session';
import { IAccessTokenPayload } from '@/types/access-token-payload.interfaces';

export async function getAccessTokenPayload(): Promise<IAccessTokenPayload> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const accessTokenPayload: IAccessTokenPayload =
    await Session.getAccessTokenPayloadSecurely();

  if (accessTokenPayload === undefined) {
    throw new Error('User is not logged in');
  }

  return accessTokenPayload;
}

export async function getAccessToken(): Promise<string> {
  const token = await Session.getAccessToken();

  if (token === undefined) {
    throw new Error('User is not logged in');
  }

  return token;
}
