import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCopy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M7.25 15.25C6.2835 15.25 5.5 14.4665 5.5 13.5V6.75C5.5 5.64543 6.39543 4.75 7.5 4.75H14.25C15.2165 4.75 16 5.5335 16 6.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 10.75C9.5 9.64543 10.3954 8.75 11.5 8.75H18C19.1046 8.75 20 9.64543 20 10.75V17.25C20 18.3546 19.1046 19.25 18 19.25H11.5C10.3954 19.25 9.5 18.3546 9.5 17.25V10.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
