import { useApiErrorNotification, useQuery } from '@/hooks';
import { useDocumentTitle } from '@/hooks/contexts';
import { useNotification } from '@/hooks/shared/useNotification';
import * as OrganizationService from '@/services/OrganizationService';
import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { format } from 'date-fns';
import {
  GetOrganizationStatisticsInput,
  OrganizationOutputDto,
  OrganizationStatisticsOutputDto,
} from 'dto';
import { useEffect } from 'react';

const stringifyDate = (date?: Nullable<Date>) =>
  date ? format(date, 'yyyy-MM-dd') : '-';

const organizationQueryKeys = {
  all: () => ['organization'],
  details: () => [...organizationQueryKeys.all(), 'details'],
  metrics: (data: GetOrganizationStatisticsInput) => [
    ...organizationQueryKeys.all(),
    'metrics',
    {
      startDate: stringifyDate(data.startDate),
      endDate: stringifyDate(data.endDate),
    },
  ],
};

export const useOrganization = (
  options?: UseQueryOptions<OrganizationOutputDto>
) => {
  const { setDocumentTitle } = useDocumentTitle();

  const { data, isPending } = useQuery({
    queryKey: organizationQueryKeys.details(),
    queryFn: OrganizationService.getCurrentUserOrganization,
    ...options,
  });

  useEffect(() => {
    if (!data?.name) return;

    setDocumentTitle({ organizationName: data.name });
  }, [data?.name, setDocumentTitle]);

  return {
    organization: data,
    organizationLoading: isPending,
  };
};

export const useOrganizationStatistics = (
  dataInput: GetOrganizationStatisticsInput,
  options?: UseQueryOptions<OrganizationStatisticsOutputDto>
) => {
  const { data, isPending } = useQuery({
    queryKey: organizationQueryKeys.metrics(dataInput),
    queryFn: () => OrganizationService.getOrganizationStatistics(dataInput),
    ...options,
  });

  return {
    organizationStatistics: data,
    organizationStatisticsLoading: isPending,
  };
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  const { showErrorNotification } = useApiErrorNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: OrganizationService.createOrganization,
    onSuccess: (newData) => {
      queryClient.setQueryData(organizationQueryKeys.details(), newData);
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    createOrganization: mutateAsync,
    createOrganizationLoading: isPending,
  };
};

export const useUpdateOrganizationSettings = () => {
  const queryClient = useQueryClient();
  const { showSuccessNotification } = useNotification();
  const { showErrorNotification } = useApiErrorNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: OrganizationService.updateOrganizationSettings,
    onSuccess: (newData) => {
      queryClient.setQueryData(
        organizationQueryKeys.details(),
        (oldData?: OrganizationOutputDto) => {
          if (!oldData) return;

          return {
            ...oldData,
            settings: newData,
          };
        }
      );

      showSuccessNotification();
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    updateOrganizationSettings: mutateAsync,
    updateOrganizationSettingsLoading: isPending,
  };
};

export const useUpdateOrganizationFromWaEmbeddedSignup = () => {
  const queryClient = useQueryClient();
  const { showErrorNotification } = useApiErrorNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: OrganizationService.updateOrganizationFromWaEmbeddedSignup,
    onSuccess: (newData) => {
      queryClient.setQueryData(organizationQueryKeys.details(), () => newData);
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    updateOrganizationFromEmbeddedSignup: mutateAsync,
    updateOrganizationFromEmbeddedSignupLoading: isPending,
  };
};

export const useRegisterOrganizationPhoneNumber = () => {
  const queryClient = useQueryClient();
  const { showErrorNotification } = useApiErrorNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: OrganizationService.registerOrganizationPhoneNumber,
    onSuccess: (newData) => {
      queryClient.setQueryData(organizationQueryKeys.details(), () => newData);
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    registerOrganizationPhoneNumber: mutateAsync,
    registerOrganizationPhoneNumberLoading: isPending,
  };
};

export const useUpdateOrganizationWhatsAppProfile = () => {
  const queryClient = useQueryClient();
  const { showErrorNotification } = useApiErrorNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: OrganizationService.updateOrganizationWhatsAppProfile,
    onSuccess: (newData) => {
      queryClient.setQueryData(organizationQueryKeys.details(), () => newData);
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    updateOrganizationWhatsAppProfile: mutateAsync,
    updateOrganizationWhatsAppProfileLoading: isPending,
  };
};
