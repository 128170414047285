import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.settings_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <circle cx={17.25} cy={16.75} r={1.5} className="settings_svg__a" />
    <path
      d="m18.524 10.947.442 1.453a.994.994 0 0 0 1.174.681l1.472-.341a1.339 1.339 0 0 1 1.275 2.218l-1.031 1.111a1 1 0 0 0 0 1.362l1.031 1.111a1.339 1.339 0 0 1-1.275 2.218l-1.472-.341a.994.994 0 0 0-1.174.681l-.442 1.453a1.33 1.33 0 0 1-2.548 0l-.442-1.453a.994.994 0 0 0-1.174-.681l-1.472.341a1.339 1.339 0 0 1-1.275-2.218l1.031-1.111a1 1 0 0 0 0-1.362l-1.031-1.111a1.339 1.339 0 0 1 1.275-2.218l1.472.341a.994.994 0 0 0 1.174-.681l.442-1.453a1.33 1.33 0 0 1 2.548 0ZM3 12.25V2.5A1.5 1.5 0 0 1 4.5 1h15A1.5 1.5 0 0 1 21 2.5v6M8.25 12.25H.75A3.75 3.75 0 0 0 4.5 16h3.75"
      className="settings_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
