import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M17.5 19.25H6C5.44772 19.25 5 18.8023 5 18.25V5.75C5 5.19771 5.44772 4.75 6 4.75H14.5C15.0523 4.75 15.5 5.19772 15.5 5.75V10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7727 9.75H15.5V17.25C15.5 18.3546 16.3954 19.25 17.5 19.25C18.6046 19.25 19.5 18.3546 19.5 17.25V11.4773C19.5 10.5233 18.7267 9.75 17.7727 9.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.75C8 8.19772 8.44772 7.75 9 7.75H11.5C12.0523 7.75 12.5 8.19772 12.5 8.75V10.25C12.5 10.8023 12.0523 11.25 11.5 11.25H9C8.44772 11.25 8 10.8023 8 10.25V8.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 13.75H12.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 16.25H12.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
