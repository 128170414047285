import { IEntryPointStatus } from 'models';

export const entrypoints = {
  pageTitle: 'Entry points',

  // model
  status: {
    [IEntryPointStatus.ENABLED]: 'Enabled',
    [IEntryPointStatus.DISABLED]: 'Disabled',
  },

  list: {
    title: 'Entry points',
    description:
      'Grow your WhatsApp audience by multiplying entry points and monitoring their performance',
    createCta: 'New entry point',
  },

  listItem: {
    triggered: 'Triggered',
    optedIn: 'Opted-in',
    shopify: 'Shopify Entry Point',
  },

  emptyState: {
    title: 'Create your first entry point',
    content:
      'Grow your WhatsApp audience by multiplying entry points and monitoring their performance',
    linkTitle: 'Link / QR code',
    klaviyoTitle: 'Klaviyo popup',
    shopifyTitle: 'Shopify Checkout',
    connectIntegration: 'Connect integration',
  },

  actions: {
    back: 'Entry points',
    createCta: 'Create entry point',
    updateCta: 'Save modifications',
    enableCta: 'Enable entry point',
    disableCta: 'Disable entry point',
  },

  createForm: {
    title: 'Create new entry point',
    nameLabel: 'Name entry point',
    linkOption: {
      title: 'Link / QR code',
      description:
        'Generate a link or QR code to bring customers to chat with you.',
    },
    klaviyoOption: {
      title: 'Klaviyo popup',
      description: 'Enroll customers from Klaviyo pop-ups and forms',
    },
    shopifyOption: {
      title: 'Shopify checkout',
      description:
        'Turn your Shopify checkout into a growth machine to bring more contacts in your WhatsApp audience.',
    },
  },

  updateNameForm: {
    title: 'Update entry point name',
    nameLabel: 'New name',
    namePlaceholder: 'Entry point name',
  },

  keywordForm: {
    type: 'Wa.me link',

    configure: {
      title: 'Configure',
      keywordLabel: 'Pre-fill message',
      keywordPlaceholder: 'I want to join the WhatsApp club',
      optinLabel: 'Opt-in collection',
      optinPlaceholder:
        'Customers entering this entry point are considered opt-in WhatsApp',
      confirmationLabel: 'Reply message',
      confirmationPlaceholder: 'You are now part of the WhatsApp club',
    },

    setup: {
      title: 'Setup',
      linkTitle: 'Copy link or download QR code',
      linkDescription:
        'Both methods get your customers in WhatsApp with the same experience.',
      linkCardTitle: 'Link',
      linkCardDescription:
        'Get customers on mobile from browsing to chatting with your brand on WhatsApp seamlessly.',
      linkCardCta: 'Copy link',
      qrCardTitle: 'QR code',
      qrCardDescription:
        'Get customers from desktop or physical interface to WhatsApp easily.',
      qrCardCta: 'Download QR code',
      ideasTitle: 'Place it everywhere',
      ideasDescription:
        'Entry points can be placed in various places across customer experience. Multiply entry points to start more WhatsApp conversations.',
      ideas: {
        button: 'Button',
        socialMedia: 'Social media',
        email: 'Email signature',
        home: 'Homepage',
        product: 'Product page',
        klaviyo: 'Klaviyo popup',
        print: 'Print & flyers',
        physical: 'Physical products',
      },
    },

    howItWorks: {
      title: 'How it works',

      firstStepTitle: 'Contact enter by',
      qrCode: 'Scanning QR code',
      or: 'or',
      link: 'Clicking link',

      secondStepTitle: 'Then are redirected to WhatsApp',
      userMessagePlaceholder:
        'Contact will send the pre-fill text message you configured',
      optin: 'Opt-in collection',
    },
  },

  klaviyoForm: {
    type: 'Klaviyo popup',

    configure: {
      title: 'Configure',
      optinLabel: 'Opt-in collection',
      optinPlaceholder:
        'Customers entering this entry point are considered opt-in WhatsApp',
      confirmationLabel: 'Confirmation message',
      confirmationPlaceholder:
        'While the confirmation text isn’t mandatory, it’s highly recommended to ensure your customers are aware they are opting-in to WhatsApp.',
    },

    setup: {
      title: 'Setup',

      firstStepTitle:
        'Pre-requisite : Have your Klaviyo Pop-up already set-up.',
      firstStepDescription:
        'This entry point is attached to your Klaviyo pop-up, you need to have it for next steps.',
      firstStepCta: 'Create your Klaviyo pop-up',

      secondStepTitle: 'Make your pop-up GDPR Compliant',
      secondStepDescription:
        'You have to be explicit with your customers when collecting WhatsApp opt-in. To help you being compliant, we provide you with a text inspiration you can display in the form.',
      secondStepGdprText:
        'By submitting this form and signing up, you consent to receive WhatsApp communications from {{companyName}}. Ask to unsubscribe any time directly in the conversation.',
      secondStepCta: 'See tutorial',

      thirdStepTitle: 'Copy this attribute to your pop-up hidden fields',
      thirdStepDescription:
        'Copy and paste this attribute in a Hidden field in Klaviyo Form to ensure contacts get opted-in',
      thirdStepFieldNameLabel: 'Field name',
      thirdStepFieldValueLabel: 'Field value',
      thirdStepCta: 'See tutorial',
    },

    howItWorks: {
      title: 'How it works',
      firstStepTitle: 'Contacts enter by',
      firstStepDescription: 'Entering phone number in popup',

      secondStepTitle: 'We collect opt-in',

      thirdStepTitle: 'Contact receives confirmation message',
    },
  },

  shopifyForm: {
    createCta: 'Create entry point',
    updateCta: 'Save modifications',
    type: 'Shopify checkout',

    configure: {
      title: 'Configure',
      optinLabel: 'Opt-in collection',
      optinPlaceholder:
        'Customers entering this entry point are considered opt-in WhatsApp',
      phoneAttributePlaceholder: 'Select Shopify field to sync opt-in',
      phoneNumberAttributeBilling: 'Billing address phone number',
      phoneNumberAttributeCustomer: 'Customer phone number',
      confirmationLabel: 'Confirmation message',
      confirmationPlaceholder:
        'While the confirmation text isn’t mandatory, it’s highly recommended to ensure your customers are aware they are opting-in to WhatsApp.',
    },

    setup: {
      title: 'Setup',

      firstStepTitle:
        'Pre-requisite : Have your Shopify checkout already set up.',
      firstStepDescription:
        'This entry point is attached to your Shopify checkout, you need to have it for next step.',
      firstStepCta: 'Create your Shopify checkout',

      secondStepTitle: 'Make your pop-up GDPR Compliant',
      secondStepDescription:
        'You have to be explicit with your customers when collecting WhatsApp opt-in. To help you being compliant, we provide you with a text inspiration you can display in the form.',
      secondStepGdprText:
        'By submitting this form and signing up, you consent to receive WhatsApp communications from {{companyName}}. Ask to unsubscribe any time directly in the conversation.',
      secondStepCta: 'See tutorial',
    },

    howItWorks: {
      title: 'How it works',
      firstStepTitle: 'Contacts enter by',
      firstStepDescription: 'Shopify checkout',

      secondStepTitle: 'We collect opt-in',

      thirdStepTitle: 'Contact receives confirmation message',
    },
  },
};
