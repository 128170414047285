import { createContext, ReactNode, useMemo, useRef } from 'react';

export type DocumentTitle = {
  pageName?: string;
  notificationCount?: number;
  organizationName?: string;
};

export const DocumentTitleContext = createContext<
  Nullable<{
    setDocumentTitle: (titleObject: DocumentTitle) => void;
  }>
>(null);

const getDocumentTitle = (titleObject: DocumentTitle) => {
  const title: string[] = [];

  if (titleObject.notificationCount) {
    title.push(`(${titleObject.notificationCount})`);
  }

  if (titleObject.pageName) {
    title.push(titleObject.pageName);
  }

  if (titleObject.organizationName) {
    if (titleObject.pageName) {
      title.push('|');
    }

    title.push(titleObject.organizationName);
  }

  return title.join(' ');
};

export const DocumentTitleProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const titleObjectRef = useRef<DocumentTitle>({});

  const value = useMemo(
    () => ({
      setDocumentTitle: (titleObject: DocumentTitle) => {
        titleObjectRef.current = { ...titleObjectRef.current, ...titleObject };
        document.title = getDocumentTitle(titleObjectRef.current);
      },
    }),
    []
  );

  return (
    <DocumentTitleContext.Provider value={value}>
      {children}
    </DocumentTitleContext.Provider>
  );
};
