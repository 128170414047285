import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from './en';

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: 'shared',
    resources: { en },
    interpolation: {
      escapeValue: false,
    },
  });
};
declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'shared';
    resources: typeof en;
  }
}
