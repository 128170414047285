import { ICampaignKioskSegment, ICampaignStatus } from 'models';

export const campaigns = {
  pageTitle: 'Campaigns',

  // MODEL
  status: {
    [ICampaignStatus.DRAFT]: 'Draft',
    [ICampaignStatus.ERROR]: 'Error',
    [ICampaignStatus.SCHEDULED]: 'Scheduled',
    [ICampaignStatus.SENT]: 'Sent',
  },

  target: {
    kiosk: {
      [ICampaignKioskSegment.OPTED_IN]: 'Kiosk subscribers list',
    },
  },

  create: {
    title: 'Create a new campaign',
    description:
      'Get started directly from our pre-made templates or get creative and start from scratch',
    subtitle: 'Engage an audience massively with a personnalised message.',
    cta: 'Create campaign',
    nameLabel: 'Name',
  },

  updateName: {
    title: 'Edit campaign name',
    scheduledCampaignWarningFirstLine:
      'Editing this scheduled campaign will result in unscheduling it.',
    scheduledCampaignWarningSecondLine:
      "Don't forget to reschedule it after editing its name.",
  },

  displayLink: {
    details: 'Details',
    performance: 'Performance',
    responses: 'Responses',
  },

  updateSteps: {
    audience: 'Audience',
    content: 'Content',
    schedule: 'Recap & Scheduling',
  },

  displayAudience: {
    title: 'Audience',
    subtitle: 'This campaign will be sent to the following audience',
    estimatedAudience: 'contacts will receive this campaign',
    excludeContactsMessagedLast24Hours:
      'Contacts that were <strong>contacted in the last 24 hours</strong> will <strong>not</strong> receive the campaign',
    includeContactsMessagedLast24Hours:
      'Contacts that were <strong>contacted in the last 24 hours</strong> will <strong>also</strong> receive the campaign',
    optedInContacts:
      'Contacts that <strong>never opted</strong> will <strong>not</strong> receive the campaign',
    neverOptedContacts:
      'Contacts that <strong>never opted</strong> will <strong>also</strong> receive the campaign',
  },

  updateAudience: {
    title: 'Select your campaign audience',
    subtitle: 'Who are you sending this campaign to?',
    targetLabel: 'Select contact list',
    includeNeverOptedContacts: 'Also send to contacts that never opted',
    contactsThatWontReceive: 'Contacts that won’t receive',
    contactsThatWillReceive: 'Contacts that will receive',
    includeContactsMessagedLast24Hours:
      'Also send to contacts that were contacted in the last 24 hours.',
    includeContactsMessagedLast24HoursDescription:
      'It is not recommended to send multiple campaigns to the same contact over a short period of time.',
  },

  backButtonError: {
    title: 'Invalid data',
    content:
      'The data is not valid and cannot be saved. Do you want to proceed without saving ?',
    proceedCta: 'Proceed without saving',
  },

  displayContent: {
    title: 'Content',
    subtitle: 'Test your campaign directly on your phone',
  },

  updateContent: {
    title: 'What type of campaign are you aiming to create?',
    singleMessageTitle: 'Single message campaign',
    singleMessageDescription: 'Send a single message to your audience',
    interactiveTitle: 'Interactive campaign',
    interactiveDescription: 'Send a series of messages to your audience',
    composeTitle: 'Compose your message',
    ctaLoading: 'Validating your content',
  },

  displaySchedule: {
    title: 'Schedule',
    subtitle: 'The moment when the campaign is sent',
    futureSchedule: 'Campaign will be sent on',
    pastSchedule: 'Campaign was sent on',
  },

  updateSchedule: {
    nowCta: 'Send campaign',
    laterCta: 'Schedule campaign',
    title: 'Schedule',
    subtitle: 'The moment when the campaign is sent',
    now: 'Send now',
    nowDescription: 'I want to send my campaign right now !',
    nowHelper: 'Campaign will launch as soon as you hit',
    later: 'Schedule for later',
    laterDescription: 'I want to send my campaign later',
    laterLabel: 'Select date & time',
    laterHelper: 'Campaign will be sent on',
  },

  notifications: {
    cancelSuccessTitle: 'Campaign successfully unscheduled!',
    cancelSuccessContent:
      'Your campaign has been unscheduled. You can schedule it again later.',
    scheduleSuccessTitle: 'Campaign successfully scheduled!',
    sendSuccessTitle: 'Campaign started to send!',
    scheduleSuccessContent:
      'Your campaign has been scheduled. It will be sent at the specified date.',
    sendSuccessContent: 'Your campaign is currently being sent.',
    scheduleTemplatePendingTitle: 'Template pending',
    scheduleTemplatePendingContent:
      'The template is still pending, try again in a few seconds.',
    scheduleTemplateRejectedTitle: 'Template rejected',
    scheduleTemplateRejectedContent:
      'The template was rejected. Try updating the template and schedule again.',
  },

  listSubtitle: 'Engage an Audience massively with a personnalised message.',
  listEmptyStateTitle: 'No campaigns found',
  listEmptyStateDescription:
    'Try changing the filters or create a new campaign.',

  listHeaders: {
    name: 'Name',
    status: 'Status',
    audience: 'Audience',
    sentMessage: 'Message sent',
    launchDate: 'Launch date',
    delivered: 'Delivered',
    interacted: 'Interacted',
    ordered: 'Ordered',
    revenue: 'Revenue',
  },

  listFilters: {
    all: 'All',
    [ICampaignStatus.DRAFT]: 'Draft',
    [ICampaignStatus.ERROR]: 'Error',
    [ICampaignStatus.SCHEDULED]: 'Scheduled',
    [ICampaignStatus.SENT]: 'Sent',
  },

  templates: {
    description: 'Explore our library and create your campaign',
    startFromScratch: 'Start from scratch',
    startFromScratchDescription: 'Get creative and create your own campaign.',
    useTemplate: 'Use template',
  },

  templateMissingIntegration: {
    title: 'Missing {{integration}} integration',
    content:
      'You’ll need to connect your <integration>{{integration}}</integration> account to be able to use this template.',
  },
};
