import { cva } from 'cva';
import { classNames, mergeClassNames } from '@/utils/classNames';
import { RingLoaderIcon } from '@ui/icons';
import {
  ElementType,
  ReactNode,
  Ref,
  RefAttributes,
  forwardRef,
  ForwardedRef,
  ComponentProps,
  ComponentPropsWithoutRef,
} from 'react';
import { Slot } from '@radix-ui/react-slot';

export const buttonVariants = [
  'primary-brand',
  'primary-error',
  'primary-indigo',
  'secondary-brand',
  'soft-brand',
  'soft-error',
  'soft-indigo',
  'subtle-brand',
  'subtle-error',
  'subtle-indigo',
  'overlay',
] as const;

type ButtonConfigProps<T extends ElementType> = {
  as?: T;
  /** The type of button that will be rendered */
  variant?: (typeof buttonVariants)[number];
  inline?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /** Icon rendered instead of text  */
  icon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export type ButtonProps<T extends ElementType> = ButtonConfigProps<T> &
  Omit<ComponentPropsWithoutRef<T>, keyof ButtonConfigProps<T>>;

const buttonClasses = cva(
  'relative text-sm font-medium inline-flex items-center justify-center transition focus:outline-none focus-visible:ring',
  {
    variants: {
      variant: {
        'primary-brand':
          'bg-neutral-900 border border-transparent hover:bg-black text-white aria-[disabled=true]:bg-neutral-300',
        'primary-error':
          'border border-transparent shadow-sm bg-rose-600 text-white hover:bg-rose-700 aria-[disabled=true]:bg-rose-300',
        'primary-indigo':
          'border border-transparent shadow-sm bg-indigo-600 text-white hover:bg-indigo-700 aria-[disabled=true]:bg-indigo-300',
        'secondary-brand':
          'shadow-sm border bg-white hover:bg-neutral-50 aria-[disabled=true]:bg-neutral-50 aria-[disabled=true]:text-neutral-400 text-neutral-900',
        'soft-brand':
          'border border-transparent shadow-sm bg-neutral-100 hover:bg-neutral-200 text-neutral-900 aria-[disabled=true]:bg-neutral-50 aria-[disabled=true]:text-neutral-400',
        'soft-error':
          'border border-transparent shadow-sm bg-rose-50 hover:bg-rose-100 text-rose-600 aria-[disabled=true]:bg-rose-50 aria-[disabled=true]:text-rose-300',
        'soft-indigo':
          'border border-transparent shadow-sm bg-indigo-50 hover:bg-indigo-100 text-indigo-600 aria-[disabled=true]:bg-indigo-50 aria-[disabled=true]:text-neutral-400',
        'subtle-brand':
          'border border-transparent bg-inherit text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 aria-[disabled=true]:bg-inherit aria-[disabled=true]:text-neutral-400',
        'subtle-error':
          'border border-transparent bg-inherit text-rose-600 hover:bg-rose-50 aria-[disabled=true]:bg-inherit aria-[disabled=true]:text-rose-300',
        'subtle-indigo':
          'border border-transparent bg-inherit text-indigo-600 hover:bg-indigo-50 aria-[disabled=true]:bg-inherit aria-[disabled=true]:text-indigo-300',
        overlay:
          'border border-transparent bg-neutral-600/50 text-white hover:bg-neutral-600/60 aria-[disabled=true]:bg-neutral-600/30 aria-[disabled=true]:text-white/50',
      },
      size: {
        xxs: 'p-1 data-[icon=false]:px-2 data-[inline=true]:-mx-2 rounded-lg gap-1',
        xs: 'p-1 data-[icon=false]:px-2 data-[inline=true]:-mx-2 rounded-lg gap-1',
        sm: 'p-1.5 data-[icon=false]:px-2 data-[inline=true]:-mx-2 rounded-lg gap-1',
        md: 'p-2 data-[icon=false]:px-3 data-[inline=true]:-mx-3 rounded-xl gap-2',
        lg: 'p-2.5 data-[icon=false]:px-3 data-[inline=true]:-mx-3 rounded-xl gap-2',
        xl: 'p-3 data-[icon=false]:px-6 data-[inline=true]:-mx-6 rounded-2xl gap-2',
      },
    },
  }
);

const iconClasses = cva('block', {
  variants: {
    size: {
      xxs: 'size-4',
      xs: 'size-5',
      sm: 'size-5',
      md: 'size-5',
      lg: 'size-5',
      xl: 'size-5',
    },
  },
});

export const Button = fixedForwardRef(
  <T extends ElementType = 'button'>(
    {
      as,
      children,
      variant = 'primary-brand',
      className,
      size = 'md',
      icon,
      loading,
      inline,
      disabled,
      ...rest
    }: ButtonProps<T>,
    ref: ForwardedRef<ComponentProps<T>>
  ) => {
    const Component = as ?? 'button';
    const buttonType =
      Component === 'button' ? { type: 'button' as const } : {};

    return (
      <Component
        ref={ref}
        className={mergeClassNames(buttonClasses({ variant, size }), className)}
        data-icon={!!icon}
        data-inline={inline}
        {...buttonType}
        {...rest}
        disabled={disabled}
        aria-disabled={disabled}
      >
        {icon ? (
          <>
            {children && <span className="sr-only">{children}</span>}
            <Slot className={iconClasses({ size })}>{icon}</Slot>
          </>
        ) : (
          children
        )}
        <span
          className={classNames(
            'absolute inset-0 flex items-center justify-center rounded-inherit bg-inherit text-inherit',
            !loading && 'hidden'
          )}
        >
          <RingLoaderIcon className="h-4 w-4" />
        </span>
      </Component>
    );
  }
);

function fixedForwardRef<T, P extends object>(
  render: (props: P, ref: Ref<T>) => ReactNode
): (props: P & RefAttributes<T>) => ReactNode {
  return forwardRef<any, any>(render);
}
