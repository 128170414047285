import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgHelpCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M18.7502 10.25H16.25C15.6977 10.25 15.25 9.80228 15.25 9.25V6.75C15.25 6.19772 15.6977 5.75 16.25 5.75H18.7502C19.3024 5.75 19.7502 6.19772 19.7502 6.75V9.25C19.7502 9.80228 19.3024 10.25 18.7502 10.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 5.75H11.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 10.25H11.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 14.25H19.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 18.25H19.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHelpCircle);
export default ForwardRef;
