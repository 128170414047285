import { InviteNewUserInputDto, UserOutputDto } from 'dto';
import { payloadToDto, payloadToDtos } from '@/utils/dto.ts';
import { apiClient } from '@/services/ApiService';
import { SettingsNotificationType } from '@/features/agents';
import { getAccessTokenPayload } from './AccessTokenService';

type ControlledErrorResponse = { response: { data: { message: string } } };

export async function getAgentById(id: string): Promise<UserOutputDto> {
  const response = await apiClient.get(`/users/${id}`);

  return payloadToDto(UserOutputDto, response.data);
}

export async function getAgents(): Promise<UserOutputDto[]> {
  const response = await apiClient.get('/users');

  return payloadToDtos(UserOutputDto, response.data);
}

export async function inviteNewAgent(
  data: Omit<InviteNewUserInputDto, 'organizationId'>
): Promise<UserOutputDto> {
  const { organizationId } = await getAccessTokenPayload();

  const response = await apiClient.post('/users/invite-new-user', {
    ...data,
    organizationId,
  });

  return payloadToDto(UserOutputDto, response.data);
}

export async function updateNotificationsSettings({
  notificationType,
  enabled,
}: {
  notificationType: SettingsNotificationType;
  enabled: boolean;
}): Promise<UserOutputDto> {
  try {
    const response = await apiClient.patch('/users/me', {
      settings: { notifications: { [notificationType]: enabled } },
    });

    return payloadToDto(UserOutputDto, response.data);
  } catch (error) {
    throw new Error(
      (error as ControlledErrorResponse)?.response?.data?.message
    );
  }
}

export const archiveAgent = async ({ id }: { id: string }) => {
  await apiClient.delete(`/users/${id}`);
};
