import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSatellite = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.satellite_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M20.828 14.357A8.25 8.25 0 0 1 9.161 2.689ZM21.359 5.341a2.25 2.25 0 1 1 0-3.182 2.249 2.249 0 0 1 0 3.182ZM18.177 5.341l-3.182 3.182M3.25 22.522l4.243-4.242a1.5 1.5 0 0 1 2.121 0l4.243 4.242"
      className="satellite_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSatellite);
export default ForwardRef;
