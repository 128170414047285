import {
  useQuery,
  useMutation,
  useQueryClient,
  useApiErrorNotification,
} from '@/hooks';
import { useNotification } from '@/hooks/shared/useNotification';
import {
  getAgents,
  inviteNewAgent,
  updateNotificationsSettings,
} from '@/services/AgentsService';
import { UserOutputDto } from 'dto';
import { useTranslation } from 'react-i18next';

const CACHE_KEY = ['agents'];

export const useAgents = () => {
  const { t } = useTranslation(['settings']);
  const queryClient = useQueryClient();
  const { showErrorNotification } = useApiErrorNotification();
  const { showSuccessNotification } = useNotification();

  const { data: agents, isPending: agentsLoading } = useQuery({
    queryKey: CACHE_KEY,
    queryFn: getAgents,
  });

  const { mutateAsync: createAgent, isPending: createAgentLoading } =
    useMutation({
      mutationFn: inviteNewAgent,
      onSuccess: (newData) => {
        queryClient.setQueryData(CACHE_KEY, (oldData?: UserOutputDto[]) => {
          if (!oldData) return;

          return [...oldData, newData];
        });

        showSuccessNotification({
          title: t('settings:agentCreatedSuccessNotification.title'),
          content: t('settings:agentCreatedSuccessNotification.content', {
            firstName: newData.firstName,
          }),
        });
      },
      onError: (error) => showErrorNotification(error),
    });

  const {
    mutateAsync: updateCurrentAgentSettings,
    isPending: updateCurrentAgentSettingsLoading,
  } = useMutation({
    mutationFn: updateNotificationsSettings,
    onSuccess: (newData, variables) => {
      queryClient.setQueryData(CACHE_KEY, (oldData?: UserOutputDto[]) => {
        if (!oldData) return;

        const index = oldData.findIndex(({ id }) => id === newData.id);
        if (index > -1) {
          const data = [...oldData];
          data.splice(index, 1, newData);

          return data;
        }
      });

      showSuccessNotification({
        title: t('settings:notifications.successNotification.title'),
        content: variables.enabled
          ? t('settings:notifications.successNotification.enabled')
          : t('settings:notifications.successNotification.disabled'),
      });
    },
    onError: (error) => showErrorNotification(error),
  });

  return {
    agents,
    agentsLoading,
    createAgent,
    createAgentLoading,
    updateCurrentAgentSettings,
    updateCurrentAgentSettingsLoading,
  };
};
