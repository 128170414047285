import { ComponentProps } from 'react';
import { classNames } from '@/utils/classNames';
import { BrandLogoOption, getBrandLogoSrc } from './getBrandLogoSrc';

export const BrandLogo = ({
  brand,
  loading = 'lazy',
  className,
  ...imgPropsWeDontControl
}: {
  brand: BrandLogoOption;
} & ComponentProps<'img'>) => (
  <img
    alt={brand}
    className={classNames('object-contain', className)}
    {...imgPropsWeDontControl}
    loading={loading}
    src={getBrandLogoSrc(brand)}
  />
);
