import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgHelpCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M19.75 12C19.75 16.0041 16.5041 19.25 12.5 19.25C8.49594 19.25 5.25 16.0041 5.25 12C5.25 7.99594 8.49594 4.75 12.5 4.75C16.5041 4.75 19.75 7.99594 19.75 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.25 10C10.25 10 10.5 7.75 12.5 7.75C14.5 7.75 14.75 9 14.75 10C14.75 10.7513 14.3266 11.5027 13.4798 11.8299C12.9647 12.0289 12.5 12.4477 12.5 13V13.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 16C13 16.2761 12.7761 16.5 12.5 16.5C12.2239 16.5 12 16.2761 12 16C12 15.7239 12.2239 15.5 12.5 15.5C12.7761 15.5 13 15.7239 13 16Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHelpCircle);
export default ForwardRef;
