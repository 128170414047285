import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M13.25 16C13.25 16.2761 13.0261 16.5 12.75 16.5C12.4739 16.5 12.25 16.2761 12.25 16C12.25 15.7239 12.4739 15.5 12.75 15.5C13.0261 15.5 13.25 15.7239 13.25 16Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 12C13.25 12.2761 13.0261 12.5 12.75 12.5C12.4739 12.5 12.25 12.2761 12.25 12C12.25 11.7239 12.4739 11.5 12.75 11.5C13.0261 11.5 13.25 11.7239 13.25 12Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 8C13.25 8.27614 13.0261 8.5 12.75 8.5C12.4739 8.5 12.25 8.27614 12.25 8C12.25 7.72386 12.4739 7.5 12.75 7.5C13.0261 7.5 13.25 7.72386 13.25 8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
