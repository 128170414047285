import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 12C7.75 11.7348 7.85536 11.4804 8.04289 11.2929C8.23043 11.1054 8.48478 11 8.75 11C9.01522 11 9.26957 11.1054 9.45711 11.2929C9.64464 11.4804 9.75 11.7348 9.75 12C9.75 12.2652 9.64464 12.5196 9.45711 12.7071C9.26957 12.8946 9.01522 13 8.75 13C8.48478 13 8.23043 12.8946 8.04289 12.7071C7.85536 12.5196 7.75 12.2652 7.75 12ZM11.75 12C11.75 11.7348 11.8554 11.4804 12.0429 11.2929C12.2304 11.1054 12.4848 11 12.75 11C13.0152 11 13.2696 11.1054 13.4571 11.2929C13.6446 11.4804 13.75 11.7348 13.75 12C13.75 12.2652 13.6446 12.5196 13.4571 12.7071C13.2696 12.8946 13.0152 13 12.75 13C12.4848 13 12.2304 12.8946 12.0429 12.7071C11.8554 12.5196 11.75 12.2652 11.75 12ZM15.75 12C15.75 11.7348 15.8554 11.4804 16.0429 11.2929C16.2304 11.1054 16.4848 11 16.75 11C17.0152 11 17.2696 11.1054 17.4571 11.2929C17.6446 11.4804 17.75 11.7348 17.75 12C17.75 12.2652 17.6446 12.5196 17.4571 12.7071C17.2696 12.8946 17.0152 13 16.75 13C16.4848 13 16.2304 12.8946 16.0429 12.7071C15.8554 12.5196 15.75 12.2652 15.75 12Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
