import { useQuery } from '@/hooks';
import { useCurrentAgent } from '@/features/agents';
import {
  fetchUnrepliedConversationsCounts,
  ConversationsAssigneeFilter,
} from '@/services/ConversationsService';
import { useMemo } from 'react';

export const useUnrepliedConversationsCount = () => {
  const { currentAgent } = useCurrentAgent();

  const { data } = useQuery({
    queryKey: ['unrepliedConversationsCount'],
    queryFn: fetchUnrepliedConversationsCounts,
    staleTime: Infinity,
  });

  return useMemo(
    () => ({
      [ConversationsAssigneeFilter.ALL]: data?.total ?? null,
      [ConversationsAssigneeFilter.MY]:
        data?.assigned[currentAgent?.id ?? ''] ?? null,
      [ConversationsAssigneeFilter.UNASSIGNED]: data?.unassigned ?? null,
    }),
    [data, currentAgent?.id]
  );
};
