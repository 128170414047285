import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgConversation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.conversation_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M23.25 13.5A5.249 5.249 0 0 0 18 8.25h-3a5.25 5.25 0 0 0 0 10.5h.75l4.5 4.5v-5.024a5.237 5.237 0 0 0 3-4.726ZM6.75 12.75l-3 3v-5.024A5.239 5.239 0 0 1 6 .75h3a5.252 5.252 0 0 1 5.033 3.75"
      className="conversation_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgConversation);
export default ForwardRef;
