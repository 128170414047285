export const organization = {
  preview: {
    title: 'Preview',
    professionalAccount: 'This is a business account',
  },

  updateForm: {
    profilePictureLabel: 'Profile picture',
    businessDescriptionLabel: 'Business description',
    businessNameLabel: 'Name',
    businessNameTooltip:
      'Business name can only be changed through the Meta Interface.',
    businessWebsiteLabel: 'Website',
    businessEmailLabel: 'Email',
    businessAddressLabel: 'Address',
    advancedSettings: 'Advanced settings',
  },
};
