import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCodeBrackets = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 21H8c-1.105 0-2-.894-2-1.999V14c0-1-1.5-2-1.5-2S6 11 6 10V5a2 2 0 0 1 2-2h1M15 21h1c1.105 0 2-.894 2-2v-5c0-1 1.5-2 1.5-2S18 11 18 10V5a2 2 0 0 0-2-2h-1"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCodeBrackets);
export default ForwardRef;
