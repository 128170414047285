import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgLivestream = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M16 5.75C17.9586 7.04802 19.5 9.47441 19.5 12C19.5 14.5257 17.9586 16.952 16 18.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 5.75C6.54145 7.04802 5 9.47441 5 12C5 14.5257 6.54145 16.952 8.5 18.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 8.75C15.7722 9.51951 16.5 10.8238 16.5 12C16.5 13.1763 15.7722 14.4805 15 15.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 8.75C8.72784 9.51951 8 10.8238 8 12C8 13.1763 8.72784 14.4805 9.5 15.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 12C12.75 12.2761 12.5261 12.5 12.25 12.5C11.9739 12.5 11.75 12.2761 11.75 12C11.75 11.7239 11.9739 11.5 12.25 11.5C12.5261 11.5 12.75 11.7239 12.75 12Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLivestream);
export default ForwardRef;
