import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPlugOff = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 7.75h8.5v3.593a4 4 0 0 1-1.172 2.829l-1.031 1.031M14.5 19.25H11a1 1 0 0 1-1-1V16l-1.828-1.828A4 4 0 0 1 7 11.343v-.593M10 7.5V4.75M14.5 7.5V4.75M6 4.75l12.5 14.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPlugOff);
export default ForwardRef;
