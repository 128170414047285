import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgChevronLeft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14 8.75 10.5 12l3.5 3.25"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgChevronLeft);
export default ForwardRef;
