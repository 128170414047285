import { QueryClient } from '@/hooks';
import { payloadToDto } from '@/utils/dto';
import { UnrepliedConversationsCountsOutputDto } from 'dto';

export const handleUpdatedUnrepliedConversationsCountsSocketEvent =
  (queryClient: QueryClient) => (payload: any) => {
    const unrepliedConversationCount = payloadToDto(
      UnrepliedConversationsCountsOutputDto,
      payload
    );

    queryClient.setQueryData<UnrepliedConversationsCountsOutputDto>(
      ['unrepliedConversationsCount'],
      () => unrepliedConversationCount
    );
  };
