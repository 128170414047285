import { RouteObject } from 'react-router-dom';

export const router = {
  path: 'dashboard',
  children: [
    {
      index: true,
      lazy: () => import('./Dashboard.page'),
    },
  ],
} satisfies RouteObject;
