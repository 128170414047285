export const API_DOMAIN = import.meta.env.VITE_API_DOMAIN as string;
export const FRONTEND_DOMAIN = import.meta.env.VITE_FRONTEND_DOMAIN as string;
export const SUPERTOKENS_AUTH_PATH = import.meta.env
  .VITE_SUPERTOKENS_AUTH_PATH as string;
export const KIOSK_WHATSAPP_NUMBER = import.meta.env
  .VITE_KIOSK_WHATSAPP_NUMBER as string;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID as number;
export const STAGE = import.meta.env.VITE_STAGE as 'DEV' | 'PROD' | 'STAGING';
export const ZAPIER_APP_URL = import.meta.env.VITE_ZAPIER_APP_URL as string;
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID as string;

export const IS_PROD = STAGE === 'PROD';
export const IS_STAGING = STAGE === 'STAGING';
export const IS_DEV = STAGE === 'DEV';

export const IS_SENTRY_ENABLED = IS_PROD || IS_STAGING;

export const META_FB_LOGIN_CONFIG_ID = import.meta.env
  .VITE_META_FB_LOGIN_CONFIG_ID as string;

export const META_FB_APP_ID = import.meta.env.VITE_META_FB_APP_ID as string;
export const STRIPE_BILLING_PORTAL_URL = import.meta.env
  .VITE_STRIPE_BILLING_PORTAL_URL as string;
