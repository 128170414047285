import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPause = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M15.5 6.75v10.5M9 6.75v10.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPause);
export default ForwardRef;
