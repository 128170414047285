import { useSocketContext } from '@/contexts/Socket';
import { useEffect, useMemo } from 'react';
import { socket } from '@/services/socketio.service';
import { useQueryClient } from '@/hooks';
import { useCurrentAgent } from '@/features/agents';
import { handleMessageSocketEvent } from './socketMutationHandlers/messageSocketEvent';
import { handleUpdatedMessageSocketEvent } from './socketMutationHandlers/updatedMessageSocketEvent.ts';
import { handleUpdatedConversationAssigneeSocketEvent } from './socketMutationHandlers/updatedConversationAssigneeSocketEvent';
import { handleUpdatedConversationStatusSocketEvent } from './socketMutationHandlers/updatedConversationStatusSocketEvent';
import { handleUpdatedUnrepliedConversationsCountsSocketEvent } from './socketMutationHandlers/updatedUnrepliedConversationsCountSocketEvent';

export const ConversationEventsListener = () => {
  const { ready: socketReady } = useSocketContext();
  const { currentAgent } = useCurrentAgent();

  const queryClient = useQueryClient();

  const socketEvents = useMemo(() => {
    if (!currentAgent?.id) return [];

    return [
      {
        event: 'message',
        handler: handleMessageSocketEvent(queryClient, currentAgent.id),
      },
      {
        event: 'updated_message_status',
        handler: handleUpdatedMessageSocketEvent(queryClient),
      },
      {
        event: 'downloaded_message_media',
        handler: handleUpdatedMessageSocketEvent(queryClient),
      },
      {
        event: 'updated_conversation_assignee',
        handler: handleUpdatedConversationAssigneeSocketEvent(
          queryClient,
          currentAgent.id
        ),
      },
      {
        event: 'updated_conversation_status',
        handler: handleUpdatedConversationStatusSocketEvent(
          queryClient,
          currentAgent.id
        ),
      },
      {
        event: 'updated_unreplied_conversations_counts',
        handler:
          handleUpdatedUnrepliedConversationsCountsSocketEvent(queryClient),
      },
    ];
  }, [queryClient, currentAgent]);

  useEffect(() => {
    if (socketReady) {
      socketEvents.forEach(({ event, handler }) => {
        socket.on(event, handler);
      });

      return () => {
        socketEvents.forEach(({ event, handler }) => {
          socket.off(event, handler);
        });
      };
    }
  }, [socketReady, socketEvents]);

  return null;
};
