import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgDone = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      d="M4.79163 10.7221L6.94992 13.6782C7.62639 14.6047 9.01486 14.5867 9.66709 13.643L15.2083 5.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgDone);
export default ForwardRef;
