import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgBuilding = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.446}
      d="M8.625 11.25H6.45c-.42 0-.63 0-.79.082a.75.75 0 0 0-.328.327c-.082.16-.082.371-.082.791v6.3m10.125-7.5h2.175c.42 0 .63 0 .79.082a.75.75 0 0 1 .328.327c.082.16.082.371.082.791v6.3m-3.375 0V7.65c0-.84 0-1.26-.164-1.581a1.5 1.5 0 0 0-.655-.656c-.32-.163-.74-.163-1.581-.163h-1.95c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v11.1m10.875 0h-15m6.75-10.5h1.5m-1.5 3h1.5m-1.5 3h1.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBuilding);
export default ForwardRef;
