import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgLock = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 11.75a1 1 0 0 1 1-1h10.5a1 1 0 0 1 1 1v5.5a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-5.5ZM8 10.5v-.157c0-1.562-.094-3.302.996-4.42.622-.636 1.628-1.173 3.254-1.173 1.626 0 2.632.537 3.254 1.174 1.09 1.117.996 2.857.996 4.419v.157"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLock);
export default ForwardRef;
