import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import { API_DOMAIN } from '@/constants';

export default class SocketIOService {
  socket: Socket;

  constructor() {
    this.socket = io(`${API_DOMAIN}`, {
      transports: ['websocket'], // default is polling
      autoConnect: false,
    });
  }
}

export const { socket } = new SocketIOService();
