import { RouteObject, Navigate } from 'react-router-dom';

export const router = {
  path: 'inbox',
  lazy: () => import('./Inbox.page.tsx'),
  children: [
    {
      index: true,
      element: <Navigate replace to="my" />,
    },
    {
      path: ':conversationsAssigneeFilter',
      children: [
        {
          index: true,
          lazy: () => import('./pages/inboxHome/InboxHome.page.tsx'),
        },
        {
          path: ':conversationId',
          id: 'inboxConversation',
          lazy: () =>
            import('./pages/inboxConversation/InboxConversation.page.tsx'),
        },
      ],
    },
  ],
} satisfies RouteObject;
