import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCloseSmall = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m9.5 8.75 6.5 6.5M16 8.75l-6.5 6.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCloseSmall);
export default ForwardRef;
