import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSingleCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <path
      d="m4.667 8.615 1.846 2.052 5.128-5.334"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSingleCheck);
export default ForwardRef;
