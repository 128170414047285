import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPlay = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M18.5 12 6 5.75v12.5L18.5 12Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPlay);
export default ForwardRef;
