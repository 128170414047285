import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgUpload = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.upload_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M12.001 15.75v-12M16.501 8.25l-4.5-4.5-4.5 4.5M23.251 15.75v1.5a3 3 0 0 1-3 3h-16.5a3 3 0 0 1-3-3v-1.5"
      className="upload_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgUpload);
export default ForwardRef;
