export const shared = {
  actions: {
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    connect: 'Connect',
    copied: 'Copied',
    copy: 'Copy',
    create: 'Create',
    delete: 'Delete',
    disable: 'Disable',
    disconnect: 'Disconnect',
    download: 'Download',
    duplicate: 'Duplicate',
    edit: 'Edit',
    enable: 'Enable',
    manage: 'Manage',
    new: 'New',
    next: 'Next',
    preview: 'Preview',
    save: 'Save',
    saveUpdates: 'Save changes',
    saving: 'Saving',
    search: 'Search',
    searchMin: 'Search (min {{numberOfCharacters}} characters)',
    start: 'Start',
    talkToUs: 'Talk to us',
    validate: 'Validate',
  },

  common: {
    disabled: 'Disabled',
  },

  forms: {
    search: 'Search',
    empty: 'No results',
    select: 'Select',
  },

  dates: {
    weekdays: {
      '1': 'Monday',
      '2': 'Tuesday',
      '3': 'Wednesday',
      '4': 'Thursday',
      '5': 'Friday',
      '6': 'Saturday',
      '0': 'Sunday',
    },
  },

  notifications: {
    success: {
      title: 'Success',
      content: 'Operation completed successfully',
    },
    error: {
      title: 'Something went wrong',
      content: 'Please try again later',
    },
    websocketsClosed: {
      title: 'Connection lost',
      content:
        'We’re having some troubles to reconnect you to our servers. Please reload your window.',
      cta: 'Reload page',
    },
  },

  sidebar: {
    growTitle: 'Grow',
    engageTitle: 'Engage',
    converseTitle: 'Converse',

    dashboard: 'Dashboard',
    inbox: 'Inbox',
    concierge: 'Concierge',
    contacts: 'Audience',
    entrypoints: 'Entry points',
    campaigns: 'Campaigns',
    templates: 'Templates',
    scenarios: 'Automations',

    // settings
    whatsapp: 'WhatsApp settings',
    organization: 'Organization settings',
    personal: 'Personal settings',
    integrations: 'Integrations',
    logout: 'Logout',
  },

  layout: {
    errorDisplay: {
      cta: 'Tell us about it',
      back: 'Back to safety',
    },
  },
};
