import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSendMoney = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.75 10.75a2 2 0 0 1 2-2h4.5a2 2 0 0 1 2 2v6.5a2 2 0 0 1-2 2h-4.5a2 2 0 0 1-2-2v-6.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.25 10.25v-3.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v3.5M14 9v10"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSendMoney);
export default ForwardRef;
