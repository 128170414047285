import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgMenu = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.menu_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path d="M5 5.75h14.5M5 18.25h14.5M5 12h14.5" className="menu_svg__a" />
  </svg>
);

const ForwardRef = forwardRef(SvgMenu);
export default ForwardRef;
