import { IIntegrationType } from 'models';
import hubspot from './assets/brand-hubspot.png';
import klaviyo from './assets/brand-klaviyo.png';
import zapier from './assets/brand-zapier.png';
import shopify from './assets/brand-shopify.png';
import gorgias from './assets/brand-gorgias.png';
import kiosk from './assets/brand-kiosk.png';
import kioskBlue from './assets/brand-kiosk-blue.png';
import kioskOrange from './assets/brand-kiosk-orange.png';
import kioskLetters from './assets/brand-kiosk-letters.svg';
import meta from './assets/brand-meta.png';
import whatsApp from './assets/brand-whatsapp.png';

export type BrandLogoOption =
  | IIntegrationType
  | 'hubspot'
  | 'kiosk'
  | 'kioskBlue'
  | 'kioskLetters'
  | 'kioskOrange'
  | 'klaviyo'
  | 'gorgias'
  | 'meta'
  | 'shopify'
  | 'whatsApp'
  | 'zapier';

export const getBrandLogoSrc = (brand: BrandLogoOption): string => {
  switch (brand) {
    case IIntegrationType.HUBSPOT:
    case 'hubspot':
      return hubspot;
    case 'kiosk':
      return kiosk;
    case 'kioskBlue':
      return kioskBlue;
    case 'kioskLetters':
      return kioskLetters;
    case 'kioskOrange':
      return kioskOrange;
    case IIntegrationType.KLAVIYO:
    case 'klaviyo':
      return klaviyo;
    case 'meta':
      return meta;
    case IIntegrationType.SHOPIFY:
    case 'shopify':
      return shopify;
    case IIntegrationType.GORGIAS:
    case 'gorgias':
      return gorgias;
    case 'whatsApp':
      return whatsApp;
    case 'zapier':
      return zapier;
  }
};
