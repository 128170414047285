import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgFaceIdError = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 4.75h-.5a4 4 0 0 0-4 4v.5M10 19.25h-.5a4 4 0 0 1-4-4v-.5M15.5 4.75h.5a4 4 0 0 1 4 4v.5M15.5 19.25h.5a4 4 0 0 0 4-4v-.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.25 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM15.25 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.5 15.25s.25-1.5 3.25-1.5 3.25 1.5 3.25 1.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgFaceIdError);
export default ForwardRef;
