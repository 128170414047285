import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCursorClick = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m9.5 8.75 4.25 10.5 1.25-5L20 13 9.5 8.75ZM15.75 15 20 19.25M5.5 4.75 7 6.25M14 4.75l-1.5 1.5M7 11.75l-1.5 1.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCursorClick);
export default ForwardRef;
