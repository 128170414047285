export const colors = {
  // utility
  transparent: 'transparent',
  current: 'currentColor',
  inherit: 'inherit',
  black: '#000',
  white: '#fff',

  // main colors
  neutral: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E5E5E5',
    300: '#D4D4D4',
    400: '#A3A3A3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  indigo: {
    50: '#F0F4FE',
    100: '#DCE5FD',
    200: '#C1D2FC',
    300: '#97B5F9',
    400: '#668FF4',
    500: '#3E65EF',
    600: '#2C49E4',
    700: '#2435D1',
    800: '#232DAA',
    900: '#222C86',
  },
  pink: {
    50: '#FCF3FA',
    100: '#FCE8F6',
    200: '#F7D3EF',
    300: '#F193D7',
    400: '#ED79CB',
    500: '#E351B3',
    600: '#D13195',
    700: '#B42279',
    800: '#951F63',
    900: '#7D1F55',
  },
  amber: {
    50: '#FFFCE8',
    100: '#FFF6D4',
    200: '#FBE79F',
    300: '#FFDE6E',
    400: '#FFCF23',
    500: '#EDBC0D',
    600: '#CE8801',
    700: '#A46005',
    800: '#884B0B',
    900: '#733D10',
  },
  orange: {
    50: '#FFF4ED',
    100: '#FFE4D4',
    200: '#FFC5A9',
    300: '#FF9A6D',
    400: '#FE7438',
    500: '#FD5012',
    600: '#EC310A',
    700: '#C31E0A',
    800: '#9D1810',
    900: '#7E140F',
  },
  lime: {
    50: '#F5FBEA',
    100: '#E8F6D2',
    200: '#D2EEA8',
    300: '#B3E175',
    400: '#94CF44',
    500: '#79B62D',
    600: '#5C9120',
    700: '#466F1C',
    800: '#3B581C',
    900: '#324B1C',
  },
  rose: {
    50: '#FEF2F2',
    100: '#FFE4E6',
    200: '#FECDD3',
    300: '#FDA4AF',
    400: '#FB7185',
    500: '#F33E5E',
    600: '#E11D48',
    700: '#BE133C',
    800: '#9F1239',
    900: '#881337',
  },

  // secondary colors
  red: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  yellow: {
    50: '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#EAB308',
    600: '#CA8A04',
    700: '#A16207',
    800: '#854D0E',
    900: '#713F12',
  },
  green: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
  },
  emerald: {
    50: '#ECFDF5',
    100: '#D1FAE5',
    200: '#A7F3D0',
    300: '#6EE7B7',
    400: '#34D399',
    500: '#10B981',
    600: '#059669',
    700: '#047857',
    800: '#065F46',
    900: '#064E3B',
  },
  teal: {
    50: '#F0FDFA',
    100: '#CCFBF1',
    200: '#99F6E4',
    300: '#5EEAD4',
    400: '#2DD4BF',
    500: '#14B8A6',
    600: '#0D9488',
    700: '#0F766E',
    800: '#115E59',
    900: '#134E4A',
  },
  cyan: {
    50: '#ECFEFF',
    100: '#CFFAFE',
    200: '#A5F3FC',
    300: '#67E8F9',
    400: '#22D3EE',
    500: '#06B6D4',
    600: '#0891B2',
    700: '#0E7490',
    800: '#155E75',
    900: '#164E63',
  },
  sky: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#BAE6FD',
    300: '#7DD3FC',
    400: '#38BDF8',
    500: '#0EA5E9',
    600: '#0284C7',
    700: '#0369A1',
    800: '#075985',
    900: '#0C4A6E',
  },
  blue: {
    50: '#EFF6FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3B82F6',
    600: '#2563EB',
    700: '#1D4ED8',
    800: '#1E40AF',
    900: '#1E3A8A',
  },
  violet: {
    50: '#F5F3FF',
    100: '#EDE9FE',
    200: '#DDD6FE',
    300: '#C4B5FD',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7C3AED',
    700: '#6D28D9',
    800: '#5B21B6',
    900: '#4C1D95',
  },
  purple: {
    50: '#FAF5FF',
    100: '#F3E8FF',
    200: '#E9D5FF',
    300: '#D8B4FE',
    400: '#C084FC',
    500: '#A855F7',
    600: '#9333EA',
    700: '#7E22CE',
    800: '#6B21A8',
    900: '#581C87',
  },
  fuchsia: {
    50: '#FDF4FF',
    100: '#FAE8FF',
    200: '#F5D0FE',
    300: '#F0ABFC',
    400: '#E879F9',
    500: '#D946EF',
    600: '#C026D3',
    700: '#A21CAF',
    800: '#86198F',
    900: '#701A75',
  },
} as const;
