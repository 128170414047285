import { ClassConstructor } from 'class-transformer/types/interfaces';
import { plainToInstance } from 'class-transformer';

export function payloadToDto<T, V>(cls: ClassConstructor<T>, payload: any): T {
  return plainToInstance(cls, payload as V, {
    enableImplicitConversion: true,
  });
}

export function payloadToDtos<T, V>(
  cls: ClassConstructor<T>,
  payload: any
): T[] {
  return plainToInstance(cls, payload as V[], {
    enableImplicitConversion: true,
  });
}
