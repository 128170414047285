import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSingleDot = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 3 3"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#single-dot_svg__a)">
      <circle cx={1.5} cy={1.5} r={1.5} fill="currentColor" />
    </g>
    <defs>
      <clipPath id="single-dot_svg__a">
        <path d="M0 0h3v3H0z" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgSingleDot);
export default ForwardRef;
