import { useMemo } from 'react';
import { colors as twColors } from '@/utils/tailwindConfig';

export const colorMap = [
  {
    background: twColors.neutral[200],
    accent: twColors.neutral[500],
  },
  {
    background: twColors.indigo[200],
    accent: twColors.indigo[500],
  },
  {
    background: twColors.red[200],
    accent: twColors.red[500],
  },
  {
    background: twColors.orange[200],
    accent: twColors.orange[500],
  },
  {
    background: twColors.amber[200],
    accent: twColors.amber[500],
  },
  {
    background: twColors.yellow[200],
    accent: twColors.yellow[500],
  },
  {
    background: twColors.lime[200],
    accent: twColors.lime[500],
  },
  {
    background: twColors.green[200],
    accent: twColors.green[500],
  },
  {
    background: twColors.emerald[200],
    accent: twColors.emerald[500],
  },
  {
    background: twColors.teal[200],
    accent: twColors.teal[500],
  },
  {
    background: twColors.cyan[200],
    accent: twColors.cyan[500],
  },
  {
    background: twColors.sky[200],
    accent: twColors.sky[500],
  },
  {
    background: twColors.blue[200],
    accent: twColors.blue[500],
  },
  {
    background: twColors.violet[200],
    accent: twColors.violet[500],
  },
  {
    background: twColors.purple[200],
    accent: twColors.purple[500],
  },
  {
    background: twColors.fuchsia[200],
    accent: twColors.fuchsia[500],
  },
  {
    background: twColors.pink[200],
    accent: twColors.pink[500],
  },
  {
    background: twColors.rose[200],
    accent: twColors.rose[500],
  },
];

export const useTextColor = (text?: Nullable<string>) => {
  const colors = useMemo(() => {
    if (!text) {
      return {
        background: twColors.neutral[200],
        accent: twColors.neutral[500],
      };
    }

    const number = text
      .split('')
      .reduce((acc, curr) => acc + curr.charCodeAt(0), 0);

    return colorMap[number % colorMap.length];
  }, [text]);

  return colors;
};
