import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgDownload = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.5 14.75v1.5a3 3 0 0 0 3 3H17a3 3 0 0 0 3-3v-1.5M12.75 14.25v-9.5M9.5 10.75l3.25 3.5 3.25-3.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgDownload);
export default ForwardRef;
