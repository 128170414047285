import {
  IntegrationOutputDto,
  SearchIntegrationPersonsOutputDto,
  SearchIntegrationPersonsInputDto,
  CreateIntegrationPersonInputDto,
  ContactIntegrationIdentifierOutputDto,
} from 'dto';
import { payloadToDtos, payloadToDto } from '@/utils/dto.ts';
import { apiClient } from '@/services/ApiService';

export async function getIntegrations(): Promise<IntegrationOutputDto[]> {
  const response = await apiClient.get('/integrations');

  return payloadToDtos(IntegrationOutputDto, response.data);
}

export const getIntegrationPersons = async ({
  integrationId,
  ...params
}: { integrationId: string } & SearchIntegrationPersonsInputDto) => {
  const response = await apiClient.get(
    `/integrations/${integrationId}/persons`,
    {
      params,
    }
  );

  return payloadToDto(SearchIntegrationPersonsOutputDto, response.data);
};

export const createIntegrationPerson = async ({
  integrationId,
  ...params
}: { integrationId: string } & CreateIntegrationPersonInputDto) => {
  const response = await apiClient.post(
    `/integrations/${integrationId}/persons`,
    params
  );

  return payloadToDto(ContactIntegrationIdentifierOutputDto, response.data);
};
