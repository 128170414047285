import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgFingerTap = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12.313 11.25.345-5.138a1.276 1.276 0 0 0-2.54-.235L9.25 13.25l-1.604-1.923a1.605 1.605 0 0 0-2.388-.085.018.018 0 0 0-.004.019l1.98 4.87a5 5 0 0 0 4.632 3.119h3.884a4 4 0 0 0 4-4v-1a3 3 0 0 0-3-3h-4.438ZM5.75 8.25h-.5M16.25 8.25h.5M7.75 5.25l-.5-.5M15.25 5.25l.5-.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgFingerTap);
export default ForwardRef;
