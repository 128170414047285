import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgMeta = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="url(#meta_svg__a)"
      d="M7.747 5h-.02l-.025 2.172h.018c1.43 0 2.539 1.128 4.95 5.188l.146.247.01.017 1.35-2.025-.01-.016a40.46 40.46 0 0 0-.915-1.425c-.34-.503-.664-.952-.98-1.353C10.678 5.775 9.344 5 7.748 5Z"
    />
    <path
      fill="url(#meta_svg__b)"
      d="M7.728 5c-1.604.008-3.022 1.045-4.046 2.632l-.009.014 1.88 1.023.009-.014c.598-.9 1.342-1.474 2.14-1.483h.018L7.747 5h-.02Z"
    />
    <path
      fill="url(#meta_svg__c)"
      d="m3.682 7.634-.009.013C3.001 8.695 2.5 9.98 2.228 11.367l-.004.017 2.112.498.003-.018c.226-1.218.655-2.348 1.214-3.194l.009-.014-1.88-1.022Z"
    />
    <path
      fill="url(#meta_svg__d)"
      d="m4.34 11.865-2.112-.498-.003.018A12.29 12.29 0 0 0 2 13.699v.019l2.165.193v-.019a10.426 10.426 0 0 1 .174-2.027Z"
    />
    <path
      fill="url(#meta_svg__e)"
      d="M4.231 14.584a4.522 4.522 0 0 1-.066-.676v-.019L2 13.696v.019c0 .483.04.937.119 1.355l.003.018 2.112-.486a2.384 2.384 0 0 1-.003-.018Z"
    />
    <path
      fill="url(#meta_svg__f)"
      d="M4.724 15.707c-.235-.257-.402-.628-.49-1.102l-.003-.019-2.112.486c0 .007.002.012.003.019.16.838.473 1.536.921 2.065l.012.014 1.682-1.45-.013-.013Z"
    />
    <path
      fill="#0082FB"
      d="M10.983 9.696c-1.273 1.953-2.044 3.178-2.044 3.178-1.696 2.658-2.283 3.254-3.227 3.254a1.29 1.29 0 0 1-.975-.409l-.013-.013-1.68 1.448.011.015c.62.721 1.493 1.122 2.575 1.122 1.635 0 2.812-.771 4.903-4.428l1.472-2.6c-.372-.6-.71-1.119-1.022-1.567Z"
    />
    <path
      fill="url(#meta_svg__g)"
      d="m13.252 6.616-.013.014a13.11 13.11 0 0 0-.968 1.176c.315.401.64.851.98 1.354.4-.618.773-1.118 1.14-1.5l.012-.014-1.151-1.03Z"
    />
    <path
      fill="#0082FB"
      d="M19.432 6.423C18.544 5.526 17.485 5 16.354 5c-1.193 0-2.197.654-3.102 1.615l-.013.013 1.151 1.03.013-.014c.596-.62 1.174-.93 1.813-.93.69 0 1.334.324 1.892.893l.013.013 1.324-1.184-.013-.013Z"
    />
    <path
      fill="url(#meta_svg__h)"
      d="M21.998 13.41c-.05-2.88-1.057-5.454-2.553-6.974l-.013-.013-1.324 1.183.013.013c1.125 1.156 1.897 3.306 1.967 5.79v.02h1.91v-.019Z"
    />
    <path
      fill="url(#meta_svg__i)"
      d="M21.998 13.431v-.019h-1.91a13.682 13.682 0 0 1 .005.37c0 .678-.1 1.226-.306 1.62l-.01.018 1.424 1.481.01-.016c.518-.798.789-1.906.789-3.25 0-.068 0-.136-.002-.204Z"
    />
    <path
      fill="url(#meta_svg__j)"
      d="m19.787 15.4-.01.017c-.178.334-.432.556-.764.653l.65 2.046a2.872 2.872 0 0 0 .364-.152 2.96 2.96 0 0 0 1.174-1.066l.01-.016-1.424-1.482Z"
    />
    <path
      fill="url(#meta_svg__k)"
      d="M18.6 16.125c-.218 0-.41-.032-.598-.116l-.665 2.095c.374.127.772.185 1.217.185.409 0 .785-.062 1.126-.18l-.65-2.045c-.14.042-.285.063-.43.061Z"
    />
    <path
      fill="url(#meta_svg__l)"
      d="m17.27 15.412-.013-.015-1.53 1.59.014.014c.531.567 1.038.918 1.614 1.11l.664-2.092c-.242-.104-.477-.293-.75-.607Z"
    />
    <path
      fill="url(#meta_svg__m)"
      d="M17.257 15.396c-.459-.534-1.026-1.422-1.919-2.858l-1.163-1.94-.01-.016-1.349 2.024.01.016.824 1.387c.799 1.337 1.45 2.304 2.078 2.977l.013.014 1.529-1.59a1.819 1.819 0 0 1-.013-.014Z"
    />
    <defs>
      <linearGradient
        id="meta_svg__a"
        x1={13.278}
        x2={8.798}
        y1={11.8}
        y2={5.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.001} stopColor="#0867DF" />
        <stop offset={0.454} stopColor="#0668E1" />
        <stop offset={0.859} stopColor="#0064E0" />
      </linearGradient>
      <linearGradient
        id="meta_svg__b"
        x1={4.556}
        x2={7.628}
        y1={7.997}
        y2={5.665}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.132} stopColor="#0064DF" />
        <stop offset={0.999} stopColor="#0064E0" />
      </linearGradient>
      <linearGradient
        id="meta_svg__c"
        x1={3.254}
        x2={4.486}
        y1={11.42}
        y2={8.319}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.015} stopColor="#0072EC" />
        <stop offset={0.688} stopColor="#0064DF" />
      </linearGradient>
      <linearGradient
        id="meta_svg__d"
        x1={3.087}
        x2={3.229}
        y1={13.662}
        y2={11.768}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.073} stopColor="#007CF6" />
        <stop offset={0.994} stopColor="#0072EC" />
      </linearGradient>
      <linearGradient
        id="meta_svg__e"
        x1={3.165}
        x2={3.063}
        y1={14.688}
        y2={13.929}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.073} stopColor="#007FF9" />
        <stop offset={1} stopColor="#007CF6" />
      </linearGradient>
      <linearGradient
        id="meta_svg__f"
        x1={3.105}
        x2={3.741}
        y1={14.89}
        y2={16.238}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.073} stopColor="#007FF9" />
        <stop offset={1} stopColor="#0082FB" />
      </linearGradient>
      <linearGradient
        id="meta_svg__g"
        x1={12.873}
        x2={13.713}
        y1={8.368}
        y2={7.206}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopColor="#007FF8" />
        <stop offset={0.914} stopColor="#0082FB" />
      </linearGradient>
      <linearGradient
        id="meta_svg__h"
        x1={19.261}
        x2={21.019}
        y1={6.859}
        y2={13.325}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0082FB" />
        <stop offset={1} stopColor="#0081FA" />
      </linearGradient>
      <linearGradient
        id="meta_svg__i"
        x1={21.443}
        x2={20.331}
        y1={13.574}
        y2={15.822}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.062} stopColor="#0081FA" />
        <stop offset={1} stopColor="#0080F9" />
      </linearGradient>
      <linearGradient
        id="meta_svg__j"
        x1={19.447}
        x2={20.486}
        y1={17.011}
        y2={16.303}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#027AF3" />
        <stop offset={1} stopColor="#0080F9" />
      </linearGradient>
      <linearGradient
        id="meta_svg__k"
        x1={17.816}
        x2={19.261}
        y1={17.149}
        y2={17.149}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0377EF" />
        <stop offset={0.999} stopColor="#0279F1" />
      </linearGradient>
      <linearGradient
        id="meta_svg__l"
        x1={16.559}
        x2={17.598}
        y1={16.355}
        y2={16.967}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.002} stopColor="#0471E9" />
        <stop offset={1} stopColor="#0377EF" />
      </linearGradient>
      <linearGradient
        id="meta_svg__m"
        x1={13.391}
        x2={16.718}
        y1={11.848}
        y2={16.031}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.277} stopColor="#0867DF" />
        <stop offset={1} stopColor="#0471E9" />
      </linearGradient>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgMeta);
export default ForwardRef;
