import { IIntegrationType, IOptInStatus } from 'models';

export const contact = {
  // models
  optInStatus: {
    [IOptInStatus.NEVER_OPTED]: 'Never opted',
    [IOptInStatus.OPTED_IN]: 'Opt-in',
    [IOptInStatus.OPTED_OUT]: 'Opt-out',
  },

  pageTitle: 'Audience',
  description: 'Navigate your contacts and watch your Whatsapp Audience grow.',

  list: {
    name: 'WhatsApp name',
    phoneNumber: 'Phone',
    integrations: 'Integrations',
    optInStatus: 'Opt-in',
    lastMessage: 'Last message',
    lastMessageEmpty: 'No conversation yet',
    metrics: {
      total: 'Total',
      optedIn: 'Opt-in',
      optedOut: 'Opt-out',
      neverOpted: 'Never opted',
      contacts_zero: 'contact',
      contacts_one: 'contact',
      contacts_other: 'contacts',
    },
  },

  details: {
    title: 'Details',

    kiosk: {
      optInStatus: 'Opt-in status',
    },

    actions: {
      createContactIntegrationIdentifier: {
        title: 'Contact linked',
        text: 'Contact has been successfully linked to your CRM.',
      },
      createIntegrationPerson: {
        title: 'Contact created & linked',
        text: 'Contact has been successfully created and linked to your CRM.',
      },
      deleteContactIntegrationIdentifier: {
        title: 'Contact unlinked',
        text: 'Contact has been successfully unlinked from your CRM.',
      },
    },

    notConnected: {
      title: 'Connect your favorite tools',
      content:
        'Synchronize contact information to gain necessary insight and details to have great conversations',
      cta: 'Connect your first app',
      help: 'Need help ?',
    },

    integrationWrapper: {
      toggleDisclosure: 'Show/hide details',
      [IIntegrationType.HUBSPOT]: {
        title: 'Hubspot',
      },
      [IIntegrationType.KLAVIYO]: {
        title: 'Klaviyo',
      },
      [IIntegrationType.SHOPIFY]: {
        title: 'Shopify',
      },
      [IIntegrationType.GORGIAS]: {
        title: 'Gorgias',
      },
    },

    matcher: {
      create: 'Creating & matching contact',
      match: 'Matching contact',
      [IIntegrationType.HUBSPOT]: {
        title: 'Match this conversation with a specific contact in Hubspot.',
        content:
          'Retrieve up-to-date contact information to gain necessary insight and details to have great conversations',
        action: 'New Hubspot contact',
        search: '$t(shared:actions.searchMin, {"numberOfCharacters": 3})',
      },
      [IIntegrationType.KLAVIYO]: {
        title: 'Match this conversation with a specific profile in Klaviyo.',
        content:
          'Retrieve up-to-date contact information to gain necessary insight and details to have great conversations',
        action: 'New Klaviyo profile',
        search: 'Email or phone number (exact match)',
      },
      [IIntegrationType.SHOPIFY]: {
        title: 'Match this conversation with a specific customer in Shopify.',
        content:
          'Retrieve up-to-date contact information to gain necessary insight and details to have great conversations',
        action: 'New Shopify customer',
        search: 'Search',
      },
    },

    details: {
      [IIntegrationType.HUBSPOT]: {
        link: 'Show on hubspot',
        unlink: 'Unlink from hubspot',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        whatsappPhone: 'WhatsApp phone',
        company: 'Company',
        owner: 'Owner',
        dealCard: {
          pipeline: 'Pipeline',
          stage: 'Stage',
          owner: 'Owner',
          lastContacted: 'Last contacted',
        },
      },
      [IIntegrationType.KLAVIYO]: {
        link: 'Show on klaviyo',
        unlink: 'Unlink from klaviyo',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone',
        company: 'Company',
        job: 'Title',
      },
      [IIntegrationType.SHOPIFY]: {
        link: 'Show on Shopify',
        unlink: 'Unlink from Shopify',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phoneNumber: 'Phone number',
        ordersCount: 'Orders count',
        order: {
          status: 'Status',
          date: 'Date',
        },
      },
    },
  },
};
