import { KIOSK_WHATSAPP_NUMBER } from '@/constants';

export const getWhatsAppLink = ({
  phone = KIOSK_WHATSAPP_NUMBER,
  text,
}: {
  phone?: string | number;
  text?: string;
} = {}): string => {
  const url = `https://wa.me/${phone}`;

  if (!text) {
    return url;
  }

  const encodedText = encodeURIComponent(text);

  return `${url}?text=${encodedText}`;
};
