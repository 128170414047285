import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgInbox = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.75 11.75 18.164 6.2a2 2 0 0 0-1.923-1.45H8.76A2 2 0 0 0 6.836 6.2L5.25 11.75M10.714 12.369c-.258-.336-.62-.619-1.043-.619H5.25v5.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-5.5h-4.42c-.425 0-.786.283-1.044.619a2.246 2.246 0 0 1-1.786.881 2.246 2.246 0 0 1-1.786-.881Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgInbox);
export default ForwardRef;
