export const onboarding = {
  phoneNumberStep: {
    title:
      'Do you have a <highlight>phone number</highlight> for {{organizationName}} ?',
    description:
      'During the setup, you will need a phone number that can receive a verification code. It is essential to create your WhatsApp account and complete your setup.',
    hasNoPhoneNumber: {
      title: 'I don’t have a phone number...',
      description:
        'Don’t worry, here’s a list of suggested providers, or you can <callLink>book a call with us</callLink> for assistance',
      providersList: 'Check our providers list',
    },
    hasPhoneNumber: {
      title: 'I have a phone number!',
      description:
        'Great! It looks like you’re ready to set up your WhatsApp Business API account.',
      continueSetup: 'Continue setup',
    },
  },

  metaBusinessStep: {
    title:
      'Do you have a <highlight>Meta Business Account</highlight> for {{organizationName}} ?',
    description:
      'WhatsApp is part of Meta, you will need a Meta Business Account to create a WhatsApp Business API account.',
    hasNoBusinessAccount: {
      title: 'I need to create a Meta Business Account',
      description:
        'You’ll be able to create your an account during the setup. <callLink>Book a call with us</callLink> for assistance.',
      createAccount: 'Create account',
    },
    hasBusinessAccount: {
      title: 'I already have a Meta Business Account',
      description:
        'Great. Please make sure you have <strong>Admin rights</strong> or <inviteButton>invite an account admin</inviteButton>.',
      continueSetup: 'Continue setup',
    },
  },

  whatsAppSetupFinishStep: {
    hasFinishedSetupTitle:
      'Your {{organizationName}} WhatsApp Business Account is <highlight>set-up<highlight> 🎉',
    hasNotFinishedSetupTitle:
      'Almost done. Add a <highlight>payment method</highlight> to finalize your setup.',
    description:
      'WhatsApp charges for <docLink>business-initiated conversations</docLink>. To send campaigns and automations, add a payment method in your Meta Business Manager.',
    setupAccount: {
      title: 'Set up your WhatsApp Business Account',
      description: 'Account connected',
    },
    setupPayment: {
      title: 'Set up WhatsApp Payment Method',
      hasNotFinishedSetupDescription: 'Payment method linked',
      hasFinishedSetupDescription: 'Payment method not linked',
      setupCta: 'Set up',
    },
    continueCta: 'Continue',
    skipCta: 'Skip for now',
  },

  businessProfileStep: {
    title: '<highlight>Polish</highlight> your profile',
    description: 'It’s important to have a nice client-facing profile.',
    submitContent: 'Finalize setup',
  },

  recapStep: {
    title: 'Start Growing your business on WhatsApp with Kiosk',
    description: 'Learn how to use Kiosk to generate revenue with WhatsApp!',
    callToAction: 'Start my 14 days free trial',
    subscriberList: {
      title: 'Create and grow your WhatsApp Opt-in list',
      description: 'Create entry points to drive customers in WhatsApp',
    },
    smartAutomations: {
      title: 'Set up Automations that drive Revenue',
      description:
        'Generate revenue by automating conversation and marketing flows',
    },
    engagingCampaigns: {
      title: 'Send WhatsApp Marketing Campaigns',
      description: 'Drive up to 30x ROI with interactive WhatsApp campaigns',
    },
    reactiveAI: {
      title: 'Manage Conversations with your AI Concierge',
      description: 'Let your AI Concierge handle questions in seconds, 24/7',
    },
    sharedInbox: {
      title: 'Collaborate in a shared inbox',
      description: 'Manage all your WhatsApp conversations in one place',
    },
  },
};
