import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPen = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m5 19.25 4.25-1 9.563-9.563a2.298 2.298 0 1 0-3.25-3.25L6 15l-1 4.25Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPen);
export default ForwardRef;
