import { getAccessTokenPayload } from '@/services/AccessTokenService';
import { INTERCOM_APP_ID } from '@/constants';
import { useCurrentAgent } from '@/features/agents';
import { useEffect } from 'react';

const INTERCOM_DSN = 'https://api-iam.intercom.io';

export const initIntercom = () => {
  window.Intercom('boot', {
    api_base: INTERCOM_DSN,
    app_id: INTERCOM_APP_ID,
  });
};

const updateIntercom = async (email?: string) => {
  const { sub, firstName, lastName, organizationId } =
    await getAccessTokenPayload();

  window.Intercom('update', {
    name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    email,
    user_id: sub || undefined,
    organizationId: organizationId || undefined,
  });
};

export const useInitLoggedUserIntercom = () => {
  const { currentAgent } = useCurrentAgent();

  useEffect(() => {
    updateIntercom(currentAgent?.email);
  }, [currentAgent?.email]);
};
