import {
  CreateOrganizationInput,
  OrganizationOutputDto,
  OrganizationSettingsOutputDto,
  RegisterOrganizationPhoneNumberInputDto,
  UpdateOrganizationFromWaEmbeddedSignupInputDto,
  UpdateOrganizationSettingsInputDto,
  UpdateOrganizationWaProfileInputDto,
  OrganizationStatisticsOutputDto,
  GetOrganizationStatisticsInput,
} from 'dto';
import { payloadToDto } from '@/utils/dto.ts';
import { apiClient } from '@/services/ApiService.ts';
import axios from 'axios';
import { API_DOMAIN } from '@/constants';

export async function getCurrentUserOrganization(): Promise<OrganizationOutputDto> {
  const response = await apiClient.get('/organizations/current');

  return payloadToDto(OrganizationOutputDto, response.data);
}

export const createOrganization = async (data: CreateOrganizationInput) => {
  const response = await axios.post(`${API_DOMAIN}/organizations`, data);

  return payloadToDto(OrganizationOutputDto, response.data);
};

export const updateOrganizationSettings = async (
  data: UpdateOrganizationSettingsInputDto
) => {
  const response = await apiClient.patch('/organizations/settings', data);

  return payloadToDto(OrganizationSettingsOutputDto, response.data);
};

export const updateOrganizationFromWaEmbeddedSignup = async (
  data: UpdateOrganizationFromWaEmbeddedSignupInputDto
) => {
  const response = await apiClient.put('/organizations/whatsapp', data);

  return payloadToDto(OrganizationOutputDto, response.data);
};

export const registerOrganizationPhoneNumber = async (
  data: RegisterOrganizationPhoneNumberInputDto
) => {
  const response = await apiClient.post(
    '/organizations/register-phone-number',
    data
  );

  return payloadToDto(OrganizationOutputDto, response.data);
};

export const updateOrganizationWhatsAppProfile = async (
  data: UpdateOrganizationWaProfileInputDto
) => {
  const response = await apiClient.put('/organizations/whatsapp-profile', data);

  return payloadToDto(OrganizationOutputDto, response.data);
};

export const getOrganizationStatistics = async (
  data: GetOrganizationStatisticsInput
) => {
  const response = await apiClient.get('/organizations/statistics', {
    params: data,
  });

  return payloadToDto(OrganizationStatisticsOutputDto, response.data);
};
