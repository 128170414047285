import { RouterProvider } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationsProvider } from '@/contexts/Notifications/Notifications.provider.tsx';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import {
  API_DOMAIN,
  FRONTEND_DOMAIN,
  SUPERTOKENS_AUTH_PATH,
} from '@/constants';
import { DocumentTitleProvider } from './contexts/DocumentTitle';
import { colours as supertokensColours } from './style/supertokens.style';

import { router } from './pages/router';

SuperTokens.init({
  appInfo: {
    appName: 'Kiosk',
    apiDomain: API_DOMAIN,
    websiteDomain: FRONTEND_DOMAIN,
    apiBasePath: SUPERTOKENS_AUTH_PATH,
    websiteBasePath: SUPERTOKENS_AUTH_PATH,
  },
  // eslint-disable-next-line @typescript-eslint/require-await
  getRedirectionURL: async (context) => {
    if (context.action === 'TO_AUTH') {
      return '/auth/signin';
    }
  },
  recipeList: [
    EmailPassword.init({
      style: supertokensColours,
    }),
    Session.init(),
  ],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
    },
  },
});

if (new URLSearchParams(window.location.search).get('showOnboarding')) {
  localStorage.setItem('showOnboarding', 'true');
}

const App = () => (
  <DocumentTitleProvider>
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <SuperTokensWrapper>
            <RouterProvider router={router()} />
          </SuperTokensWrapper>
        </NotificationsProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </Suspense>
  </DocumentTitleProvider>
);

export default App;
