import {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { notificationsContext } from './Notifications.context.ts';
import { NotificationProps } from '../../components/ui/notification/Notification.tsx';

interface NotificationsProviderProps {
  children: ReactNode;
}

export function NotificationsProvider({
  children,
}: NotificationsProviderProps) {
  const [notifications, setNotifications] = useState<
    ReactElement<NotificationProps>[]
  >([]);

  const addNotification = useCallback(
    (notification: ReactElement<NotificationProps>) => {
      setNotifications((existingNotifications) => [
        ...existingNotifications,
        notification,
      ]);
    },
    []
  );

  const removeNotification = useCallback((key: string) => {
    setNotifications((existingNotifications) =>
      existingNotifications.filter(
        (notification) => notification.props.id !== key
      )
    );
  }, []);

  const value = useMemo(
    () => ({
      addNotification,
      removeNotification,
    }),
    [addNotification, removeNotification]
  );

  return (
    <notificationsContext.Provider value={value}>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification) => (
            <Fragment key={notification.props.id}>{notification}</Fragment>
          ))}
        </div>
      </div>
      {children}
    </notificationsContext.Provider>
  );
}
