import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgVideoCamera = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m15.5 10 3.75-2.25v8.5L15.5 14m-8.75 3.25h6.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2h-6.5a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgVideoCamera);
export default ForwardRef;
