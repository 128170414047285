import {
  DropdownMenu as RadixDropdownMenu,
  DropdownMenuTrigger as RadixDropdownMenuTrigger,
  DropdownMenuPortal as RadixDropdownMenuPortal,
  DropdownMenuContent as RadixDropdownMenuContent,
  DropdownMenuItem as RadixDropdownMenuItem,
  DropdownMenuSeparator as RadixDropdownMenuSeparator,
  DropdownMenuLabel as RadixDropdownMenuLabel,
  DropdownMenuItemProps,
  DropdownMenuContentProps,
} from '@radix-ui/react-dropdown-menu';
import { mergeClassNames } from '@/utils/classNames';

export const DropdownMenu = RadixDropdownMenu;
export const DropdownMenuTrigger = RadixDropdownMenuTrigger;
export const DropdownMenuContent = ({
  className,
  ...props
}: DropdownMenuContentProps) => (
  <RadixDropdownMenuPortal>
    <RadixDropdownMenuContent
      className={mergeClassNames(
        'z-50 min-w-[200px] rounded-xl border-[0.5px] border-neutral-200 bg-white py-2 shadow-lg',
        className
      )}
      sideOffset={6}
      {...props}
    />
  </RadixDropdownMenuPortal>
);

export const DropdownMenuSeparator = RadixDropdownMenuSeparator;
export const DropdownMenuItem = ({
  className,
  ...props
}: DropdownMenuItemProps) => (
  <RadixDropdownMenuItem
    className={mergeClassNames(
      'block cursor-pointer rounded px-3 py-2 transition duration-100 focus:outline-none focus-visible:outline [&[data-highlighted]]:bg-neutral-50',
      className
    )}
    {...props}
  />
);
export const DropdownMenuLabel = RadixDropdownMenuLabel;
