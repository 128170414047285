import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgSmiley = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.smiley_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <circle cx={12} cy={12} r={11.25} className="smiley_svg__a" />
    <path
      d="M8.25 8.625A.375.375 0 1 1 7.875 9a.375.375 0 0 1 .375-.375M15.75 8.625a.375.375 0 1 1-.375.375.375.375 0 0 1 .375-.375M18.048 15a6.752 6.752 0 0 1-12.1 0"
      className="smiley_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgSmiley);
export default ForwardRef;
