import {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useContext,
  createContext,
} from 'react';

import { socket } from '@/services/socketio.service';
import { Button } from '@ui/button';
import { Modal } from '@ui/modal';
import { RefreshIcon, WarningIcon } from '@ui/icons';
import { useTranslation } from 'react-i18next';
import Session from 'supertokens-web-js/recipe/session';

type SocketProviderProps = {
  children: ReactNode;
  authToken: Nullable<string>;
  tokenExpirationTime: Nullable<number>;
};

const socketContext = createContext<Nullable<{ ready: boolean }>>(null);

export function SocketProvider({
  children,
  authToken,
  tokenExpirationTime,
}: SocketProviderProps) {
  const [ready, setReady] = useState(false);
  const { t } = useTranslation('shared');
  const [displayErrorModal, setDisplayErrorModal] = useState(false);

  useEffect(() => {
    function onConnect() {
      setReady(true);
      setDisplayErrorModal(false);
    }
    function onDisconnect() {
      setReady(false);
    }
    function onConnectError() {
      if (!tokenExpirationTime || displayErrorModal) return;

      if (tokenExpirationTime < Date.now() / 1000) {
        Session.attemptRefreshingSession()
          .then((result) => {
            if (!result) setDisplayErrorModal(true);
          })
          .catch(() => {
            setDisplayErrorModal(true);
          });
      }
    }

    if (authToken) {
      socket.auth = { token: authToken };
      socket.connect();
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('connect_error', onConnectError);
    }

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
    };
  }, [authToken, displayErrorModal, tokenExpirationTime]);

  const value = useMemo(() => ({ ready }), [ready]);

  return (
    <socketContext.Provider value={value}>
      <Modal open={displayErrorModal} onClose={() => {}} className="max-w-sm">
        <h3 className="heading-xl flex items-center gap-3">
          <WarningIcon className="h-8 w-8 text-rose-600" />
          {t('notifications.websocketsClosed.title')}...
        </h3>

        <p className="mt-4 leading-relaxed text-neutral-900">
          {t('notifications.websocketsClosed.content')}
        </p>

        <div className="mt-6">
          <Button onClick={() => window.location.reload()}>
            <RefreshIcon className="h-5 w-5" />
            {t('notifications.websocketsClosed.cta')}
          </Button>
        </div>
      </Modal>
      {children}
    </socketContext.Provider>
  );
}

export const useSocketContext = () => {
  const context = useContext(socketContext);
  if (!context) {
    throw new Error('useSocketContext must be used within a SocketProvider');
  }

  return context;
};
