import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5882 6.08828C13.5882 6.68932 13.101 7.17656 12.5 7.17656C11.8989 7.17656 11.4117 6.68932 11.4117 6.08828C11.4117 5.48724 11.8989 5 12.5 5C13.101 5 13.5882 5.48724 13.5882 6.08828ZM8.17656 9.24489C8.17656 9.84593 7.68932 10.3332 7.08828 10.3332C6.48724 10.3332 6 9.84593 6 9.24489C6 8.64386 6.48724 8.15662 7.08828 8.15662C7.68932 8.15662 8.17656 8.64386 8.17656 9.24489ZM13.5882 11.9506C13.5882 12.5516 13.101 13.0389 12.5 13.0389C11.8989 13.0389 11.4117 12.5516 11.4117 11.9506C11.4117 11.3495 11.8989 10.8623 12.5 10.8623C13.101 10.8623 13.5882 11.3495 13.5882 11.9506ZM17.9118 10.3332C18.5128 10.3332 19 9.84593 19 9.24489C19 8.64386 18.5128 8.15662 17.9118 8.15662C17.3107 8.15662 16.8235 8.64386 16.8235 9.24489C16.8235 9.84593 17.3107 10.3332 17.9118 10.3332ZM8.17656 14.6565C8.17656 15.2576 7.68932 15.7448 7.08828 15.7448C6.48724 15.7448 6 15.2576 6 14.6565C6 14.0555 6.48724 13.5682 7.08828 13.5682C7.68932 13.5682 8.17656 14.0555 8.17656 14.6565ZM17.9118 15.7449C18.5128 15.7449 19 15.2577 19 14.6566C19 14.0556 18.5128 13.5684 17.9118 13.5684C17.3107 13.5684 16.8235 14.0556 16.8235 14.6566C16.8235 15.2577 17.3107 15.7449 17.9118 15.7449ZM12.5 18.9014C13.101 18.9014 13.5882 18.4142 13.5882 17.8131C13.5882 17.2121 13.101 16.7249 12.5 16.7249C11.8989 16.7249 11.4117 17.2121 11.4117 17.8131C11.4117 18.4142 11.8989 18.9014 12.5 18.9014Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
