import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgInformation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.5 15v-2"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.75 11.75a7.25 7.25 0 1 1-14.5 0 7.25 7.25 0 0 1 14.5 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgInformation);
export default ForwardRef;
