import { payloadToDto } from '@/utils/dto';
import { QueryClient } from '@/hooks';
import {
  ConversationOutputDto,
  MessageOutputDto,
  SocketMessageOutputDto,
} from 'dto';
import { updateConversationsCache } from '@/features/socketEvents/conversationsCache.helpers.ts';

export const handleMessageSocketEvent =
  (queryClient: QueryClient, agentId: string) => (payload: any) => {
    const { message, conversation } = payloadToDto(
      SocketMessageOutputDto,
      payload
    );

    queryClient
      .getQueryCache()
      .findAll({ queryKey: ['conversations', 'list'] })
      .forEach((query) =>
        updateConversationsCache({
          query,
          queryClient,
          conversation,
          agentId,
        })
      );

    // UPDATING ACTIVE CONVERSATION CACHE
    queryClient.setQueryData<ConversationOutputDto>(
      ['conversations', 'show', conversation.id],
      () => conversation
    );

    // UPDATING ACTIVE CONVERSATION MESSAGES CACHE
    queryClient.setQueryData<MessageOutputDto[]>(
      ['messages', 'list', conversation.id],
      (oldData) => oldData && [...oldData, message]
    );
  };
