export const auth = {
  validationErrors: {
    emailPasswordMismatch: 'Wrong email or password',
    signinNotAllowed: 'Sign in is not allowed',
    strongPassword:
      'Password must contain at least 8 characters, including a number.',
  },

  signin: {
    title: 'Sign in to <highlight>Kiosk</highlight>',
    emailLabel: 'Work e-mail',
    passwordLabel: 'Password',
    signinCta: 'Sign in to Kiosk',
    signupDescription: 'Don’t have an account?',
    signupCta: 'Sign up',
    forgotPassword: 'Forgot your password ?',
    resetPasswordCta: 'Reset password',
  },

  signup: {
    title:
      'Welcome to Kiosk 👋 <br/>Create your <highlight>free</highlight> account',
    description:
      'Use Kiosk to generate revenue from WhatsApp Marketing, build meaningful relationships with customers and grow your Business.',
    organizationNameLabel: 'Organization name',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Work e-mail',
    passwordLabel: 'Password',
    signupCta: 'Create my account',
    signinDescription: 'Already have an account?',
    signinCta: 'Log in',
  },
};
