import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPhoto = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M17.5 4.75H7C5.89543 4.75 5 5.64543 5 6.75V17.25C5 18.3546 5.89543 19.25 7 19.25H17.5C18.6046 19.25 19.5 18.3546 19.5 17.25V6.75C19.5 5.64543 18.6046 4.75 17.5 4.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 16L7.74619 12.5066C8.5249 11.5161 10.0145 11.4837 10.8356 12.4394L13.25 15.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.165 12.8229C12.2022 11.5036 13.6473 9.6345 13.7414 9.51289L13.7513 9.50012C14.5315 8.51593 16.0163 8.48576 16.8356 9.43942L19.25 12.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPhoto);
export default ForwardRef;
