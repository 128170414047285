import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgLinkBreak = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m18 12.25.25-.25a4.243 4.243 0 1 0-6-6l-.25.25M6.5 11.75l-.25.25a4.243 4.243 0 0 0 6 6l.25-.25M8.5 4.75v1.5M16 17.75v1.5M6.5 8.25H5M19.5 15.75H18"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLinkBreak);
export default ForwardRef;
