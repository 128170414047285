import { RouteObject } from 'react-router-dom';

export const router = {
  path: 'concierge',
  children: [
    {
      index: true,
      lazy: () => import('./Concierge.page'),
    },
    {
      path: 'onboarding',
      lazy: () => import('./pages/onboarding/Onboarding.page'),
    },
    {
      path: 'settings',
      lazy: () => import('./pages/settings/Settings.layout'),
      children: [
        {
          index: true,
          lazy: () => import('./pages/settings/SettingsContent.page'),
        },
        {
          path: 'escalation',
          lazy: () => import('./pages/settings/SettingsEscalation.page'),
        },
      ],
    },
  ],
} satisfies RouteObject;
