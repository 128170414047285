import { InfiniteData, Query, QueryClient } from '@/hooks';
import {
  CONVERSATIONS_PAGE_SIZE,
  ConversationsAssigneeFilter,
  ConversationsFilter,
} from '@/services/ConversationsService.ts';
import { ConversationOutputDto, GetConversationsOutputDto } from 'dto';

type QueryCacheKey = ['conversations', 'list', ConversationsFilter];

export const updateConversationsCache = ({
  query,
  queryClient,
  conversation,
  agentId,
}: {
  query: Query;
  queryClient: QueryClient;
  conversation: ConversationOutputDto;
  agentId: string;
}) => {
  const allConversations = mapAndSortConversationsPages(
    (query.state.data as InfiniteData<GetConversationsOutputDto>).pages
  );

  const hasConversationInCache = allConversations.some(
    ({ id }) => id === conversation.id
  );

  const queryKey = query.queryKey as QueryCacheKey;
  const conversationsFilter = queryKey[2];
  const shouldConversationBeIncluded =
    isConversationMatchingFilters({
      conversation,
      conversationsFilter,
      agentId,
    }) &&
    (allConversations.length < CONVERSATIONS_PAGE_SIZE ||
      conversation.sequenceId > allConversations.at(-1)!.sequenceId);

  if (shouldConversationBeIncluded && !hasConversationInCache) {
    addConversationInCache({ query, queryClient, conversation });
  }

  if (shouldConversationBeIncluded && hasConversationInCache) {
    updateConversationInCache({ query, queryClient, conversation });
  }

  if (!shouldConversationBeIncluded) {
    removeConversationInCache({ query, queryClient, conversation });
  }
};

export const addConversationInCache = ({
  query,
  queryClient,
  conversation,
}: {
  query: Query;
  queryClient: QueryClient;
  conversation: ConversationOutputDto;
}) => {
  queryClient.setQueryData<InfiniteData<GetConversationsOutputDto>>(
    query.queryKey,
    (oldData) =>
      oldData && {
        ...oldData,
        pages: oldData.pages.map((page, index) => ({
          ...page,
          conversations:
            index === 0
              ? [conversation, ...page.conversations]
              : page.conversations,
        })),
      }
  );
};

export const removeConversationInCache = ({
  query,
  queryClient,
  conversation,
}: {
  query: Query;
  queryClient: QueryClient;
  conversation: ConversationOutputDto;
}) => {
  queryClient.setQueryData<InfiniteData<GetConversationsOutputDto>>(
    query.queryKey,
    (oldData) =>
      oldData && {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          conversations: page.conversations.filter(
            ({ id }) => id !== conversation.id
          ),
        })),
      }
  );
};

export const updateConversationInCache = ({
  query,
  queryClient,
  conversation,
}: {
  query: Query;
  queryClient: QueryClient;
  conversation: ConversationOutputDto;
}) => {
  queryClient.setQueryData<InfiniteData<GetConversationsOutputDto>>(
    query.queryKey,
    (oldData) =>
      oldData && {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          conversations: page.conversations.map(
            (cachedConversation: ConversationOutputDto) =>
              cachedConversation.id === conversation.id
                ? conversation
                : cachedConversation
          ),
        })),
      }
  );
};

const isConversationMatchingFilters = ({
  conversation,
  conversationsFilter,
  agentId,
}: {
  conversation: ConversationOutputDto;
  conversationsFilter: ConversationsFilter;
  agentId: string;
}) => {
  switch (conversationsFilter.assignee) {
    case ConversationsAssigneeFilter.ALL:
      return conversationsFilter.status === conversation.status;
    case ConversationsAssigneeFilter.MY:
      return (
        conversation.assigneeId === agentId &&
        conversationsFilter.status === conversation.status
      );
    case ConversationsAssigneeFilter.UNASSIGNED:
      return (
        conversation.assigneeId === null &&
        conversationsFilter.status === conversation.status
      );
    default:
      return false;
  }
};

export const mapAndSortConversationsPages = (
  pages: GetConversationsOutputDto[]
) =>
  pages
    .map((page) => page.conversations)
    .flat()
    .sort((a, b) => {
      if (a.sequenceId < b.sequenceId) return 1;

      if (a.sequenceId > b.sequenceId) return -1;

      return 0;
    });
