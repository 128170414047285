import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M19.3747 10C19.8146 10.8986 20 11.6745 20 12C20 13 18.25 18.25 12.75 18.25C12.0186 18.25 11.3535 18.1572 10.75 17.9938M7.75 16.2686C6.11209 14.6693 5.5 12.5914 5.5 12C5.5 11 7.25 5.75 12.75 5.75C14.5447 5.75 15.9401 6.30902 17.0058 7.09698"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 4.75L5.5 19.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.159 13.591C10.2803 12.7123 10.2803 11.2877 11.159 10.409C12.0377 9.53033 13.4623 9.53033 14.341 10.409"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
