import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowRightGradient = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
    ref={ref}
    {...props}
  >
    <path
      stroke="url(#arrow-right-gradient_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.958 6.125 4.584 4.375-4.584 4.375"
    />
    <path
      stroke="url(#arrow-right-gradient_svg__b)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.333 10.5H4.458"
    />
    <defs>
      <linearGradient
        id="arrow-right-gradient_svg__a"
        x1={16.542}
        x2={11.704}
        y1={6.125}
        y2={6.268}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E07B" />
        <stop offset={0.505} stopColor="#FF9473" />
        <stop offset={1} stopColor="#6366F1" />
      </linearGradient>
      <linearGradient
        id="arrow-right-gradient_svg__b"
        x1={16.333}
        x2={7.68}
        y1={10.5}
        y2={16.304}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9E07B" />
        <stop offset={0.505} stopColor="#FF9473" />
        <stop offset={1} stopColor="#6366F1" />
      </linearGradient>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgArrowRightGradient);
export default ForwardRef;
