import * as Sentry from '@sentry/react';
import { getAccessTokenPayload } from '@/services/AccessTokenService';
import { IS_SENTRY_ENABLED, SENTRY_DSN, STAGE } from '@/constants';

export const initSentry = async () => {
  const { sub, firstName, lastName, organizationId } =
    await getAccessTokenPayload();

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    enabled: IS_SENTRY_ENABLED,
    environment: STAGE,
    initialScope: {
      user: {
        id: sub || undefined,
        username:
          firstName && lastName ? `${firstName} ${lastName}` : undefined,
      },
      tags: { organizationId },
    },
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const setSentryUser = async () => {
  const { sub, firstName, lastName, organizationId } =
    await getAccessTokenPayload();

  Sentry.setUser({
    id: sub || undefined,
    username: firstName && lastName ? `${firstName} ${lastName}` : undefined,
  });

  Sentry.setTag('organizationId', organizationId);
};
