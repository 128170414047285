import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initSentry } from '@/plugins/sentry';
import { initI18n } from '@/locales/config';
import { IS_PROD } from '@/constants';
import { initHotjar } from '@/plugins/hotjar';
import { initIntercom } from '@/plugins/intercom';

import App from './App';
import './index.css';

initSentry();
initI18n();
initHotjar();
initIntercom();

if (!IS_PROD) {
  // remove all icon links
  const iconLinks = document.querySelectorAll('link[rel~="icon"]');
  iconLinks?.forEach((l) => l.remove());

  // create dev icon link
  const devLink = document.createElement('link');
  devLink.rel = 'icon';
  devLink.href = '/favicon-dev.svg';

  document.head.appendChild(devLink);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
