import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgArrowLeft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.25 6.75 4.75 12l5.5 5.25M19.25 12H5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowLeft);
export default ForwardRef;
