import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgRouteJourneys = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 8.5C7.89788 8.5 9.07245 7.54392 9.40549 6.25H16.5C17.7426 6.25 18.75 7.25736 18.75 8.5C18.75 9.74264 17.7426 10.75 16.5 10.75H7.75C5.54086 10.75 3.75 12.5409 3.75 14.75C3.75 16.9591 5.54086 18.75 7.75 18.75H10.76L9.9955 19.445C9.689 19.7237 9.66641 20.198 9.94504 20.5045C10.2237 20.811 10.698 20.8336 11.0045 20.555L13.2045 18.555C13.3609 18.4128 13.45 18.2113 13.45 18C13.45 17.7887 13.3609 17.5872 13.2045 17.445L11.0045 15.445C10.698 15.1664 10.2237 15.189 9.94504 15.4955C9.66641 15.802 9.689 16.2763 9.9955 16.555L10.76 17.25H7.75C6.36929 17.25 5.25 16.1307 5.25 14.75C5.25 13.3693 6.36929 12.25 7.75 12.25H16.5C18.5711 12.25 20.25 10.5711 20.25 8.5C20.25 6.42893 18.5711 4.75 16.5 4.75H9.40549C9.07245 3.45608 7.89788 2.5 6.5 2.5C4.84315 2.5 3.5 3.84315 3.5 5.5C3.5 7.15685 4.84315 8.5 6.5 8.5ZM6.5 7C7.32843 7 8 6.32843 8 5.5C8 4.67157 7.32843 4 6.5 4C5.67157 4 5 4.67157 5 5.5C5 6.32843 5.67157 7 6.5 7ZM17.5 20.5C18.8807 20.5 20 19.3807 20 18C20 16.6193 18.8807 15.5 17.5 15.5C16.1193 15.5 15 16.6193 15 18C15 19.3807 16.1193 20.5 17.5 20.5Z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgRouteJourneys);
export default ForwardRef;
