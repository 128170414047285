import { mergeClassNames } from '@/utils/classNames';
import { useOrganization } from '@/features/organization/queries';

export const OrganizationPicture = ({ className }: { className?: string }) => {
  const { organization } = useOrganization();

  return (
    <div
      className={mergeClassNames(
        'flex items-center justify-center bg-neutral-100',
        className
      )}
    >
      {organization?.waData?.profile.pictureUrl ? (
        <img
          className="h-full w-full rounded-[inherit] object-cover"
          src={organization.waData.profile.pictureUrl}
          loading="lazy"
          alt={organization?.name}
        />
      ) : (
        <span className="font-heading">{organization?.name.charAt(0)}</span>
      )}
    </div>
  );
};
