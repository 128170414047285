import { SocketProvider } from '@/contexts/Socket';
import { DynamicDocumentTitle } from '@/features/dynamicDocumentTitle/DynamicDocumentTitle';
import { ConversationEventsListener } from '@/hooks/conversations';
import { useInitLoggedUserIntercom } from '@/plugins/intercom';
import { setSentryUser } from '@/plugins/sentry';
import { IAccessTokenPayload } from '@/types/access-token-payload.interfaces';
import Session, { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { useEffect, useState } from 'react';
import { getAccessToken } from '@/services/AccessTokenService';
import { Outlet, useNavigate } from 'react-router-dom';
import { useOrganization } from '@/features/organization/queries';
import { IOrganizationBillingSubscriptionStatus } from 'models';

export const ConfigLayout = () => {
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const [authToken, setAuthToken] = useState<Nullable<string>>(null);
  const sessionContext = Session.useSessionContext();
  setSentryUser();
  useInitLoggedUserIntercom();

  useEffect(() => {
    getAccessToken().then((token) => {
      setAuthToken(token);
    });
  }, [sessionContext]);

  useEffect(() => {
    if (localStorage.getItem('showOnboarding') === 'true') {
      navigate('/onboarding');
    }

    if (!organization) return;

    if (
      [
        IOrganizationBillingSubscriptionStatus.CANCELED,
        IOrganizationBillingSubscriptionStatus.WAITING_FOR_PAYMENT,
      ].includes(organization.billingData.subscriptionStatus)
    ) {
      navigate('/suspended');
    }
  }, [organization, navigate]);

  if (sessionContext.loading) return null;

  const tokenExpirationTime = (
    sessionContext.accessTokenPayload as IAccessTokenPayload
  ).exp;

  return (
    <SessionAuth>
      <SocketProvider
        authToken={authToken}
        tokenExpirationTime={tokenExpirationTime}
      >
        <ConversationEventsListener />
        <DynamicDocumentTitle />
        <Outlet />
      </SocketProvider>
    </SessionAuth>
  );
};
