import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgMinusCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M5 12C5 7.99594 8.24594 4.75 12.25 4.75C16.2541 4.75 19.5 7.99594 19.5 12C19.5 16.0041 16.2541 19.25 12.25 19.25C8.24594 19.25 5 16.0041 5 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 12L9 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMinusCircle);
export default ForwardRef;
