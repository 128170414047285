import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgDoubleChatBubble = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.3263 12.0918C10.6555 12.0918 12.6942 10.8297 12.6942 8.02507C12.6942 5.22045 10.6555 3.95837 8.3263 3.95837C5.99715 3.95837 3.95837 5.22045 3.95837 8.02507C3.95837 9.05519 4.23342 9.87722 4.69687 10.505C4.86499 10.7328 4.82957 11.2636 4.69521 11.5128C4.37368 12.1091 4.98119 12.7625 5.64152 12.6111C6.13579 12.4978 6.65424 12.3446 7.10795 12.1389C7.27407 12.0636 7.45748 12.0332 7.63871 12.0537C7.86385 12.0792 8.09363 12.0918 8.3263 12.0918Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1893 8.75C15.7127 9.39269 16.0274 10.2565 16.0274 11.3584C16.0274 12.3885 15.7524 13.2105 15.2889 13.8383C15.1208 14.0661 15.1562 14.5969 15.2906 14.8461C15.6121 15.4424 15.0046 16.0958 14.3443 15.9444C13.85 15.8311 13.3315 15.6778 12.8778 15.4722C12.7117 15.3969 12.5283 15.3665 12.3471 15.387C12.1219 15.4125 11.8921 15.4251 11.6595 15.4251C10.5797 15.4251 9.56226 15.1538 8.78149 14.5833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgDoubleChatBubble);
export default ForwardRef;
