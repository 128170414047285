import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M5.25 7.75C5.25 6.09315 6.59315 4.75 8.25 4.75H16.75C18.4069 4.75 19.75 6.09315 19.75 7.75V16.25C19.75 17.9069 18.4069 19.25 16.75 19.25H8.25C6.59315 19.25 5.25 17.9069 5.25 16.25V7.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 8C17 8.27614 16.7761 8.5 16.5 8.5C16.2239 8.5 16 8.27614 16 8C16 7.72386 16.2239 7.5 16.5 7.5C16.7761 7.5 17 7.72386 17 8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 13C15.75 14.7949 14.2949 16.25 12.5 16.25C10.7051 16.25 9.25 14.7949 9.25 13C9.25 11.2051 10.7051 9.75 12.5 9.75C14.2949 9.75 15.75 11.2051 15.75 13Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
