import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgKeys = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M10.75 13.0632C12.4872 12.5294 13.75 10.9122 13.75 9C13.75 6.65279 11.8472 4.75 9.5 4.75C7.15279 4.75 5.25 6.65279 5.25 9C5.25 10.9122 6.51283 12.5294 8.25 13.0632V18C8.25 18.6904 8.80964 19.25 9.5 19.25C10.1904 19.25 10.75 18.6904 10.75 18V13.0632Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12L17.8452 15.0736C18.4523 15.436 19.2285 15.2209 19.579 14.5932C19.9295 13.9655 19.7215 13.1629 19.1144 12.8005L14 9.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 8V8.01"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgKeys);
export default ForwardRef;
