import { Tooltip } from '@/components/ui/tooltip';
import { classNames } from '@/utils/classNames';
import { formatDistanceToNowStrict, format, isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const TrialBanner = ({ trialEndAt }: { trialEndAt: Date }) => {
  const { t } = useTranslation(['pricing']);
  const isOverdue = isBefore(trialEndAt, new Date());

  const getBannerText = () => {
    const formattedDate = formatDistanceToNowStrict(trialEndAt, {
      addSuffix: true,
    });

    if (isOverdue) {
      return t('pricing:trialBanner.trialEnded', {
        formattedDate,
      });
    }

    return t('pricing:trialBanner.trialEnds', {
      formattedDate,
    });
  };

  const getBannerColor = () => {
    if (isOverdue) {
      return 'bg-red-600 text-white';
    }

    return `bg-amber-600 text-white`;
  };

  return (
    <Tooltip content={format(trialEndAt, 'dd/MM/yyyy')}>
      <div
        className={classNames(
          'rounded-lg border border-black/10 px-2 py-1.5 text-[11px] font-medium uppercase leading-none',
          getBannerColor()
        )}
      >
        {getBannerText()}
      </div>
    </Tooltip>
  );
};
