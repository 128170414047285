import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgTemplateItem = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <rect width={24} height={24} rx={8} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M7.167 7.833H13.5M7.167 16.167H13.5M7.167 12h9.667"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgTemplateItem);
export default ForwardRef;
