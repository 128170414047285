import { RouteObject } from 'react-router-dom';

export const router = {
  path: 'campaigns',
  children: [
    {
      index: true,
      lazy: () => import('./Campaigns.page'),
    },
    {
      path: ':campaignId',
      lazy: () => import('./pages/show/CampaignShow.layout'),
      children: [
        {
          index: true,
          lazy: () => import('./pages/show/CampaignShow.page'),
        },
        {
          path: 'performance',
          lazy: () => import('./pages/show/CampaignShowPerformance.page'),
        },
        {
          path: 'responses',
          lazy: () => import('./pages/show/CampaignShowResponses.page'),
        },
      ],
    },
    {
      path: ':campaignId/update',
      lazy: () => import('./pages/update/CampaignUpdate.page'),
    },
  ],
} satisfies RouteObject;
