import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgWhatsapp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <g filter="url(#whatsapp_svg__a)">
      <path
        fill="#B3B3B3"
        d="m7.597 18.92.301.177a8.222 8.222 0 0 0 4.191 1.148h.003c4.54 0 8.233-3.693 8.235-8.232 0-2.2-.855-4.268-2.41-5.824a8.182 8.182 0 0 0-5.821-2.414c-4.543 0-8.237 3.693-8.238 8.231a8.211 8.211 0 0 0 1.259 4.381l.196.312-.833 3.037 3.117-.817Zm-5.495 3.17 1.405-5.133a9.887 9.887 0 0 1-1.322-4.951c.002-5.461 4.446-9.904 9.907-9.904a9.847 9.847 0 0 1 7.009 2.905A9.843 9.843 0 0 1 22 12.014c-.002 5.46-4.447 9.904-9.908 9.904h-.004a9.903 9.903 0 0 1-4.734-1.206L2.102 22.09Z"
      />
    </g>
    <path
      fill="#fff"
      d="m2 21.988 1.406-5.133a9.886 9.886 0 0 1-1.323-4.951C2.086 6.443 6.53 2 11.991 2a9.846 9.846 0 0 1 7.008 2.905 9.842 9.842 0 0 1 2.9 7.007c-.003 5.46-4.448 9.904-9.908 9.904h-.005a9.903 9.903 0 0 1-4.734-1.206L2 21.988Z"
    />
    <path
      fill="url(#whatsapp_svg__b)"
      d="M11.994 3.673c-4.542 0-8.236 3.693-8.238 8.232a8.211 8.211 0 0 0 1.259 4.38l.196.312-.832 3.037 3.116-.817.301.179a8.224 8.224 0 0 0 4.192 1.147h.003c4.538 0 8.233-3.693 8.234-8.232a8.181 8.181 0 0 0-2.41-5.824 8.18 8.18 0 0 0-5.821-2.414Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.515 7.763c-.186-.412-.381-.42-.558-.427l-.474-.006a.91.91 0 0 0-.66.31c-.227.247-.867.846-.867 2.064s.887 2.395 1.011 2.56c.124.166 1.713 2.745 4.229 3.737 2.091.825 2.517.66 2.97.62.455-.042 1.465-.6 1.671-1.177.207-.579.207-1.074.145-1.177-.062-.104-.227-.165-.475-.29-.248-.123-1.465-.722-1.691-.805-.227-.082-.392-.123-.557.124-.166.248-.64.805-.784.97-.144.166-.289.187-.537.063-.247-.124-1.044-.385-1.99-1.229-.736-.656-1.233-1.466-1.377-1.714-.145-.247-.016-.382.108-.505.111-.11.248-.29.372-.434.124-.144.165-.247.247-.412.082-.165.041-.31-.02-.434-.062-.124-.543-1.348-.763-1.838Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="whatsapp_svg__b"
        x1={11.823}
        x2={11.906}
        y1={4.661}
        y2={18.736}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#57D163" />
        <stop offset={1} stopColor="#23B33A" />
      </linearGradient>
      <filter
        id="whatsapp_svg__a"
        width={21.898}
        height={21.988}
        x={1.102}
        y={1.102}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_9_2"
          stdDeviation={0.5}
        />
      </filter>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgWhatsapp);
export default ForwardRef;
