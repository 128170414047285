import axios from 'axios';
import { API_DOMAIN } from '@/constants';
import { getAccessTokenPayload } from './AccessTokenService';

const client = () => {
  const axiosClient = axios.create({
    baseURL: API_DOMAIN,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosClient.interceptors.request.use(
    async (config) => {
      const { organizationId } = await getAccessTokenPayload();
      if (organizationId) {
        config.headers['x-organization-id'] = organizationId;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosClient;
};

export const apiClient = client();
