import { useQuery } from '@/hooks';
import { getIntegrations } from '@/services/IntegrationsService';
import { useMemo } from 'react';
import { IIntegrationStatus } from 'models';

const queryKeys = {
  all: () => ['integrations'],
  list: () => [...queryKeys.all(), 'list'],
};

export const useIntegrationList = () => {
  const { data: integrations, isPending: integrationsLoading } = useQuery({
    queryKey: queryKeys.list(),
    queryFn: getIntegrations,
  });

  return {
    integrations,
    integrationsLoading,
  };
};

export const useActiveIntegrationList = () => {
  const { integrations, integrationsLoading: activeIntegrationsLoading } =
    useIntegrationList();

  const activeIntegrations = useMemo(
    () =>
      integrations?.filter(
        (integration) => integration.status === IIntegrationStatus.ACTIVATED
      ),
    [integrations]
  );

  return {
    activeIntegrations,
    activeIntegrationsLoading,
  };
};
