import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgClose = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 6.75 7.5 17.25M7.5 6.75 18 17.25"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
