import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCalendar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.75 8.75a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v8.5a2 2 0 0 1-2 2H6.75a2 2 0 0 1-2-2v-8.5ZM8 4.75v3.5M16 4.75v3.5M7.75 10.75h8.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
