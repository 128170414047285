import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCornerUpLeft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.471 3.138a.667.667 0 1 0-.942-.943L2.195 5.53a.667.667 0 0 0 0 .942L5.53 9.805a.667.667 0 1 0 .942-.943L4.276 6.667h2.657c1.131 0 1.94 0 2.573.052.626.051 1.022.149 1.34.311.628.32 1.138.83 1.457 1.457.162.318.26.714.311 1.34.052.634.053 1.442.053 2.573v.933a.667.667 0 0 0 1.333 0v-.963c0-1.095 0-1.957-.057-2.651-.058-.709-.178-1.3-.452-1.838a4.667 4.667 0 0 0-2.039-2.039c-.537-.274-1.129-.394-1.837-.452-.694-.057-1.557-.057-2.652-.057H4.276l2.195-2.195Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCornerUpLeft);
export default ForwardRef;
