import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgFailed = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.failed_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M3.167 8a4.833 4.833 0 1 1 9.666 0 4.833 4.833 0 0 1-9.666 0ZM6.5 6.5l3 3M9.5 6.5l-3 3"
      className="failed_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgFailed);
export default ForwardRef;
