import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgBolt = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="currentColor"
      strokeWidth={1.5}
      d="M9.88887 12.1112H6.33331L12.1111 4.55566V9.889H15.6666L9.88887 17.4446V12.1112Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgBolt);
export default ForwardRef;
