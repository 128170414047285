import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCloseCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.5 12A7.25 7.25 0 1 1 20 12a7.25 7.25 0 0 1-14.5 0ZM10.5 9.75l4.5 4.5M15 9.75l-4.5 4.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCloseCircle);
export default ForwardRef;
