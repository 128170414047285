import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCheckmark = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.5 12.75 2.25 2.5L17 8.75"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCheckmark);
export default ForwardRef;
