import { useDocumentTitle } from '@/hooks/contexts';
import { useUnrepliedConversationsCount } from '@/hooks/conversations';
import { useEffect } from 'react';

export const DynamicDocumentTitle = () => {
  const { setDocumentTitle } = useDocumentTitle();
  const { my, unassigned } = useUnrepliedConversationsCount();

  useEffect(() => {
    setDocumentTitle({
      notificationCount: (my ?? 0) + (unassigned ?? 0),
    });
  }, [my, unassigned, setDocumentTitle]);

  return null;
};
