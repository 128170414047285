import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgRobot = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.75 11.75a4 4 0 0 1 4-4h6.5a4 4 0 0 1 4 4v3.5a4 4 0 0 1-4 4h-6.5a4 4 0 0 1-4-4v-3.5ZM12 7.25v-2.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.25 12a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM16.25 12a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM10.75 16.25h2.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgRobot);
export default ForwardRef;
