export const settings = {
  pageTitle: 'Settings',
  organizationSettingsSubtitle: '{{organizationName}} settings',

  whatsAppLinks: {
    title:
      'Hey {{firstName}}, find here useful links to navigate your WhatsApp & Meta accounts',
    subtitle: 'WhatsApp & Meta Settings',
    businessAccount: {
      title: 'Meta Business Account',
      description:
        'Access your Meta Business Suite Account to help with general settings & access rights',
    },
    whatsAppProfile: {
      title: 'WhatsApp profile',
      description:
        'Improve what your customers see when they converse with your Brand on WhatsApp',
    },
    createEntryPoints: {
      title: 'Create entry points',
      description:
        'Create WhatsApp entry points to your website for seamless interactions',
    },
    conversationAnalytics: {
      title: 'Conversation analytics',
      description:
        'Improve what your customers see when they converse with your Brand on WhatsApp',
    },
    whatsAppBilling: {
      title: 'WhatsApp billing',
      description: 'Manage your WhatsApp billing & payment settings',
    },
  },

  notifications: {
    title: 'Personal settings',
    subtitle: 'Notifications',
    description: 'I would like to be notified by e-mail when...',

    byEmail: 'By email',

    newConversationUnreplied: {
      title: 'A new conversation is unreplied',
      description: 'A new customer didn’t get a reply after 2 hours',
    },
    userConversationUnreplied: {
      title: 'One of my conversation is unreplied',
      description: 'One of my conversations didn’t get a reply after 2 hours',
    },
    conversationAssignedToUser: {
      title: 'A conversation is assigned to me',
      description: 'A teammate assigns you to a conversation',
    },

    successNotification: {
      title: 'Settings saved',
      enabled: 'Notifications have been enabled',
      disabled: 'Notifications have been disabled',
    },
  },

  teammates: {
    title: 'Teammates',
    createCta: 'Invite teammate',
    emailHeader: 'Name',
  },

  openingHours: {
    title: 'Organization settings',
    subtitle: 'Opening hours & away message',
    callToAction: 'Save modifications',

    openingHours: {
      title: 'Opening hours',
      subtitle: 'Define the days and hours when you are open',

      errors: {
        startRequired: 'Start time must be filled',
        endRequired: 'End time must be filled',
        endAfterStart: 'End time must be after start time',
      },
    },

    awayMessage: {
      title: 'Away message',
      subtitle:
        'Write the message your customers will receive when they write to you outside these opening hours',
      textPlaceholder:
        'Thanks for reaching to us. We will get back to you soon.',
      errors: {
        awayMessageRequired: 'Away message is required',
      },
    },
  },

  integrations: {
    title: 'Integrations',
    description:
      'Connect Kiosk with your favorite tools to power-up your customer journeys',
    HUBSPOT: {
      title: 'HubSpot',
      description:
        'Trigger WhatsApp scenarios from Kiosk based on HubSpot events !',
      installInProgress: "We're busy connecting your HubSpot account ...",
    },
    KLAVIYO: {
      title: 'Klaviyo',
      description:
        'Trigger WhatsApp scenarios from Kiosk based on Klaviyo events !',
      installInProgress: "We're busy connecting your Klaviyo account ...",
    },
    GORGIAS: {
      title: 'Gorgias',
      description: 'Concierge opens a ticket in your preferred support tool !',
      installInProgress: "We're busy connecting your Gorgias account ...",
    },
    SHOPIFY: {
      title: 'Shopify',
      description:
        'Trigger WhatsApp scenarios from Kiosk based on Shopify events !',
      installInProgress: "We're busy connecting your Shopify account ...",
    },
    zapier: {
      title: 'Zapier',
      description:
        'Trigger WhatsApp scenarios from Kiosk based on Zapier events !',
      cta: 'Create a zap',
    },
    nextIntegration: {
      title: 'Which integration should be next?',
      description: 'Get in touch with our team directly on WhatsApp !',
      cta: 'Tell us on WhatsApp',
    },
    contact: {
      help: 'I need help with integrations',
      interested: 'Hey Kiosk ! I want to talk Integrations 😊',
    },
    connectWaAccount: {
      title: 'Connect your WhatsApp account',
      description:
        'Connect your WhatsApp account to Kiosk to start using integrations',
    },
    connectWaAccountIssues: {
      title: 'There are some issues',
      description:
        'Connect your WhatsApp account to Kiosk to start using integrations',
      finishCta: 'Finish setup',
      phoneNumberIssue: 'Your phone number needs to be linked to Kiosk',
      paymentIssue: 'There are some payment issues in WhatsApp',
    },
  },

  apiKeys: {
    title: 'API keys',
    subtitle: 'Use API keys to send data to Zapier or use Kiosk API',
    nameHeader: 'Name',
    tokenHeader: 'Token',
    createdAtHeader: 'Creation date',
    documentation: 'Check our technical doc',
    createTitle: 'New API key',
    nameLabel: 'Name',
    namePlaceholder: 'Zapier',
  },

  twoStepVerificationModal: {
    title: 'Two-step verification',
    firstDescription:
      'Any attempt to register your phone number on WhatsApp must be accompanied by the six-digit PIN that you created.',
    secondDescription:
      'If you forget or misplace your PIN, you can turn off two-step verification or change your PIN.',
    updateCta: 'Update PIN',
    twoStepVerificationPinLabel: 'Two step verification pin',
    twoStepVerificationError: 'Please enter a valid 6 digit code',
  },

  embeddedSignup: {
    signupError: 'Failed to link WhatsApp business account. Try again.',
  },

  whatsAppProfile: {
    title: 'Polish your profile',
    description: 'It’s important to have a nice client-facing profile.',
  },

  agentCreatedSuccessNotification: {
    title: 'User invited',
    content: '{{firstName}} has been invited to the team',
  },

  createAgentModal: {
    title: 'Invite teammate',
    description:
      'Get the most out of Kiosk & ensure better conversation answers by inviting teammates',
    emailLabel: 'E-mail',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    submitContent: 'Invite teammate',
  },

  pricing: {
    title: 'Plans & Billing',
    description: 'Manage your plan, invoices and payment settings',
    paymentMethod: 'Payment method & invoices',
    whatsAppBilling: 'WhatsApp Billing',
    billingEmailTooltip:
      'Billing email linked to Kiosk. If you need to change it, please contact us.',
  },

  tracking: {
    title: 'Tracking',
    description: 'Manage your revenue attribution window and UTM tracking.',
    attributionTitle: 'Attribution rule settings',
    attributionDescription:
      'Kiosk tracks whether a contact <highlight>viewed</highlight> 👀 or <highlight>clicked</highlight> 👆 a WhatsApp marketing message before making a purchase on your store. We use this information to attribute revenue and sales to WhatsApp. We let you define which attribution window feels best for your business.',
    defineViewWindow: '👀 View window',
    defineClickWindow: '👆 Click window',
    days_one: 'Day',
    days_other: 'Days',
    hours_one: 'Hour',
    hours_other: 'Hours',
  },
};
