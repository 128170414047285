import { IPricingPlanType } from 'models';

export const pricing = {
  // models
  pricingType: {
    [IPricingPlanType.STARTER]: 'Starter',
    [IPricingPlanType.GROWTH]: 'Growth',
    [IPricingPlanType.PROFESSIONAL]: 'Professional',
  },

  features: [
    'Single message campaign',
    'Single step automation',
    'Unlimited segments',
    'Reporting',
    'Live chat, e-mail support',
  ],

  pricingPlanSelector: {
    month: 'month',
    planDescription: '{{amount}} per outbound conversation + WhatsApp fees',
    seeWhatsAppPicingCta: 'See WhatsApp pricing',
    seatLimitTitle: 'Seat limit',
    seatLimit_one: '1 seat',
    seatLimit_other: '{{count}} seats',
    managePlan: 'Manage your plan',
    updatePlan: 'Update your plan',
    upgradePlan: 'Upgrade to {{plan}}',
  },

  plans: {
    [IPricingPlanType.STARTER]: {
      title: '💬 Starter',
      featuresTitle: 'Features',
      features: [
        'Single message campaign',
        'Single step automation',
        'Unlimited segments',
        'Reporting',
        'Live chat, e-mail support',
      ],
    },
    [IPricingPlanType.GROWTH]: {
      title: '🚀 Growth',
      featuresTitle: 'All starter features, plus',
      features: [
        'Multi message campaigns',
        'Multi step automations',
        'Klaviyo, Zapier, HubSpot integrations',
        'AI Agents (soon)',
        'Priority live chat, e-mail support',
      ],
    },
    [IPricingPlanType.PROFESSIONAL]: {
      title: '📣 Professional',
      featuresTitle: 'All starter & growth features, plus',
      features: [
        'API Access',
        'White gloves onboarding',
        'Dedicated Success Manager',
        'Tier 1 engineering support',
      ],
    },
  },

  suspended: {
    title: 'Choose a plan',
    description: 'Manage your plan, invoices and payment settings',
    or: 'Or',
    signOut: 'Sign out',
  },

  trialBanner: {
    trialEnded: 'Trial ended {{formattedDate}}',
    trialEnds: 'Trial ends {{formattedDate}}',
  },
};
