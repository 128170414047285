import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M11.25 14.25H12.75C15.3734 14.25 17.5 12.1234 17.5 9.5C17.5 6.87665 15.3734 4.75 12.75 4.75H9.25C6.90279 4.75 5 6.65279 5 9V10.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 9.75H11.75C9.12665 9.75 7 11.8766 7 14.5C7 17.1234 9.12665 19.25 11.75 19.25H15.25C17.5972 19.25 19.5 17.3472 19.5 15V13.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
