import { mergeClassNames } from '@/utils/classNames';

export const Skeleton = ({
  gradient = false,
  className,
}: {
  gradient?: boolean;
  className?: string;
}) => (
  <div
    role="presentation"
    className={mergeClassNames(
      'min-h-[6px] w-full max-w-full animate-pulse rounded-full',
      gradient
        ? 'bg-gradient-to-r from-neutral-200 via-neutral-200 to-neutral-200/0'
        : 'bg-neutral-200',
      className
    )}
  />
);
