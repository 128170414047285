import { QueryClient } from '@/hooks';
import { payloadToDto } from '@/utils/dto';
import { MessageOutputDto } from 'dto';

export const handleUpdatedMessageSocketEvent =
  (queryClient: QueryClient) => (payload: any) => {
    const updatedMessage = payloadToDto(MessageOutputDto, payload);

    // UPDATING ACTIVE CONVERSATION MESSAGES CACHE
    queryClient.setQueryData<MessageOutputDto[]>(
      ['messages', 'list', updatedMessage.conversationId],
      (oldData) => {
        if (!oldData) return oldData;

        return oldData.map((message) => {
          if (message.id === updatedMessage.id) {
            return updatedMessage;
          }

          return message;
        });
      }
    );
  };
