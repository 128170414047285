import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgUserCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <circle cx={12} cy={12} r={7.25} stroke="currentColor" strokeWidth={1.5} />
    <circle cx={12} cy={10} r={2.25} stroke="currentColor" strokeWidth={1.5} />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7 17.198C8.259 15.872 10 15 12 15s3.741.872 5 2.198"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgUserCircle);
export default ForwardRef;
