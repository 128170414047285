import {
  Provider,
  Root,
  Trigger,
  Portal,
  Content,
  TooltipContentProps,
} from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

export const Tooltip = ({
  children,
  content,
  tooltipOptions,
  enabled = true,
}: {
  children: ReactNode;
  content: ReactNode;
  tooltipOptions?: TooltipContentProps;
  enabled?: boolean;
}) =>
  enabled ? (
    <Provider>
      <Root delayDuration={100}>
        <Trigger asChild>{children}</Trigger>

        <Portal>
          <Content
            className="z-50 m-2 max-w-sm rounded-md bg-neutral-900 px-2 py-0.5 text-neutral-50"
            {...tooltipOptions}
          >
            {content}
          </Content>
        </Portal>
      </Root>
    </Provider>
  ) : (
    children
  );
