import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M18.9696 18.25H6.53043C5.64982 18.25 5.19914 17.1941 5.80834 16.5582L12.0279 10.0663C12.4216 9.65532 13.0784 9.65532 13.4721 10.0663L19.6917 16.5582C20.3009 17.1941 19.8502 18.25 18.9696 18.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0625 10.0312L13.0606 8.93737C14.1758 7.71508 13.3086 5.75 11.654 5.75C10.6025 5.75 9.75 6.60246 9.75 7.65403V8.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
