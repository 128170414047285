import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import * as reactRouterDom from 'react-router-dom';
import { AppLayout } from '@/features/layout/app';
import { ConfigLayout, ConfigError } from '@/features/layout/config';
import { OnboardingLayout } from '@/features/layout/onboarding';
import { AuthLayout } from '@/features/layout/auth';

import { router as authRouter } from './auth/router';
import { router as campaignsRouter } from './campaigns/router';
import { router as conciergeRouter } from './concierge/router';
import { router as contactsRouter } from './contacts/router';
import { router as dashboardRouter } from './dashboard/router';
import { router as entryPointsRouter } from './entryPoints/router';
import { router as gettingStartedRouter } from './gettingStarted/router';
import { router as inboxRouter } from './inbox/router';
import { router as onboardingRouter } from './onboarding/router';
import { router as rootRouter } from './root/router';
import { router as scenariosRouter } from './scenarios/router';
import { router as settingsRouter } from './settings/router';
import { router as suspendedRouter } from './suspended/router';

export const router = () =>
  createBrowserRouter([
    ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
      EmailPasswordPreBuiltUI,
    ]).map((route) => route.props as unknown as RouteObject),
    {
      element: <AuthLayout />,
      children: [authRouter],
    },
    {
      element: <ConfigLayout />,
      errorElement: <ConfigError />,
      children: [
        suspendedRouter,
        {
          element: <OnboardingLayout />,
          children: [onboardingRouter],
        },
        {
          element: <AppLayout />,
          children: [
            campaignsRouter,
            conciergeRouter,
            contactsRouter,
            dashboardRouter,
            entryPointsRouter,
            gettingStartedRouter,
            inboxRouter,
            rootRouter,
            scenariosRouter,
            settingsRouter,
          ],
        },
      ],
    },
  ]);
