import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgChart = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.5 6.75a2 2 0 0 1 2-2H18a2 2 0 0 1 2 2v10.5a2 2 0 0 1-2 2H7.5a2 2 0 0 1-2-2V6.75ZM9.5 15.25v-5.5M16 15.25v-5.5M12.75 15.25v-2.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgChart);
export default ForwardRef;
