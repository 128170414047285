import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 13.25 18.25 12a4.243 4.243 0 1 0-6-6L11 7.25M7.5 10.75 6.25 12a4.243 4.243 0 0 0 6 6l1.25-1.25M14.5 9.75l-4.5 4.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
