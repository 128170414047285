import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgDoubleCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.double-check_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="m2.342 8.615 1.846 2.052 5.129-5.334M13.624 5.333l-5.128 5.334-.923-1.026"
      className="double-check_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgDoubleCheck);
export default ForwardRef;
