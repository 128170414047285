import { MediaType } from '@/types/media.ts';
import { IConversationStatus, IMessageStatus } from 'models';
import { ConversationsAssigneeFilter } from '@/services/ConversationsService.ts';

export const inbox = {
  pageTitle: 'Inbox',
  inboxListItem: {
    mediaLabel: {
      [MediaType.IMAGE]: 'picture',
      [MediaType.VIDEO]: 'video',
      [MediaType.AUDIO]: 'audio',
      default: 'file',
    },
  },
  date: {
    today: 'Today',
  },

  status: {
    [IMessageStatus.NOT_SENT]: 'Not sent',
    [IMessageStatus.SENT]: 'Sent',
    [IMessageStatus.DELIVERED]: 'Delivered',
    [IMessageStatus.READ]: 'Read',
    [IMessageStatus.FAILED]: 'Failed',
    [IMessageStatus.WARNING]: 'Warning',
    [IMessageStatus.DELETED]: 'Deleted',
  },

  message: {
    flow_result: {
      title: 'WhatsApp Form result',
    },
  },
  conversations: {
    filters: {
      assignee: {
        label: {
          [ConversationsAssigneeFilter.UNASSIGNED]: 'New conversations',
          [ConversationsAssigneeFilter.MY]: 'My conversations',
          [ConversationsAssigneeFilter.ALL]: 'All conversations',
        },
      },
      status: {
        title: 'Filter view',
        label: {
          [IConversationStatus.OPEN]: 'Open',
          [IConversationStatus.CLOSED]: 'Done',
        },
        option: {
          [IConversationStatus.OPEN]: 'See open convs.',
          [IConversationStatus.CLOSED]: 'See done convs.',
        },
      },
    },
    list: {
      item: {
        tooltip: {
          closed: 'Done',
          waitingForReply: 'Don’t leave this conv. unreplied 💬',
        },
      },
    },
  },
  conversation: {
    assigneeUpdate: 'This conversation has been assigned to',
    header: {
      actions: {
        addAssignee: 'Add assignee',
        open: 'Re-open',
        close: 'Done',
        statusChangeNotificationTitle: 'Success',
        statusChangeNotificationOpenText: 'Conversation has been re-opened',
        statusChangeNotificationCloseText: 'Conversation is done',
      },
    },
    input: {
      waTemplate: {
        closedView: {
          newConversation: {
            title: 'Start the conversation',
            text: 'Use an approved template message to start the conversation',
          },
          existingConversation: {
            title: 'Re-engage conversation with "{{ whatsappName }}"',
            text: 'Use an approved template message to restart the conversation',
          },
        },
      },
    },
  },
  resourcesGallery: {
    title: 'Medias & documents',
    filters: {
      medias: 'Medias',
      documents: 'Docs.',
    },
    empty: {
      medias: {
        title: 'No media',
        description:
          'All medias shared within this conversation (images, videos, gifs) will be stored here.',
      },
      documents: {
        title: 'No document',
        description:
          'All documents shared within this conversation will be stored here.',
      },
    },
  },
  defaultState: {
    default: {
      title: 'Kiosk',
      description: 'Bringing conversational commerce to your business 🥳.',
    },
    unassignedEmpty: {
      title: 'All good, your team is doing a great job!',
      description: 'Every conversation is being treated!',
      cta: 'Check out your conversations',
    },
  },
};
