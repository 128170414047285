import { useContext } from 'react';
import { DocumentTitleContext, DocumentTitle } from '@/contexts/DocumentTitle';

const useDocumentTitleContext = () => {
  const context = useContext(DocumentTitleContext);

  if (!context) {
    throw new Error(
      'useDocumentTitleContext must be used within a DocumentTitleContextProvider'
    );
  }

  return context;
};

export const useDocumentTitle = (titleObject?: DocumentTitle) => {
  const { setDocumentTitle } = useDocumentTitleContext();

  if (titleObject) {
    setDocumentTitle(titleObject);
  }

  return { setDocumentTitle };
};
