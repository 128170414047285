import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgStack = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.stack_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M22.91 6.953 12.7 1.672a1.543 1.543 0 0 0-1.416 0L1.076 6.953a.615.615 0 0 0 0 1.094l10.209 5.281a1.543 1.543 0 0 0 1.416 0L22.91 8.047a.616.616 0 0 0 0-1.094Z"
      className="stack_svg__a"
    />
    <path
      d="m.758 12.75 10.527 5.078a1.543 1.543 0 0 0 1.416 0l10.557-5.078"
      className="stack_svg__a"
    />
    <path
      d="m.758 17.25 10.527 5.078a1.543 1.543 0 0 0 1.416 0l10.557-5.078"
      className="stack_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgStack);
export default ForwardRef;
