export const features = {
  fileArea: {
    uploadText: 'Upload your media here',
    removeMedia: 'Remove media',
  },
  conversationWaTemplateInput: {
    fileAreaSupportText: 'PNG, JPG up to 10MB',
  },
  templateMessageTextWithVariables: {
    freeText: 'Free text',
    detach: 'Change variable',
    freeTextPlaceholder: 'Custom text...',
  },
  waTemplateSelectInput: {
    createNewTemplate: 'Create new engagement message',
  },
  waTemplatePreview: {
    emptyState: 'Select a template message to preview it',
  },
  chatMessage: {
    chatMessageCampaignTriggererAvatar: 'Campaign "{{ campaignName }}"',
    chatMessageScenarioTriggererAvatar: 'Scenario "{{ scenarioName }}"',
    chatMessageEntryPointTriggererAvatar: 'Entry point "{{ entryPointName }}"',
    chatMessagePublicApiTriggererAvatar: 'API',
    chatMessageZapierTriggererAvatar: 'Zapier',
    chatMessageAiAssistantTriggererAvatar:
      'Ai Concierge with mission "{{missionType}}"',
    chatMessageAwayMessageTriggererAvatar: 'Away message',
    chatMessageOptOutConfirmationTriggererAvatar: 'Opt-out confirmation',
  },
};
