import Session from 'supertokens-auth-react/recipe/session';
import { useMemo } from 'react';
import { LoadedSessionContext } from 'supertokens-auth-react/lib/build/recipe/session/types';
import { useAgent } from './useAgent';

export const useCurrentAgent = () => {
  const sessionContext = Session.useSessionContext();
  const agentId = useMemo(
    () => (sessionContext as LoadedSessionContext)?.userId || null,
    [sessionContext]
  );

  const { agent: currentAgent, agentLoading: currentAgentLoading } =
    useAgent(agentId);

  return {
    currentAgent,
    currentAgentLoading,
  };
};
