import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgShare = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.25 4.75h-2.5a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-2.5M19.25 9.25v-4.5h-4.5M19 5l-7.25 7.25"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgShare);
export default ForwardRef;
