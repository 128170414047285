import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgUserCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          '.user-check_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      d="M15.25 8a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0ZM12.25 19.25h-5.3c-1.18 0-2.06-1.04-1.46-2.055C6.363 15.723 8.24 14 12.25 14M14.75 17.75l1.25 1.5 3.25-4.5"
      className="user-check_svg__a"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgUserCheck);
export default ForwardRef;
