import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgTextCapitalize = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.75 17.25 8 6.75l3.25 10.5M6 14.25h4M19.25 14.5a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0ZM19.25 11.75v5.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgTextCapitalize);
export default ForwardRef;
