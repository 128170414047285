import { RouteObject } from 'react-router-dom';

export const router = {
  path: 'entry-points',
  children: [
    {
      index: true,
      lazy: () => import('./EntryPoints.page'),
    },
    {
      path: 'create',
      lazy: () => import('./pages/CreateEntryPoint.page'),
    },
    {
      path: ':entryPointId/configure',
      lazy: () => import('./pages/ConfigureEntryPoint.page'),
    },
  ],
} satisfies RouteObject;
