import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCreditCard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.5 7.75a2 2 0 0 1 2-2H18a2 2 0 0 1 2 2v8.5a2 2 0 0 1-2 2H7.5a2 2 0 0 1-2-2v-8.5ZM5.75 10.25h14M8.5 14.25H11M16.5 14.25h.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCreditCard);
export default ForwardRef;
