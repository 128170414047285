import { ReactNode } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { mergeClassNames } from '@/utils/classNames';

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  afterLeave?: () => void;
};

export const Modal = ({
  children,
  open,
  onClose,
  afterLeave,
  className,
}: ModalProps & { className?: string; children: ReactNode }) => (
  <Transition show={open} afterLeave={afterLeave}>
    <Dialog as="div" onClose={onClose}>
      <TransitionChild
        as="div"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        leave="ease-in duration-200"
        leaveTo="opacity-0"
        className="fixed inset-0 z-40 bg-white bg-opacity-10 backdrop-blur transition-opacity"
      />

      <div className="fixed inset-0 z-40 flex items-start justify-center overflow-y-auto">
        <TransitionChild
          as={DialogPanel}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:scale-95"
          leave="ease-in duration-200"
          leaveTo="opacity-0 translate-y-4 sm:scale-95"
          className={mergeClassNames(
            'relative max-w-md flex-1 rounded-xl border border-neutral-200 bg-white p-4 shadow-xl transition sm:my-14 sm:p-8',
            className
          )}
        >
          {children}
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);
