import { MediaOutputDto } from 'dto';

export enum MediaType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
}

export type DownloadableMedia = MediaOutputDto & {
  mediaType: MediaType;
};
