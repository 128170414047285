export const colours = `
    [data-supertokens~=container] {
        --palette-background: 255, 255, 255;
        --palette-inputBorder: 235, 235, 235;
        --palette-primary: 248, 240, 252;
        --palette-primaryBorder: 248, 240, 252;
        --palette-textTitle: 15, 17, 20;
        --palette-textLabel: 102, 101, 101;
        --palette-textPrimary: 102, 101, 101;
        --palette-buttonText: 173, 62, 201;
        --palette-error: 214, 51, 108;
        --palette-errorBackground: 255, 227, 227, 0.35;
        --palette-textInput: 102, 101, 101;
        --palette-textLink: 173, 62, 201;
        --palette-superTokensBrandingBackground: 255, 255, 255;
        --palette-superTokensBrandingText: 255, 255, 255;
    }`;

export const disableSignUpButton = `
    [data-supertokens~=headerSubtitle] {
        display: none;
    }`;
