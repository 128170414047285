import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import { notificationsContext } from '@/contexts/Notifications/Notifications.context.ts';
import { CheckmarkIcon, CloseIcon, WarningIcon } from '@ui/icons';

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type NotificationProps = {
  id: string;
  type: NotificationType;
  title: string;
  content: React.ReactNode;
  duration: number;
};

export const Notification = ({
  id,
  type,
  content,
  title,
  duration,
}: NotificationProps) => {
  const [show, setShow] = useState(false);
  const { removeNotification } = useContext(notificationsContext);

  const icon = useMemo(() => {
    switch (type) {
      case NotificationType.SUCCESS:
        return (
          <CheckmarkIcon className="h-5 w-5 rounded-md border-2 border-neutral-900 bg-lime-100 text-neutral-900 outline outline-2 outline-green-500" />
        );
      case NotificationType.ERROR:
        return (
          <WarningIcon className="h-5 w-5 rounded-md border-2 border-neutral-900 bg-rose-100 text-neutral-900 outline outline-2 outline-rose-500" />
        );
      default:
        return null;
    }
  }, [type]);

  useEffect(() => {
    setShow(true);

    if (Number.isFinite(duration)) {
      setTimeout(() => {
        setShow(false);
      }, duration);
    }
  }, [duration, id]);

  return (
    <Transition
      key={id}
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-5 opacity-0 sm:translate-y-0 sm:translate-x-5"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
      leaveTo="opacity-0 translate-y-5 sm:translate-y-0 sm:translate-x-full"
      afterLeave={() => {
        removeNotification(id);
      }}
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-xl border border-black bg-neutral-900 shadow-lg">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{icon}</div>
            <div className="-mt-0.5 ml-3 w-0 flex-1">
              <p className="heading-base text-white">{title}</p>
              <div className="text-sm font-normal text-neutral-400">
                {content}
              </div>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">close</span>
                <CloseIcon className="h-4 w-4 text-neutral-400" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
