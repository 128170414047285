import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgStackOverflow = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.75 17v1.25a1 1 0 0 0 1 1h12.5a1 1 0 0 0 1-1V17M7.75 16.25h8.5M8.399 12.723l8.441.991M9.506 9.149l8.227 2.14M11.414 5.755l7.836 3.294"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgStackOverflow);
export default ForwardRef;
