import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgRingLoader = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <style>
      {
        '@keyframes spinner_zKoa{to{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke:currentColor;stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke:currentColor;stroke-dasharray:42 150;stroke-dashoffset:-16}95%,to{stroke:currentColor;stroke-dasharray:42 150;stroke-dashoffset:-59}}'
      }
    </style>
    <g
      style={{
        stroke: 'currentColor',
        transformOrigin: 'center',
        animation: 'spinner_zKoa 2s linear infinite',
      }}
    >
      <circle
        cx={12}
        cy={12}
        r={9.5}
        fill="none"
        strokeWidth={3}
        style={{
          stroke: 'currentColor',
          opacity: 0.25,
        }}
      />

      <circle
        cx={12}
        cy={12}
        r={9.5}
        fill="none"
        strokeWidth={3}
        style={{
          stroke: 'currentColor',
          strokeLinecap: 'round',
          animation: 'spinner_YpZS 1.5s ease-in-out infinite',
        }}
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgRingLoader);
export default ForwardRef;
