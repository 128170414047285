import { IAiMissionType } from 'models';

export const ai = {
  // Model
  missionType: {
    [IAiMissionType.GENERIC_HELPER_ASSISTANT]: 'General concierge',
    [IAiMissionType.ESCALATION]: 'Hand raiser',
    [IAiMissionType.OPT_IN_OUT_ASSISTANT]: 'Opt-in/out guardian',
  },

  landing: {
    title: 'Discover our Inbox AI Concierge',
    description:
      'AI Concierge offers tailored missions for personalized support and seamless customer interactions.',
    cta: 'Configure your AI Concierge',
    alwaysOnTitle: 'Always on, always responsive',
    alwaysOnCheck: 'Available 24/7',
    alwaysOnReply: 'Reactive, reply in 10 seconds',
    alwaysOnLanguage: 'In any language',
    alwaysOnEscalate: 'Escalate to agents if needed',
    featureGeneralTitle: 'General Concierge',
    featureGeneralDescription:
      'For broad assistance, our General Concierge is your go-to. Ready to tackle a wide range of questions with expertise and efficiency.',
    featureSpecializedTitle: 'Specialized Missions',
    featureSpecializedDescription:
      'Boost your concierge with Missions tailored for everything from opt-ins, help, to lead qualification and more.',
    featureTailoredTitle: 'Tailored to your business',
    featureTailoredDescription:
      'Leveraging the power of your business context (Documents, URLs), we offer personalized concierge tailored to your business specificities.',
  },

  onboardingStepper: {
    introduction: 'Introduction',
    personalization: 'Personalization',
    recap: 'Recap',
  },

  onboarding: {
    introductionTitle: 'Welcome to the AI Concierge',
    introductionTagline:
      'Hi {{firstName}}! I’m your Inbox AI Concierge. Let’s begin by tailoring my services together to fit your needs perfectly.',
    introductionTaglineFallback: 'there',
    contentTitle: 'Personalize the experience',
    contentTagline:
      'I’m eager to learn everything necessary to assist your customers most effectively. Could you share with me key informations and documents about your business please ?',
    escalateTitle: 'Personalize the experience',
    escalateTagline:
      'Sometimes, I might not have the answer or be able to identify the appropriate mission to manage the conversation. How should I escalade in these situations ?',
    missionsTitle: 'Choose your first mission',
    missionsDescription:
      'This ensures important questions are tagged and escalated promptly, connecting you with a real agent for personalized support.',
    missionsTagline:
      'By the way, if I’m ever unable to provide the answer needed, I’ll activate my 🙋 <GradientText>Hand Raiser</GradientText> mission.',
    missionsUnlockedTitle: 'First mission unlocked',
    processingTitle:
      'We’re mixing a dash of your preferences with a sprinkle of AI magic to create a truly tailored voice for your Inbox Concierge. You’ll be accessing soon a library of missions.',
    processingTitleFinished:
      'I’m all set up, thank you. Let’s head over to concierge’s home to explore your library of missions.',
    processingCta: 'Enable concierge',
  },

  concierge: {
    title: 'Hey, welcome to your tailored AI Concierge',
    activeTitle: 'Active missions',
    availableTitle: 'What’s coming next ?',
    enableCta: 'Activate concierge',
    disableCta: 'Deactivate concierge',
  },

  mission: {
    backToConcierge: 'Back to conciergerie',
    settingsCta: 'Settings',
    configureCta: 'Configure',
    enableCta: 'Activate this mission',
    disableCta: 'Deactivate mission',
    comingSoon: 'Coming soon',
    isActive: 'Active',
  },

  genericMissionForm: {
    linksTitle: 'Synchronize external content',
    linksDescription:
      'Add content from your website, knowledge base, F.A.Q, blog...',
    addSourceCta: 'Add source',
    updateSourceCta: 'Update source',
    processingAlert:
      'Processing can take from a couple of minutes to a few hours depending on the volume.',
    documentsTitle:
      'Add any documents that might be useful to understand your business',
    documentsDescription: 'Document examples...',
    aiDataEmptyError: 'At least one link or document is required',
    aiDataDuplicateUrlError: 'This URL has already been added',
    saveCta: 'Save modifications',
  },

  editUrlElement: {
    title: 'Add new content source',
    simpleUrlsTitle: '🔗 Use simple URLs',
    simpleUrlsDescription:
      'We only imports pages that starts with the URL you enter, so it’s best to use the most basic URL system possible. For example, don’t use <Bold>website.com/en-us</Bold>, you should use <Bold>website.com</Bold> instead.',
    mediaTitle:
      '📸 We are currently unable to interpret images/multimedia contents',
    urlLabel: 'Enter URL',
    urlPlaceholder: 'https://www.example.com',
    subPagesLabel: 'Sync subpages',
    subPagesDescription:
      'Please note we can only sync three levels with a maximum limit of 100 pages (or sub-pages). Here is a small example.',
    subPagesExampleFirstLevel: { title: 'Level 1', url: 'website.com' },
    subPagesExampleSecondLevel: { title: 'Level 2', url: 'website.com/blog' },
    subPagesExampleThirdLevel: {
      title: 'Level 3',
      url: 'website.com/blog/our-latest-news',
    },
    subPagesFooter:
      'You’ll be able to manage (include, exclude) these pages afterward.',
  },

  aiDataUrlManager: {
    processing: 'Processing',
    urlHeader: 'Include URL in Ai Concierge',
    lastSync: 'Last sync',
    activePages_one: '1 active page',
    activePages_other: '{{count}} active pages',
    activePages_zero: 'No active pages',
    enabled: 'Active',
    disabled: 'Inactive',
    syncCta: 'Relaunch sync',
    description:
      'Discover here all the settings related to your content source. To get the latest updates, re-launch a new sync. You can also exclude any irrelevant links or turn off the source if it’s no longer needed.',
    pagesSynced: 'Total pages synced',
    pagesUsed: 'Pages used',
    pagesExcluded: 'Pages excluded',
  },

  escalationForm: {
    title: 'Choose your escalation scenario',
    description:
      'Sometimes the AI Concierge may not have the answer or might not be able to identify the appropriate mission to manage the conversation. In these cases, the concierge should escalate the issue to a human support team. Select your preferred approach.',

    support: {
      title: 'Escalate to support tool',
      description: 'Concierge opens a ticket in your preferred support tool',
      tool: 'Gorgias',
      actionPerformed: 'Action performed',
      actionDescription: 'Let’s have a look at what’s really happening',
      firstStep: 'Unability to answer / solve issue',
      secondStep: 'Concierge asks for customer email',
      thirdStep: 'Ticket creation',
      disclaimer:
        'Our Concierge answer directly in customer native language, perfect, right?',
      connectSupportTool: 'Connect your support tool',
    },

    email: {
      title: 'Escalate to e-mail support address',
      description: 'Concierge asks customers to send an e-mail to your support',
      actionPerformed: 'Action performed',
      actionDescription: 'Let’s have a look at what’s really happening',
      firstStep: 'Unability to answer / solve issue',
      secondStep: 'Concierge asks for customer email',
      thirdStep: 'Send e-mail to support',
      disclaimer:
        'Our Concierge answer directly in customer native language, perfect, right?',
      emailLabel: 'Support e-mail',
    },

    inbox: {
      title: 'Escalate to agent in Kiosk inbox',
      description:
        'Concierge opens a conversation in the Inbox and sends you a notification',
    },
  },

  missionContent: {
    [IAiMissionType.GENERIC_HELPER_ASSISTANT]: {
      title: 'General concierge',
      listDescription:
        'As the general concierge, I’m committed to providing best in class support for all customer inquiries. If a question falls outside my knowledge base or missions, I’ll acknowledge it and ensure customers feel heard and valued.',
      displayDescription:
        'As the {{organizationName}} concierge, I’m committed to providing best in class support for all customer inquiries. If a question falls outside my knowledge base or missions, I’ll acknowledge it and ensure customers feel heard and valued.',
    },
    [IAiMissionType.ESCALATION]: {
      title: 'Hand raiser',
      listDescription:
        'Tags key conversations for escalation, ensuring important interactions quickly reach real agents.',
      displayDescription:
        'Tags key conversations for escalation, ensuring important interactions quickly reach real agents.',
    },
    [IAiMissionType.OPT_IN_OUT_ASSISTANT]: {
      title: 'Opt-in/out guardian',
      listDescription:
        'Empowering customers to manage their communication preferences with ease.',
      displayDescription:
        'Empowering customers to manage their communication preferences with ease.',
    },
    productAssistant: {
      title: 'Product assistant',
      listDescription:
        'Empowering customers to manage their communication preferences with ease.',
    },
    contactUs: {
      title: 'Tell us about your dream mission',
      listDescription:
        'What does that look like for you? Share your dream AI mission with us, and let’s explore how we can turn your vision into reality.',
    },
  },
};
