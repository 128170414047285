import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      d="M7.75 7.75H19.25L17.6128 14.7081C17.4002 15.6115 16.5941 16.25 15.666 16.25H11.5395C10.632 16.25 9.83827 15.639 9.60606 14.7618L7.75 7.75ZM7.75 7.75L7 4.75H4.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 19C10.5 19.2761 10.2761 19.5 10 19.5C9.72386 19.5 9.5 19.2761 9.5 19C9.5 18.7239 9.72386 18.5 10 18.5C10.2761 18.5 10.5 18.7239 10.5 19Z"
      stroke="currentColor"
    />
    <path
      d="M17.5 19C17.5 19.2761 17.2761 19.5 17 19.5C16.7239 19.5 16.5 19.2761 16.5 19C16.5 18.7239 16.7239 18.5 17 18.5C17.2761 18.5 17.5 18.7239 17.5 19Z"
      stroke="currentColor"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
