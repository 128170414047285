import {
  IScenarioError,
  IScenarioNodeError,
  IScenarioFilterOperator,
  IScenarioNodeTriggerError,
  IScenarioNodeTriggerWarning,
  IScenarioNodeWarning,
  IScenarioStatus,
  IShopifyEventType,
} from 'models';

export const scenarios = {
  // model
  status: {
    [IScenarioStatus.DRAFT]: 'Draft',
    [IScenarioStatus.ENABLED]: 'Activated',
    [IScenarioStatus.DISABLED]: 'Deactivated',
    [IScenarioStatus.ERROR]: 'Error',
  },

  pageTitle: 'Automations',

  // components
  list: {
    description:
      'Automate your WhatsApp messages with our new automation feature. Create custom messages and send them to your contacts with ease.',
    createCta: 'Create an automation',
    headers: {
      name: 'Name',
      status: 'Status',
      triggered: 'Triggered',
      delivered: 'Delivered',
      interacted: 'Interacted',
      ordered: 'Ordered',
      revenue: 'Revenue',
    },
    content: {
      nodes_one: 'One node',
      nodes_other: '{{count}} nodes',
    },
    empty: {
      title: 'You don’t have any automation yet',
      description:
        'Create your first automation to automate your WhatsApp messages.',
    },
  },

  actions: {
    titlePlaceholder: 'New Automation',
    save: 'Finish later',
    activate: 'Activate',
    activateError: {
      title: 'Errors in automation',
      content:
        'There are some errors in the automation. Fix all the errors before activating it.',
    },
    validateSuccess: {
      title: 'Automation is valid',
      content: 'The automation is valid and can be activated.',
    },
    deactivate: 'Deactivate',
    validate: 'Run error check',
    editConfirmation: {
      title: 'You are about to edit an automation that was previously live',
      content: [
        'Please be aware that making changes to this automation, especially deleting nodes, modifying buttons, or rerouting edges, can potentially cause disruptions or bugs for users currently engaged in the automation. This may also impact the accuracy and consistency of your automation statistics.',
        'If you need to make substantial changes, consider creating a new automation to ensure a seamless experience for your users and the integrity of your data',
      ],
      confirm: 'Proceed with Caution',
    },
  },

  create: {
    title: 'Create new automation',
    description:
      'Get started directly from our pre-made templates or get creative and start from scratch',
    nameLabel: 'Automation name',
    namePlaceholder: 'Name your automation',
  },

  updateName: {
    title: 'Edit automation name',
  },

  displayLinks: {
    details: 'Details',
    performance: 'Performance',
    responses: 'Statistics',
  },

  updateTemplate: {
    mapVariablesLabel: 'Map variables',
    mapVariablesPlaceholder: 'Select a variable',
  },

  scenarioTest: {
    testCampaign: 'Test campaign',
    testScenario: 'Test automation',
    titleCampaign: 'Scan to test your campaign',
    titleAutomation: 'Scan to test your automation',
    scanText: 'Scan me',
    testError:
      'Some messages need a validation from Meta before testing. Testing will be available once they are validated',
    testSuccess:
      'Grab your phone, open the camera, and scan this QR code to test me on WhatsApp',
  },

  scenarioNode: {
    swapTemplateTooltip: 'Send message instead',
    swapMessageTooltip: 'Send template instead',
  },

  scenarioDisplay: {
    variables: {
      sidePanelCta: 'Automation variables',
      headerTitle: 'Automation variables',
      headerDescription:
        'Automation variables allow you to inject data from external tools in your automation.',
      createTitle: 'Create local variable',
      updateTitle: 'Edit local variable',
      description:
        'Automation variables takes on data points from external tools to further personalize this automation.',
      nameLabel: 'Variable name',
      addVariableCta: 'Add new variable',
    },
    createNode: {
      title: 'Add new action',
      invalidNodeTitle: 'Complete and save this step before adding a new one',
    },
    sidePanel: {
      emptyStateTitle: 'Select a node',
      sendMessageTitle: 'Send message',
      sendMessageSupportText: 'Send interactive messages',
      sendWaTemplateTitle: 'Send template',
      sendWaTemplateSupportText: 'Best to open conversations',
      sendFlowButtonMessageTitle: 'Send flow message',
      sendFlowButtonMessageSupportText: 'Forms in WhatsApp',
      sendInputQueryMessageTitle: 'Send input query',
      sendInputQueryMessageSupportText: 'Ask user information',
      waitTitle: 'Wait',
      waitSupportText: 'Delay delivery of messages',
      updateOptInStatusTitle: 'Update opt-in status',
      updateOptInStatusSupportText: 'Change contact opt-in status',
      sendEmailTitle: 'Send email',
      sendEmailSupportText: 'Send email alert',
      createSupportTicketTitle: 'Create support ticket',
      createSupportTicketSupportText: 'Create ticket in support tool',
      triggerTitle: 'Set up trigger',
      errorsTitle: 'Automation validations',
      errorsDescription: 'Pre activation checklist',
      triggerTypeLabel: 'Select trigger type',
      textConditionsLabel: 'Define trigger conditions',
    },
  },

  filters: {
    operator: {
      [IScenarioFilterOperator.LT]: 'is less than',
      [IScenarioFilterOperator.LTE]: 'is less or equals',
      [IScenarioFilterOperator.GT]: 'is greater than',
      [IScenarioFilterOperator.GTE]: 'is greater or equals',
      [IScenarioFilterOperator.EQ]: 'is',
      [IScenarioFilterOperator.CONTAINS]: 'contains',
      [IScenarioFilterOperator.AND]: 'AND',
      [IScenarioFilterOperator.OR]: 'OR',
      [IScenarioFilterOperator.DEFINED]: 'is defined',
      [IScenarioFilterOperator.NOT_DEFINED]: 'is not defined',
    },
    operatorDescription: {
      [IScenarioFilterOperator.AND]: 'All filters must match',
      [IScenarioFilterOperator.OR]: 'At least one filter must match',
    },
    boolean: {
      true: 'True',
      false: 'False',
    },
    addFilter: 'Add filter',
    remove: 'Remove',
    transformInGroup: 'Transform into group',
  },

  flow: {
    nodes: {
      or: 'OR',
      campaignTriggered: 'Triggered from campaign',
      manualTrigger: 'Manually executed',
      publicApiTrigger: 'Executed from public API',
      zapierTrigger: 'Executed from Zapier',
      sendMessageTitle: 'Send message',
      sendTemplateTitle: 'Send template',
      sendFlowButtonMessageTitle: 'Send WhatsApp form',
      waitTitle: 'Wait for',
      updateOptInStatusTitle: 'Set opt-in status to',
      addButton: 'Add button',
      new: 'Which action do you want to perform next ?',
      shopify: {
        [IShopifyEventType.ORDER_CREATED]: 'Order created',
        [IShopifyEventType.ORDER_UPDATED]: 'Order updated',
        [IShopifyEventType.ORDER_CANCELED]: 'Order canceled',
        [IShopifyEventType.CHECKOUT_CREATED]: 'Checkout started',
      },
    },
    trigger: {
      trigger: {
        title: 'Trigger',
        description: 'Contact enters flow when this happens',
      },
      triggerFilter: {
        title: 'Trigger filters',
        description: 'Limit trigger to certain conditions',
      },
      cancellationFilter: {
        title: 'Cancellation filters',
        description: 'Contact leaves flow when this happens',
      },
      addTrigger: 'Add trigger',
      emptyText:
        'Select the element that will trigger the start of this automation.',
      emptyCta: 'Select trigger',
      emptyDisplay: 'No initial trigger',
      inboundTextMessageContent: {
        title: 'Keyword trigger',
        content: 'Ex: Contact sends “STOP”',
        panel: {
          title: 'Contact message match',
          addPlaceholder: 'Add keyword',
          addCta: 'Add',
          emptyRules: 'No rules defined yet',
          emptyRuleError: 'You must define at least one keyword',
        },
      },
      shopify: {
        orderCreated: {
          title: 'Order created',
        },
        checkoutCreated: {
          title: 'Checkout started',
        },
        panel: {
          title: 'Contact will enter flow after',
          label: 'Enter after',
          minutes: 'Minutes',
          emptyWaitDurationError: 'You must define a trigger delay',
          checkoutCreatedWaitDurationError:
            'Trigger delay for checkout start event must be greater than or equal to 30 minutes',
        },
      },
      zapier: {
        title: 'Zapier',
        content: 'Trigger automation directly from your Zaps',
      },
      publicApi: {
        title: 'Public API trigger',
        content: 'Build your own integration',
      },
      manuallyExecutedAction: {
        title: 'Manual trigger',
        content: 'Ex: Sent from campaign',
      },
    },
  },

  validations: {
    scenarioError: {
      [IScenarioError.MISSING_INITIAL_NODE]:
        'Automation must have a start node',
      [IScenarioError.MULTIPLE_INITIAL_NODES]:
        'Automation can’t have more than one start node',
      [IScenarioError.MISSING_NODE]: 'Automation must have at least one node',
    },
    scenarioNodeError: {
      [IScenarioNodeError.MISSING_TRIGGER]: 'A node must have a trigger',
      [IScenarioNodeError.SEND_TEXT_MESSAGE_ACTION_TEXT_NULL]:
        'The text is missing',
      [IScenarioNodeError.SEND_BUTTONS_MESSAGE_ACTION_BODY_NULL]:
        'The body is missing',
      [IScenarioNodeError.SEND_FLOW_BUTTON_MESSAGE_ACTION_BODY_NULL]:
        'The body is missing',
      [IScenarioNodeError.SEND_FLOW_BUTTON_MESSAGE_ACTION_TITLE_NULL]:
        'The title is missing',
      [IScenarioNodeError.SEND_FLOW_BUTTON_MESSAGE_ACTION_FLOW_META_ID]:
        'The flow id is missing',
      [IScenarioNodeError.SEND_FLOW_BUTTON_MESSAGE_ACTION_SCREEN_ID]:
        'The screen id is missing',
      [IScenarioNodeError.WAIT_DURATION_NULL]: 'The duration is missing',
      [IScenarioNodeError.SEND_MEDIA_MESSAGE_ACTION_MEDIA_NULL]:
        'The media is missing',
      [IScenarioNodeError.SEND_WA_TEMPLATE_ACTION_WA_TEMPLATE_NULL]:
        'The template is missing',
      [IScenarioNodeError.SEND_WA_TEMPLATE_ACTION_WA_TEMPLATE_NOT_APPROVED]:
        'The template must be approved by WhatsApp',
      [IScenarioNodeError.SEND_WA_TEMPLATE_ACTION_MISSING_BODY_VARIABLES]:
        'The template body is missing variables',
      waTemplatePending: 'The template is pending approval',
      waTemplateRejected: 'The template was rejected',
    },
    scenarioNodeWarning: {
      [IScenarioNodeWarning.SEND_WA_TEMPLATE_ACTION_MISSING_ACTION_REPLY_TRIGGER]:
        'The button is not linked to any node',
      [IScenarioNodeWarning.SEND_BUTTONS_MESSAGE_ACTION_MISSING_ACTION_REPLY_TRIGGER]:
        'The button is not linked to any node',
      [IScenarioNodeWarning.SEND_WAIT_ACTION_MISSING_AWAIT_TRIGGER]:
        'The wait is not linked to any node',
    },
    scenarioNodeTriggerError: {
      [IScenarioNodeTriggerError.INBOUND_ACTION_REPLY_MESSAGE_TRIGGER_BUTTON_ID_NOT_RELATED_TO_ACTION]:
        'The trigger is linked to a button that does not exist',
      [IScenarioNodeTriggerError.MANUALLY_EXECUTED_ACTION_TRIGGER_NOT_SEND_WA_TEMPLATE_ACTION]:
        'When triggered manually, the action must be to send a template message',
      [IScenarioNodeTriggerError.PUBLIC_API_TRIGGER_NOT_SEND_WA_TEMPLATE_ACTION]:
        'When triggered through public API, the action must be to send a template message',
      [IScenarioNodeTriggerError.PUBLIC_API_TRIGGER_NOT_IN_INITIAL_NODE]:
        'The public api trigger should be on the initial node',
      [IScenarioNodeTriggerError.ZAPIER_TRIGGER_NOT_SEND_WA_TEMPLATE_ACTION]:
        'When triggered through public API, the action must be to send a template message',
      [IScenarioNodeTriggerError.ZAPIER_TRIGGER_NOT_IN_INITIAL_NODE]:
        'The public api trigger should be on the initial node',
      [IScenarioNodeTriggerError.CAMPAIGN_TRIGGER_NOT_SEND_WA_TEMPLATE_ACTION]:
        'When triggered through a campaign, the action must be to send a template message',
      [IScenarioNodeTriggerError.CAMPAIGN_TRIGGER_NOT_IN_INITIAL_NODE]:
        'The campaign triggered trigger should be on the initial node',
      [IScenarioNodeTriggerError.AI_ESCALATION_TRIGGER_NOT_IN_INITIAL_NODE]:
        'The ai escalation trigger should be on the initial node',
    },
    scenarioNodeTriggerWarning: {
      [IScenarioNodeTriggerWarning.NO_PREVIOUS_SCENARIO_NODE_ID]:
        'The trigger is not linked to any node',
    },
  },

  statistics: {
    overview: {
      title: 'Performance overview',
      triggered: 'Triggered',
      delivered: 'Delivered',
      deliveredTooltip:
        'The message has been successfully received by the user.',
      interacted: 'Interacted',
      completed: 'Completed',
      sent: 'Sent',
      read: 'Read',
      readTooltip:
        'Some users disable their read receipts, so this number might be lower than the actual number of read messages.',
      costs: 'Costs',
      revenue: 'Revenue',
      roi: 'ROI',
      averageBasket: 'Average basket',
    },
    nodes: {
      title: 'Performance by message',
      delivered: 'Delivered',
      interacted: 'Interacted',
      noInteraction: 'No interaction',
    },
  },

  statisticsConversations: {
    contacts: 'contacts',
    export: 'Export CSV',
    columns: {
      contact: {
        header: 'Contact',
      },
      status: {
        header: 'Status',
        delivered: 'Delivered',
        started: 'Started',
        error: 'Failed',
        completed: 'Completed',
      },
    },
  },

  templates: {
    description: 'Explore our library and create your automation',
    startFromScratch: 'Start from scratch',
    startFromScratchDescription: 'Get creative and create your own automation.',
    useTemplate: 'Use template',
  },

  templateMissingIntegration: {
    title: 'Missing {{integration}} integration',
    content:
      'You’ll need to connect your <integration>{{integration}}</integration> account to be able to use this template.',
  },
};
