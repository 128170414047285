import { useMemo } from 'react';
import { UserOutputDto } from 'dto';
import { useTextColor } from '@/hooks/shared/useTextColor.ts';
import { mergeClassNames } from '@/utils/classNames';
import { RingLoaderIcon } from '@ui/icons';
import { getAgentLabel } from './getAgentLabel';

type AgentPictureProps = {
  agent: Nullable<UserOutputDto>;
  className?: string;
  loading?: boolean;
};

export const AgentPicture = ({
  agent,
  className,
  loading,
}: AgentPictureProps) => {
  const color = useTextColor(agent?.id);

  const agentInitial = useMemo(
    () => getAgentLabel(agent)?.charAt(0) || null,
    [agent]
  );

  return (
    <div
      className={mergeClassNames(
        'flex aspect-square flex-shrink-0 select-none justify-center rounded-full bg-current font-medium',
        className
      )}
      style={{ backgroundColor: color.background }}
    >
      {loading && <RingLoaderIcon className="h-3.5 w-3.5 self-center" />}
      {!loading && (
        <LetterSvg
          className="h-full w-full text-neutral-800"
          letter={agentInitial || '?'}
        />
      )}
    </div>
  );
};

const LetterSvg = ({
  letter,
  className,
}: {
  letter: string;
  className?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <text
      className="font-heading font-bold"
      fill="currentColor"
      fontSize="80%"
      textAnchor="middle"
      dominantBaseline="middle"
      x="50%"
      y="50%"
      dy=".1em"
      textLength="100%"
      lengthAdjust="spacing"
    >
      {letter}
    </text>
  </svg>
);
