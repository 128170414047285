import { Navigate, RouteObject } from 'react-router-dom';

export const router = {
  path: '/auth',
  children: [
    {
      index: true,
      element: <Navigate replace to="signin" />,
    },
    {
      path: 'signin',
      lazy: () => import('./Signin.page'),
    },
    {
      path: 'signup',
      lazy: () => import('./Signup.page'),
    },
  ],
} satisfies RouteObject;
