import { QueryClient } from '@/hooks';
import { payloadToDto } from '@/utils/dto';
import { ConversationOutputDto } from 'dto';
import { updateConversationsCache } from '@/features/socketEvents/conversationsCache.helpers.ts';

export const handleUpdatedConversationAssigneeSocketEvent =
  (queryClient: QueryClient, agentId: string) => (payload: any) => {
    const conversation = payloadToDto(ConversationOutputDto, payload);

    queryClient
      .getQueryCache()
      .findAll({ queryKey: ['conversations', 'list'] })
      .forEach((query) =>
        updateConversationsCache({
          query,
          queryClient,
          conversation,
          agentId,
        })
      );

    queryClient.setQueryData<ConversationOutputDto>(
      ['conversations', 'show', conversation.id],
      () => conversation
    );
  };
