import {
  ConversationOutputDto,
  GetConversationsInputDto,
  GetConversationsOutputDto,
  UnrepliedConversationsCountsOutputDto,
} from 'dto';
import { payloadToDto } from '@/utils/dto.ts';
import { apiClient } from '@/services/ApiService.ts';
import { IConversationStatus } from 'models';

export interface ConversationsFilter {
  assignee: ConversationsAssigneeFilter;
  status: IConversationStatus;
}

export enum ConversationsAssigneeFilter {
  ALL = 'all',
  MY = 'my',
  UNASSIGNED = 'unassigned',
}

export const CONVERSATIONS_PAGE_SIZE = 100;

export const getConversations = async ({
  lastSequenceId = null,
  limit = CONVERSATIONS_PAGE_SIZE,
  query = '',
  status = IConversationStatus.OPEN,
  filter = ConversationsAssigneeFilter.ALL,
}: Partial<
  GetConversationsInputDto & {
    filter: ConversationsAssigneeFilter;
  }
> = {}) => {
  const response = await apiClient.get(getConversationsFilterPath(filter), {
    params: {
      lastSequenceId,
      limit,
      query,
      status,
    },
  });

  return payloadToDto(GetConversationsOutputDto, response.data);
};

const getConversationsFilterPath = (filter: ConversationsAssigneeFilter) => {
  switch (filter) {
    case ConversationsAssigneeFilter.MY:
      return '/conversations/my';
    case ConversationsAssigneeFilter.UNASSIGNED:
      return '/conversations/unassigned';
    default:
      return '/conversations';
  }
};

export async function getConversationById(
  conversationId: string
): Promise<ConversationOutputDto> {
  const response = await apiClient.get(`/conversations/${conversationId}`);

  return payloadToDto(ConversationOutputDto, response.data);
}

export async function createConversation(params: {
  phoneNumber: string;
}): Promise<ConversationOutputDto> {
  const response = await apiClient.post('/conversations/', params);

  return payloadToDto(ConversationOutputDto, await response.data);
}

export async function assignConversation(
  conversationId: string,
  agentId: string
): Promise<ConversationOutputDto> {
  const response = await apiClient.post(
    `/conversations/${conversationId}/assign`,
    {
      userId: agentId,
    }
  );

  return payloadToDto(ConversationOutputDto, response.data);
}

export async function fetchUnrepliedConversationsCounts(): Promise<UnrepliedConversationsCountsOutputDto> {
  const response = await apiClient.get('/conversations/unreplied-counts');

  return <UnrepliedConversationsCountsOutputDto>response.data;
}

export const toggleConversationStatus = async ({
  conversation,
}: {
  conversation: ConversationOutputDto;
}) => {
  const response = await apiClient.post(
    `/conversations/${conversation.id}/${
      conversation.status === IConversationStatus.OPEN ? 'close' : 'open'
    }`
  );

  return payloadToDto(ConversationOutputDto, response.data);
};
