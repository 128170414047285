import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgCursorText = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.75 18.25V5.75m0 12.5c0 1-2.25 1-2.25 1m2.25-1c0 1 2.25 1 2.25 1m-2.25-13.5c0-1-2.25-1-2.25-1m2.25 1c0-1 2.25-1 2.25-1M16.5 12.25H19M5.5 16.25l3.25-8.5 3.25 8.5M6.75 13.25h4"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCursorText);
export default ForwardRef;
