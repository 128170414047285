import { ReactNode, createElement, useCallback, useContext } from 'react';
import { Notification, NotificationType } from '@ui/notification';
import { useTranslation } from 'react-i18next';
import { notificationsContext } from '../../contexts/Notifications/Notifications.context.ts';

export { NotificationType } from '@ui/notification';

export type ShowNotificationProps = {
  type: NotificationType;
  title: string;
  duration?: number;
  content: ReactNode;
};

export const useNotification = () => {
  const { addNotification } = useContext(notificationsContext);
  const { t } = useTranslation('shared');

  const showNotification = useCallback(
    ({ type, title, content, duration = 3500 }: ShowNotificationProps) => {
      const contentElement = () => {
        if (content) return content;

        return createElement('p', null, content);
      };

      addNotification(
        createElement(Notification, {
          id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(),
          type,
          title,
          content: contentElement(),
          duration,
        })
      );
    },
    [addNotification]
  );

  return {
    showNotification,
    showSuccessNotification: (props?: Partial<ShowNotificationProps>) => {
      showNotification({
        type: NotificationType.SUCCESS,
        title: t('notifications.success.title'),
        content: t('notifications.success.content'),
        ...props,
      });
    },
    showErrorNotification: (props?: Partial<ShowNotificationProps>) => {
      showNotification({
        type: NotificationType.ERROR,
        title: t('notifications.error.title'),
        content: t('notifications.error.content'),
        ...props,
      });
    },
  };
};
