import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgMicrophone = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 8a3.25 3.25 0 0 1 6.5 0v3A3.25 3.25 0 0 1 9 11V8Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 12.75s.25 4.5 6.25 4.5 6.25-4.5 6.25-4.5M12.25 17.75v1.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgMicrophone);
export default ForwardRef;
