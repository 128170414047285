export const ProgressCircle = ({
  value,
  className,
}: {
  value: number;
  className?: string;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="12" cy="12" r="12" fill="white" />
    <circle
      cx="12"
      cy="12"
      r="7.5"
      stroke="currentColor"
      strokeOpacity="0.10"
      strokeWidth="3"
    />
    <circle
      cx="12"
      cy="12"
      r="7.5"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinejoin="round"
      pathLength={100}
      strokeDasharray={100}
      rotate="90deg"
      strokeDashoffset={100 - value}
      transform="rotate(-90 12 12)"
      strokeLinecap="round"
      className="transition-[stroke-dashoffset] duration-700 ease-in-out"
    />
  </svg>
);
