import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      d="M10.25 19.25L8.2545 15.2609C8.25182 15.2543 8.25328 15.2467 8.25823 15.2416C8.92116 14.5538 10.0344 14.5937 10.6463 15.3273L12.2501 17.25V10C12.2501 9.30964 12.8097 8.75 13.5001 8.75C14.1905 8.75 14.7501 9.30964 14.7501 10V14.25H16.7501C18.407 14.25 18.7501 15.5931 18.7501 17.25V19.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.75 11C19.75 9.98062 19.5274 8.97354 19.0976 8.04916C18.6679 7.12478 18.0415 6.3054 17.2622 5.64832C16.4828 4.99123 15.5694 4.51229 14.5856 4.24497C13.6019 3.97765 12.5717 3.92841 11.567 4.10069C10.5623 4.27296 9.60732 4.6626 8.76885 5.24236C7.93039 5.82211 7.22867 6.578 6.71275 7.45718C6.19682 8.33637 5.87914 9.31762 5.78191 10.3324C5.68469 11.3471 5.81026 12.3708 6.14985 13.332"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
