export const gettingStarted = {
  pageTitle: 'Hey {{name}} 👋 Let’s get you started with Kiosk',
  pageDescription: 'Check all the steps to get the most out of Kiosk',

  checklist: {
    title: 'How to get the most out of Kiosk & WhatsApp',
    whatsAppSetup: 'WhatsApp Setup',
    whatsAppCheck: 'Connected',
    whatsAppNotUnchecked: 'Not connected',

    whatsAppPayment: 'WhatsApp Payment',
    whatsAppPaymentCheck: 'Payment set up',
    whatsAppPaymentUnchecked: 'Set up payment',

    roiTitle: 'Start driving more ROI with WhatsApp',
    helpTitle: 'Need help ? ',
    helpDescription: 'We’re here to help',
    helpMessage: 'Hi, I need help with Kiosk',

    shopifyTitle: 'Connect your Shopify store',
    shopifyDescription:
      'Connect your Shopify store to Kiosk and start selling on WhatsApp.',

    entryPointsTitle: 'Create your first entry point',
    entryPointsDescription: 'Grow your subscriber list from your website',

    campaignsTitle: 'Create your first campaign',
    campaignsDescription: 'Introduce WhatsApp to your client with a campaign !',

    scenariosTitle: 'Create your first scenario',
    scenariosDescription: 'Explore our template librate to get started',
  },

  content: {
    title: 'We have nice content to help you get started !',
    club: {
      title: 'How to',
      description: 'Collect WhatsApp Opt-ins from Klaviyo Pop-Ups',
    },

    playbook: {
      title: 'Playbook',
      description:
        'Read our playbook to jumpstart your WhatsApp Marketing Strategy',
    },

    automation: {
      title: 'How to',
      description: 'How to set up your own abandoned cart flow',
    },
  },
};
