import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M7.25024 19.2502H17.7502C18.8548 19.2502 19.7502 18.3548 19.7502 17.2502V9.75025L12.5002 4.75024L5.25024 9.75025V17.2502C5.25024 18.3548 6.14568 19.2502 7.25024 19.2502Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2496 15.7493C10.2496 14.6447 11.1451 13.7493 12.2496 13.7493H12.7496C13.8542 13.7493 14.7496 14.6447 14.7496 15.7493V19.2493H10.2496V15.7493Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIcon);
export default ForwardRef;
