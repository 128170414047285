import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgEdit = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m3.958 16.042 3.542-.834 7.744-7.744a.833.833 0 0 0 0-1.178l-1.53-1.53a.833.833 0 0 0-1.178 0L4.792 12.5l-.834 3.542ZM16.042 16.042h-4.584"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
