import { RouteObject, Navigate } from 'react-router-dom';

export const router = {
  path: 'settings',
  lazy: () => import('./Settings.page'),
  children: [
    {
      index: true,
      element: <Navigate replace to="whatsapp" />,
    },
    {
      path: 'whatsapp',
      lazy: () => import('./pages/whatsapp/SettingsWhatsapp.page'),
    },
    {
      path: 'teammates',
      lazy: () => import('./pages/teammates/SettingsTeammates.page'),
    },
    {
      path: 'business-profile',
      lazy: () => import('./pages/businessProfile/BusinessProfile.page'),
    },
    {
      path: 'notifications',
      lazy: () => import('./pages/notifications/SettingsNotifications.page'),
    },
    {
      path: 'integrations',
      lazy: () => import('./pages/integrations/SettingsIntegrations.page'),
    },
    {
      path: 'integrations/install/:installIntegrationType',
      lazy: () => import('./pages/integrations/SettingsIntegrations.page'),
    },
    {
      path: 'api-keys',
      lazy: () => import('./pages/apiKeys/SettingsApiKeys.page'),
    },
    {
      path: 'opening-hours',
      lazy: () => import('./pages/openingHours/OpeningHours.page'),
    },
    {
      path: 'tracking',
      lazy: () => import('./pages/tracking/Tracking.page'),
    },
    {
      path: 'pricing',
      lazy: () => import('./pages/pricing/Pricing.page'),
    },
    {
      path: 'templates',
      children: [
        {
          index: true,
          lazy: () => import('./pages/templates/WaTemplates.page'),
        },
        {
          path: 'create',
          lazy: () => import('./pages/templates/WaTemplateCreate.page'),
        },
        {
          path: ':id/update',
          lazy: () => import('./pages/templates/WaTemplateUpdate.page'),
        },
      ],
    },
  ],
} satisfies RouteObject;
