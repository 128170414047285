export const errors = {
  messages: {
    default: 'We’re unable to send your message',
    '0': 'Error to Authenticate → contact the person administrating Kiosk in your organisation',
    '3': 'Error with the API Method → contact the person administrating Kiosk in your organisation',
    '10': 'Permission Denied → contact the person administrating Kiosk in your organisation',
    '190':
      'Access token has expired → contact the person administrating Kiosk in your organisation',
    '200-299':
      'API permission problem → contact the person administrating Kiosk in your organisation',
    '4': 'API too manys calls → your Organisation has sent to many messages within the last 24h, try again in a few hours',
    '80007':
      'Rate limit issues → your Organisation has sent to many messages within the last 24h, try again in a few hours',
    '130429':
      'Rate limit hit → your Organisation has sent to many messages within the last 24h, try again in a few hours',
    '131048':
      'Spam rate limit hit → your Organisation has sent to many spammy messages within the last 24h, leading to restrictions : contact the person administrating Kiosk in your organisation',
    '131056':
      'Too many messages sent to this phone number in a short period of time → Wait and retry the operation',
    '368': 'Temporarily blocked for policies violations',
    '131031': 'Account has been locked',
    '1': 'Message couldn’t be sent because of an API error',
    '2': 'Temporary due to downtime or due to being overloaded',
    '33': 'The business phone number has been deleted',
    '100':
      'The request included one or more unsupported or misspelled parameters',
    '130472': 'User’s number is part of an experiment',
    '131000': 'Message failed to send due to an unknown error',
    '131005':
      'Access denied → permission is either not granted or has been removed',
    '131008':
      'Required parameter is missing → contact the person administrating Kiosk in your organisation',
    '131009':
      'Parameter value is not valid → contact the person administrating Kiosk in your organisation',
    '131016':
      'A service is temporarily unavailable → contact the person administrating Kiosk in your organisation',
    '131021': 'You are trying to send a message to yourself !',
    '131026':
      'Unable to deliver message. This contact is incapable of receiving WhatsApp messages',
    '131042':
      'Message failed because of payment issues → fix billing or contact the person administrating Kiosk in your organisation',
    '131045':
      'Incorrect certificate → contact the person administrating Kiosk in your organisation',
    '131047':
      'You need to use an template message → contact the person administrating Kiosk in your organisation',
    '131051':
      'Unsupported message type → contact the person administrating Kiosk in your organisation',
    '131052': 'Media download error',
    '131053': 'Media upload error',
    '132000':
      'Problem with the variables of your Template message → ensure all variables are ordered and filled',
    '132001': 'Template does not exist',
    '132005': 'Template Hydrated Text Too Long',
    '132007': 'Template Format Character Policy Violated',
    '132012': 'Template Parameter Format Mismatch',
    '132015':
      'Template message template is paused due to low quality → contact the person administrating Kiosk in your organisation',
    '132016':
      'Template message template is Disabled → contact the person administrating Kiosk in your organisation',
    '133000':
      'A previous deregistration attempt failed → contact the person administrating Kiosk in your organisation',
    '133004':
      'Server is temporarily unavailable → contact the person administrating Kiosk in your organisation',
    '133005':
      'Two-step verification PIN incorrect → contact the person administrating Kiosk in your organisation',
    '133006':
      'Phone number needs to be verified before registering → contact the person administrating Kiosk in your organisation',
    '133008':
      'Too many two-step verification PIN guesses for this phone number → contact the person administrating Kiosk in your organisation',
    '133009':
      'Two-step verification PIN was entered too quickly → contact the person administrating Kiosk in your organisation',
    '133010':
      'Phone number not registered on the Whatsapp Business Platform → contact the person administrating Kiosk in your organisation',
    '133015':
      'The phone number you are attempting to register was recently deleted, and deletion has not yet completed → contact the person administrating Kiosk in your organisation',
    '135000':
      'Message failed to send because of an unknown error with your request parameters → contact the person administrating Kiosk in your organisation',
  },
};
