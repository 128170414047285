import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';

const SvgPlug = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.531 12.031 12.22 5.72a1 1 0 0 0-1.596.249L7 13l4.25 4.25 7.032-3.623a1 1 0 0 0 .25-1.596ZM5 19.25l3.75-3.75M14 7.25l2.5-2.5M17 10.25l2.5-2.5"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPlug);
export default ForwardRef;
