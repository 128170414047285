import { useMemo } from 'react';
import { useAgents } from './useAgents';

export const useAgent = (agentId: Nullable<string>) => {
  const { agents, agentsLoading } = useAgents();

  const agent = useMemo(() => {
    if (!agentId) {
      return null;
    }

    return agents?.find(({ id }) => id === agentId) || null;
  }, [agents, agentId]);

  return {
    agent,
    agentLoading: agentsLoading,
  };
};
