import { createContext, ReactElement } from 'react';
import { NotificationProps } from '../../components/ui/notification/Notification.tsx';

export type NotificationsContextValue = {
  addNotification: (notification: ReactElement<NotificationProps>) => void;
  removeNotification: (key: string) => void;
};

const initialValue: NotificationsContextValue = {
  addNotification: () => {},
  removeNotification: () => {},
};

export const notificationsContext = createContext(initialValue);
